import md5 from "md5";

class Socket {
  index = 0;
  pool = [];
  client = null;
  try = 0;
  tryMax = Infinity;
  trying = false;
  tryInterval = 3;
  query = "wsquote";

  constructor(url) {
    if (!url) return console.error("请传入服务器地址来创建socket连接");
    this.pool.push(...url);
  }
  //todo 启动socket
  open() {
    this.client = new WebSocket(this.url());
    this.client.onopen = this.onopen.bind(this);
    this.client.onerror = this.onerror.bind(this);
    this.client.onclose = this.onclose.bind(this);
    this.client.onmessage = this.onmessage.bind(this);
  }

  emit(buffer) {
    if (typeof buffer !== "string") {
      buffer = JSON.stringify(buffer);
    }
    if (
      this.client.readyState === WebSocket.CLOSED ||
      this.client.readyState === WebSocket.CLOSING
    ) {
      this.delayQueue(buffer);
      console.log(
        "The server is closed...\nmessage will be send,when the server is reconnect."
      );
      console.log("webSocket已经关闭...\n消息将会再重连后发送");
      return;
    }
    if (this.client.readyState === WebSocket.CONNECTING) {
      this.delayQueue(buffer);
      console.log("The server is connecting...\nmessage will be send delay");
      console.log("webSocket正在连接中...\n消息将会延迟发送");
      return;
    }
    if (this.client.readyState === WebSocket.OPEN) {
      if (buffer) {
        this.client.send(buffer);
      }
    }
  }

  queue = [];
  delayQueue(buffer) {
    if (!this.queue.includes(buffer)) this.queue.push(buffer);
  }

  onopen() {
    console.log("The server connect success");
    console.log("服务器连接成功...");
    this.trying = false;
    this.try = 0;
    let len = this.queue.length;
    if (len > 0) {
      while (len > 0) {
        let buffer = this.queue.shift();
        this.emit(buffer);
        len = this.queue.length;
      }
    }
  }

  onerror(event) {
    console.log("The server connection has an error: ", event);
    console.log("服务器连接发生错误", event);
    clearTimeout(this.beatLock);
  }

  onclose() {
    this.trying = false;
    console.log("The server connection has been closed.");
    console.log("服务器连接已关闭");
    if (this.try > this.tryMax) return;
    setTimeout(() => this.retry(), this.tryInterval * 1000);
  }

  onmessage(buffer) {
    try {
      let data = JSON.parse(buffer);
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  }

  retry() {
    if (this.trying) return;
    if (this.client !== null && this.client.readyState === WebSocket.OPEN)
      return;
    try {
      this.trying = true;
      this.try++;
      this.index++;
      if (this.index >= this.pool.length) {
        this.index = 0;
      }
      this.open();
    } catch (e) {
      console.log("The server is down for maintenance,please try again later");
      console.log("服务器正在维护,请稍后重试");
    }
  }

  url() {
    return `wss:${this.pool[this.index]}/${this.query}?${this.book(
      Date.now()
    )}`;
  }

  book(stamp) {
    return `t=${stamp}&sign=${md5(`t=${stamp}&key=hello socket quote!`)}`;
  }
}

export default Socket;
