import { subscribeOnStream, unsubscribeFromStream } from "./streaming";
import { makeApiRequest, dataProcess } from "./helper";
import { Brand } from "@/core";

let gap = 0;

const lastBarsCache = new Map();

const configurationData = {
  supports_search: false,
  supports_group_request: false,
  supported_resolutions: ["1", "3", "5", "15", "30", "60", "120", "240", "360", "720", "D", "W", "M"],
  exchanges: [],
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: false,
};

export default class DataFeeds {
  _allSymbols = null;
  _onReady = null;
  _onlLoaded = null;

  constructor(contracts, onReady, onLoaded) {
    this._allSymbols = contracts;
    this._onReady = onReady;
    this._onlLoaded = onLoaded;
  }

  onReady(callback) {
    console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
  }

  getServerTime(callback) {
    if (configurationData.supports_time) {
      makeApiRequest("/time", {}).then((time) => {
        if (!isNaN(time)) {
          gap = time - parseInt(new Date().valueOf() / 1000);
          callback(time);
        }
      });
    }
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    const symbolItem = this._allSymbols.total[symbolName].config;
    if (!symbolItem) {
      console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }
    let { priceDigit, priceChange, code, contractCode } = symbolItem;
    priceDigit = priceDigit || 0;
    priceChange = priceChange || 1;
    const pow = Math.pow(10, priceDigit);
    const symbolInfo = {
      name: symbolName ? symbolName.toUpperCase() : "",
      currency_code: Brand.defaultCurrency,
      data_status: "streaming",
      description: code,
      expiration_date: "",
      expired: false,
      force_session_rebuild: false,
      fractional: false,
      has_daily: true,
      has_empty_bars: false,
      has_intraday: true,
      has_no_volume: true,
      has_seconds: false,
      has_weekly_and_monthly: false,
      intraday_multipliers: ["1", "60", "D", "W", "M"],
      minmov: priceChange.mul(pow),
      minmove2: 0,
      pricescale: pow,
      session: "24x7",
      supported_resolutions: configurationData.supported_resolutions,
      ticker: contractCode,
      timezone: "Asia/Shanghai",
      type: "",
      volume_precision: 2,
      volume: true,
    };
    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 1);
  }

  async getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
    try {
      let request = "";
      //周末休市造成的问题
      let nowTime = new Date(),
        getDay = nowTime.getDay(),
        twoDayBefore = ((nowTime.getTime() - 3600 * 24 * 2 * 1000) / 1000).toFixed(0);
      const params = {
        symbol: symbolInfo.ticker.toUpperCase(),
        resolution,
        from: getDay === 0 ? (symbolInfo.name.indexOf("USDT") !== -1 ? from : twoDayBefore) : from,
        to,
      };
      for (let i = 0; i < Object.keys(params).length; ++i) {
        let key = Object.keys(params)[i];
        let value = encodeURIComponent(params[key]);
        request += (i === 0 ? "?" : "&") + key + "=" + value;
      }
      if (this._onReady) this._onReady();
      const data = await makeApiRequest(`/history${request}`);
      if (this._onlLoaded) this._onlLoaded();
      const bars = dataProcess(
        data,
        resolution,
        from,
        to,
        symbolInfo.full_name.indexOf("USDT") !== -1,
        symbolInfo.volume
      );
      if (!(bars instanceof Array)) {
        return onHistoryCallback([], {
          noData: true,
        });
      }
      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.name, {
          ...bars[bars.length - 1],
        });
      }
      onHistoryCallback(bars, {
        noData: false,
      });
    } catch (error) {
      console.log("[getBars]: Get error", error);
      setTimeout(() => {
        this.getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest);
      }, 2000);
    }
  }

  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) {
    console.log("[subscribeBars]: Method call with subscribeUID:", subscribeUID);
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.name),
      this._allSymbols
    );
  }

  unsubscribeBars(subscriberUID) {
    console.log("[unsubscribeBars]: Method call with subscriberUID:", subscriberUID);
    unsubscribeFromStream(subscriberUID, this._allSymbols);
  }
}
