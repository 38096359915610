import React, { useState } from "react";
import { Tabs } from "antd";
import { useLocation } from "react-router-dom";

// components
import FiatDetail from "./fiatDetail";
import CryptoDetail from "./cryptoDetail";
import TradeDetail from "./tradeDetail";
import WithdrawDetail from "./withdrawDetail";
import EagleDetail from "./eagleDetail";
import LuckyDetail from "./luckyDetail";

// style
import styles from "./index.module.scss";

const FundsDetail = () => {
	const location = useLocation();
	const [activeIndex] = useState(location.state || 1);
	const [tabList] = useState([
		{
			label: "充值记录",
			key: "1",
			children: <FiatDetail />,
		},
		{
			label: "充币记录",
			key: "2",
			children: <CryptoDetail />,
		},
		{
			label: "交易记录",
			key: "3",
			children: <TradeDetail />,
		},
		{
			label: "提款记录",
			key: "4",
			children: <WithdrawDetail />,
		},
		{
			label: "礼金记录",
			key: "5",
			children: <EagleDetail />,
		},
		{
			label: "积分记录",
			key: "6",
			children: <LuckyDetail />,
		},
	]);
	return (
		<div className={styles.fundsDetail}>
			<div className={styles.fundsTitle}>
				<h5>资金明细</h5>
			</div>
			<div className={styles.fundsMain}>
				<Tabs defaultActiveKey={`${activeIndex}`} items={tabList}></Tabs>
			</div>
		</div>
	);
};

export default FundsDetail;
