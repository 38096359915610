/**
 * 验证数据安全性
 * @param val
 * @param [avoid]
 * @returns {boolean}
 */
export function safeValue(val, avoid) {
	if (avoid) {
		return val !== undefined && val !== null && val !== "" && val !== "null";
	}
	return val !== undefined && val !== null;
}
