import React, { useState, useEffect } from "react";
import { Pagination, notification } from "antd";
// lib
import EVENT from "@/library/api";
import { RESTRICT } from "@/library/standard";
// utils
import { isEmpty, formatDate, splitAry } from "@/utils";
// assets
import funds from "@assets/common/funds_light.png";
// style
import styles from "./index.module.scss";

const TradeDetail = () => {
	const [tradeInfo, setTradeInfo] = useState({ list: [], total: 0 });
	const [page, setPage] = useState(1);
	useEffect(() => {
		getRecord();
	}, []);
	const getRecord = () => {
		EVENT.History.getRecordHistory(2)
			.then(({ data }) => {
				let o = [];
				if (!isEmpty(data)) {
					o = data.filter((item) => item.assetType === 1 && item.explain !== "佣金收入");
				}
				setTradeInfo({ list: splitAry(o, 10), total: o.length });
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	const onChangePage = (page) => {
		if (page - 1 >= tradeInfo.list.length) return;
		setPage(Number(page));
	};

	return (
		<div className={styles.detailContent}>
			<ol className={styles.tradeList}>
				<li className={styles.header}>
					<div className={styles.long}>ID</div>
					<div className={styles.income}>金额(元)</div>
					<div className={styles.longer}>明细</div>
					<div>余额</div>
					<div className={styles.longer}>时间</div>
				</li>
				{!isEmpty(tradeInfo.list) ? (
					tradeInfo.list[page - 1].map((item, key) => (
						<li key={key}>
							<div className={styles.long}>{item.bizId}</div>
							<div className={`${item.type === 100 ? styles.red : styles.green} ${styles.income}`}>
								{item.type === 100 ? "+" : "-"}
								{RESTRICT.float(item.money, 2)}
							</div>
							<div className={styles.longer}>{item.explain}</div>
							<div>{RESTRICT.float(item.assetMoney, 2)}</div>
							<div className={styles.longer}>{formatDate("y-m-d h:i:s", { date: item.createTime })}</div>
						</li>
					))
				) : (
					<div className={styles.empty}>
						<div>
							<img src={funds} alt="" />
							<b>暂无记录</b>
						</div>
					</div>
				)}
			</ol>
			<div className="pagination">
				<Pagination
					onChange={onChangePage}
					total={tradeInfo.total}
					defaultPageSize={10}
					current={page}
					showSizeChanger={false}
				/>
			</div>
		</div>
	);
};

export default TradeDetail;
