import React, { useEffect, useState, useMemo } from "react";
import { DatePicker, message, Pagination, notification } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import dayjs from "dayjs";

// lib
import { RESTRICT } from "@/library/standard";
import EVENT from "@/library/api";
// utils
import { isEmpty, formatDate, splitAry } from "@/utils";
// assets
import funds from "@assets/common/funds_light.png";
// style
import styles from "./index.module.scss";

export default function UserTrade() {
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [userTradeInfo, setUserTradeInfo] = useState({ list: [], total: 0 });
	const [page, setPage] = useState(1);
	useEffect(() => {
		update({});
	}, []);
	const disabledStartDate = useMemo(() => {
		if (!startDate || !endDate) {
			return false;
		}
		return startDate.valueOf() > endDate.valueOf();
	}, [startDate, endDate]);
	const disabledEndDate = useMemo(() => {
		if (!endDate || !startDate) {
			return false;
		}
		return endDate.valueOf() <= startDate.valueOf();
	}, [startDate, endDate]);
	// 取得佣金紀錄資料
	const update = ({ page = 1, rows = 10, biz = 1, startDate, endDate, subName }) => {
		EVENT.Account.updateCommission({ page, rows, biz, createTimeGe: startDate, createTimeLe: endDate })
			.then(({ data, total, page }) => {
				setUserTradeInfo({ list: data, total: Number(total) });
				setPage(page);
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	// 搜尋
	const search = () => {
		if (!startDate) return message.warning("请选择起始时间");
		const start = startDate ? startDate.format("YYYY-MM-DD HH:mm:ss") : undefined;
		const end = endDate ? endDate.format("YYYY-MM-DD HH:mm:ss") : undefined;
		update({ page: 1, rows: 10, biz: 1, startDate: start, endDate: end });
	};
	// 導出 excel 列表
	const getExcel = () => {
		if (!startDate && !!endDate) return message.warning("请选择起始时间");
		if (!endDate && !!startDate) return message.warning("请选择结束时间");
		let sDate, eDate;
		if (startDate instanceof dayjs) {
			sDate = startDate.format("YYYY-MM-DD HH:mm:ss");
		}

		if (endDate instanceof dayjs) {
			eDate = endDate.format("YYYY-MM-DD HH:mm:ss");
		}
		window.location.href = `${window.location.origin}/api/mine/agent/commission-export${
			!!sDate && !!eDate ? `?createTimeGe=${sDate}&createTimeLe=${eDate}` : ""
		}`;
	};

	const onChangePage = (page) => {
		let start = startDate || undefined;
		let end = endDate || undefined;

		if (start instanceof dayjs) {
			start = start.format("YYYY-MM-DD HH:mm:ss");
		}

		if (end instanceof dayjs) {
			end = end.format("YYYY-MM-DD HH:mm:ss");
		}
		update({ page, rows: 10, biz: 1, startDate, endDate });
	};

	return (
		<div className={styles.tradeBox}>
			<div className={styles.title}>
				<div className={styles.titleSection}>
					<label>日期</label>
					<div className={styles.dateWrapper}>
						<DatePicker
							style={{ width: 140 }}
							locale={locale}
							disabledDate={disabledStartDate}
							format={"YYYY-MM-DD"}
							value={startDate}
							onChange={(val) => setStartDate(val)}
							placeholder="开始日期"
						/>
						<span className={styles.line}> - </span>
						<DatePicker
							style={{ width: 140 }}
							locale={locale}
							disabledDate={disabledEndDate}
							format={"YYYY-MM-DD"}
							value={endDate}
							onChange={(val) => setEndDate(val)}
							placeholder="结束日期"
						/>
					</div>
				</div>
				<div className={styles.search} onClick={search}>
					搜索
				</div>
				<div className={styles.excel} onClick={getExcel}>
					导出
				</div>
			</div>
			<ol>
				<li className={styles.header}>
					<div>邀请用户</div>
					<div>交易手续费(元)</div>
					<div>佣金收入(元)</div>
					<div>交易ID</div>
					<div>时间</div>
				</li>
				{!isEmpty(userTradeInfo.list) ? (
					userTradeInfo.list.map((item, key) => (
						<li key={key}>
							<div>{item.subName}</div>
							<div>{RESTRICT.float(item.charge, 2)}</div>
							<div>{RESTRICT.float(item.money, 2)}</div>
							<div>{item.tradeId}</div>
							<div>{formatDate("m-d h:i:s", { date: item.createTime })}</div>
						</li>
					))
				) : (
					<div className={styles.empty}>
						<div>
							<img src={funds} alt="" />
							<b>暂无记录</b>
						</div>
					</div>
				)}
			</ol>
			<div className="pagination">
				<Pagination
					onChange={onChangePage}
					total={userTradeInfo.total}
					defaultPageSize={10}
					current={page}
					showSizeChanger={false}
				/>
			</div>
		</div>
	);
}
