/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { notification } from "antd";

// components
import { HomeIcon } from "@/view/components/Svg";
import Popover from "@/view/components/Popover";
import { customModal } from "@/view/components/Modal";
import MessageBox from "./messageBox";

// store
import Store from "@/store";
import { Brand } from "@/core";

// assets
import { ReactComponent as Triangle } from "@assets/common/triangle.svg";
import { ReactComponent as Shuzihuobi } from "@assets/home/shuzihuobi.svg";
import { ReactComponent as Ganggugupiao } from "@assets/home/ganggugupiao.svg";
import { ReactComponent as Shangpinqihuo } from "@assets/home/shangpinqihuo.svg";
import { ReactComponent as Waihuizaiquan } from "@assets/home/waihuizaiquan.svg";
import { ReactComponent as MineIcon } from "@assets/userCenter/head/mine.svg";
import { ReactComponent as AppIcon } from "@assets/userCenter/head/app.svg";
import { ReactComponent as CsIcon } from "@assets/userCenter/head/cs.svg";
import { ReactComponent as MessageIcon } from "@assets/userCenter/head/message.svg";

import download_qr from "@assets/common/qrCode0922.png";

import styles from "./index.module.scss";

const Nav = () => (
	<div className={styles.tradeNav}>
		<NavLink to={"/cooperation/invite"} className={({ isActive }) => (isActive ? styles.active : "")}>
			邀请好友
		</NavLink>
		<NavLink to={"/cooperation/proxy"} className={({ isActive }) => (isActive ? styles.active : "")}>
			申请代理
		</NavLink>
	</div>
);
const TradeNav = ({ pathname }) => (
	<div className={styles.tradeNav}>
		<NavLink
			to="/trade/crypto/BTCUSDT"
			className={() => (pathname.startsWith("/trade/crypto/") ? styles.active : "")}
			onClick={() => Store.Quote.switchSimulate(false)}
		>
			<Triangle />
			<Shuzihuobi className={styles.textIcon} />
		</NavLink>
		<NavLink
			to="/trade/stock/9988"
			className={() => (pathname.startsWith("/trade/stock/") ? styles.active : "")}
			onClick={() => Store.Quote.switchSimulate(false)}
		>
			<Triangle />
			<Ganggugupiao className={styles.textIcon} />
		</NavLink>
		<NavLink
			to="/trade/futures/CL"
			className={() => (pathname.startsWith("/trade/futures/") ? styles.active : "")}
			onClick={() => Store.Quote.switchSimulate(false)}
		>
			<Triangle />
			<Shangpinqihuo className={styles.textIcon} />
		</NavLink>
		<NavLink
			to="/trade/foreignE/EURUSD"
			className={() => (pathname.startsWith("/trade/foreignE/") ? styles.active : "")}
			onClick={() => Store.Quote.switchSimulate(false)}
		>
			<Triangle />
			<Waihuizaiquan className={styles.textIcon} />
		</NavLink>
	</div>
);

const UserNav = ({ accountInfo }) => {
	const navigate = useNavigate();
	return (
		<div className={styles.userNav}>
			<div>
				<b>{accountInfo?.username}</b>
				<p>UI:{accountInfo?.userId}</p>
			</div>
			<Link to={"/account/userCenter"}>个人中心</Link>
			<Link to={"/account/myAsset"}>资产管理</Link>
			<Link to={"/account/fundsDetail"}>资金明细</Link>
			<Link to={"/cooperation/proxy"}>申请代理</Link>
			<a
				onClick={() => {
					Store.User.setLogout();
					navigate("/");
					notification.success({
						message: "退出登录",
						// duration: 2,
					});
				}}
			>
				退出登录
			</a>
		</div>
	);
};
const DownloadNav = () => (
	<div className={styles.download}>
		<img src={download_qr} alt="" />
		<div>
			<b></b>
			<p>IOS + Android</p>
			<Link to={"/download"}>查看全部</Link>
		</div>
	</div>
);

const LoginContent = ({ accountInfo }) => {
	// 開啟客服
	const openCs = () => {
		let w = 600,
			h = 670;
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth
			? window.innerWidth
			: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: window.screen.width;
		const height = window.innerHeight
			? window.innerHeight
			: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: window.screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;
		const user = accountInfo;
		console.log(Brand.cusServiceUrl);
		const link = `${Brand.cusServiceUrl}&metaData={"name":${(user && user.username) || "游客"},"userId":${
			(user && user.userId) || ""
		}}`;
		const feature = `
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
        `;
		window.open(link, "客服", feature);
	};
	// 開啟留言板
	const showMessage = () => {
		customModal(<MessageBox />);
	};

	return (
		<>
			<div className={styles.btnBox}>
				<Link to={"/account/recharge"}>充值</Link>
				<Link to={"/account/withdraw"}>提现</Link>
			</div>
			<Popover content={<UserNav accountInfo={accountInfo} />} placement={"bottom"} className={styles.headerModal}>
				<a>
					<MineIcon />
				</a>
			</Popover>
			<a onClick={openCs}>
				<CsIcon />
			</a>
			<a onClick={showMessage}>
				<MessageIcon />
				{accountInfo?.unread !== 0 && <span className={styles.unread}>{accountInfo?.unread}</span>}
			</a>
			<Popover content={<DownloadNav />} placement={"bottom"} className={styles.headerModal}>
				<a>
					<AppIcon />
				</a>
			</Popover>
		</>
	);
};
const UnLoginContent = () => (
	<div className={styles.btnBox}>
		<Link to={"/common/login"}>登录</Link>
		<Link to={"/common/register"}>注册</Link>
	</div>
);

const Header = () => {
	const location = useLocation();
	return (
		<header className={styles.headerWrapper}>
			<div className={styles.headerLeft}>
				<Link to={"/"}>
					<HomeIcon img="logo" />
				</Link>
				<Popover
					content={<TradeNav pathname={location.pathname} />}
					placement={"bottom"}
					className={styles.headerModal}
				>
					<a>品类交易</a>
				</Popover>
				<NavLink to={"/activity"} className={({ isActive }) => (isActive ? styles.active : "")}>
					活动专区
				</NavLink>

				<Popover content={<Nav />} placement={"bottom"} className={styles.headerModal}>
					<NavLink
						to={"/cooperation/invite"}
						className={() => (location.pathname.startsWith("/cooperation/") ? styles.active : "")}
					>
						商务合作
					</NavLink>
				</Popover>
				<NavLink to={"/download"} className={({ isActive }) => (isActive ? styles.active : "")}>
					移动端下载
				</NavLink>
			</div>
			<div className={styles.headerRight}>
				{Store.User.isLogin ? <LoginContent accountInfo={Store.User.accountInfo} /> : <UnLoginContent />}
			</div>
		</header>
	);
};
export default observer(Header);
