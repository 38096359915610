import CFD from "./cfd";
// import PERPETUAL from "./perpetual";
import Brand from "./brand";
import { decodeFromWeb } from "@/library/encrypt";
import service from "@/library/network/axios";

class Launcher {
	#initial = false;
	#queue = [];
	#cfd = null;
	//   #perpetual = null;
	constructor() {
		this.#cfd = new CFD("/api/trade/commodity/initial", "cfd");
		// this.#perpetual = new PERPETUAL("/swap/contract/list", "perpetual");
		// this.#perpetual.bindReq(axios);
	}

	async start() {
		try {
			let data = await service({
				url: "/api/trade/commodity/initial",
				method: "GET",
				timeout: 10000,
				params: {
					_: new Date().getTime(),
				},
			});
			//todo 后续会加永续 quoteDomainList
			const {
				brand,
				quoteDomain,
				appVersion: {
					android: { cusServiceUrl },
				},
			} = data;
			Brand.setData({ ...brand, cusServiceUrl });
			const cfd = decodeFromWeb(quoteDomain);
			// const perpetual = quoteDomainList;
			await Promise.all([
				this.#cfd.start(cfd, data),
				// this.#perpetual.start(perpetual),
			]);
		} catch (e) {
			console.warn(e);
			setTimeout(() => this.start(), 5000);
		}
	}

	get CFD() {
		return this.#cfd;
	}

	//   get PERPETUAL() {
	//     return this.#perpetual;
	//   }
}

export default Launcher;
