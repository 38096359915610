import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { observer } from "mobx-react-lite";
import CX from "@/core";
import styles from "./home.module.scss";
import Store from "@/store";
// components
import Header from "./components/header";
import Quote from "./components/quote";
import Intro from "./components/intro";
import Footer from "../components/Footer";

const Home = () => {
	const navigate = useNavigate();

	useEffect(() => {
		CX.Launcher.CFD.startData();
		Store.User.updateDetail();
		return () => CX.Launcher.CFD.endData();
	}, []);
	useEffect(() => {
		if (Store.User.ru) {
			navigate("/common/register");
		}
	}, [Store.User.ru]);

	return (
		<div className={styles.home}>
			<Header />
			<Quote />
			<Intro />
			<Footer />
		</div>
	);
};

export default observer(Home);
