import React from "react";
import { observer } from "mobx-react-lite";

// core
import Store from "@/store";
import { Brand } from "@/core";
// component
import { CommonIcon } from "@/view/components/Svg";
// style
import styles from "./index.module.scss";

const CsAlert = () => {
	const { username, userId } = Store.User.accountInfo || {};
	const openCs = () => {
		let w = 600,
			h = 670;
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth
			? window.innerWidth
			: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: window.screen.width;
		const height = window.innerHeight
			? window.innerHeight
			: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: window.screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;
		const link = `${Brand.cusServiceUrl}&metaData={"name":${username || "游客"},"userId":${userId || ""}}`;
		const feature = `
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
        `;
		window.open(link, "客服", feature);
	};
	return (
		<div className={styles.csAlertWrapper} onClick={openCs}>
			<CommonIcon img="message" />
		</div>
	);
};
export default observer(CsAlert);
