import React, { useCallback, useMemo } from "react";

// style
import styles from "../home.module.scss";
// components
import { HomeIcon } from "@/view/components/Svg";
import Popover from "@/view/components/Popover";
// assets
import section1 from "@assets/home/section1.svg";
import section2 from "@assets/home/section2.svg";
import terminal from "@assets/home/download/terminal.png";
import iOS from "@assets/home/download/IOS.png";
import android from "@assets/home/download/Android.png";
import downloadQR from "@assets/home/download/downloadQR.png";
import download_qr from "@assets/common/qrCode0922.png";

export default function Intro() {
	const handleDownload = useCallback(() => {
		window.open("https://cxx-apk.zbb6.top/jyt-gw.html", "_blank");
	}, []);
	const download = useMemo(
		() => (
			<div className={styles.homeQR}>
				<img src={download_qr} alt="" />
			</div>
		),
		[]
	);
	return (
		<section className={styles.introWrapper}>
			<div className={styles.intro1}>
				<img src={section1} alt="" className={styles.terminal} />
			</div>
			<div className={styles.download}>
				<div className={styles.content}>
					<img src={terminal} alt="" className={styles.terminal} />
					<div className={styles.info}>
						<h5>随时随地 全在掌控</h5>
						<p>
							<HomeIcon img="xiazai" />
						</p>
						<div>
							<img src={iOS} alt="" onClick={handleDownload} />
							<img src={android} alt="" onClick={handleDownload} />
							<Popover content={download} placement="bottom">
								<img className={styles.qrCode} src={downloadQR} alt="" />
							</Popover>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.intro2}>
				<img src={section2} alt="" className={styles.terminal} />
			</div>
		</section>
	);
}
