import { Routes, Route } from "react-router-dom";

import routes from "./routes/index";
import { RouterGuard } from "./routes/auth";
import "@/library/prototype";

function MyApp() {
	return (
		<Routes>
			{routes.map((route, i) =>
				route.nestRoutes ? (
					<Route key={i} path={route.path} element={RouterGuard(route)}>
						{route.nestRoutes.map((child) => (
							<Route key={child.path} path={child.path} element={RouterGuard(child)} />
						))}
					</Route>
				) : (
					<Route key={i} path={route.path} element={RouterGuard(route)} />
				)
			)}
		</Routes>
	);
}

export default MyApp;
