import CX from "@/core";
import { widget } from "./charting_library.min";
import DataFeed from "./udf";

const getDefaultOverrides = (RAISE, FALL) => {
	return {
		"paneProperties.vertGridProperties.color": "rgba(0,0,0,0)",
		"paneProperties.horzGridProperties.color": "rgba(0,0,0,0)",

		"paneProperties.legendProperties.showSeriesTitle": false,
		// 'paneProperties.legendProperties.showStudyTitles': false,
		// 'paneProperties.legendProperties.showStudyValues': false,
		"paneProperties.legendProperties.showLegend": false,

		"mainSeriesProperties.candleStyle.upColor": RAISE,
		"mainSeriesProperties.candleStyle.downColor": FALL,
		"mainSeriesProperties.candleStyle.drawWick": true,
		"mainSeriesProperties.candleStyle.drawBorder": false,
		"mainSeriesProperties.candleStyle.borderUpColor": RAISE,
		"mainSeriesProperties.candleStyle.borderDownColor": FALL,
		"mainSeriesProperties.candleStyle.wickUpColor": RAISE,
		"mainSeriesProperties.candleStyle.wickDownColor": FALL,
		"mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
		// 'mainSeriesProperties.areaStyle.color1': "#32577a",
		// 'mainSeriesProperties.areaStyle.color2': "#ffffff",
		// 'mainSeriesProperties.areaStyle.linecolor': "#32577a",
		// LINESTYLE_SOLID = 0
		// LINESTYLE_DOTTED = 1
		// LINESTYLE_DASHED = 2
		// LINESTYLE_LARGE_DASHED = 3
		// 'mainSeriesProperties.areaStyle.linestyle': 0,
		// 'mainSeriesProperties.areaStyle.linewidth': 2,
		"mainSeriesProperties.areaStyle.priceSource": "close",
		"symbolWatermarkProperties.color": "rgba(0, 0, 0, 0)",
	};
};

const zone = {
	3: "America/Sao_Paulo",
	4: "Pacific/Norfolk",
	5: "America/Mexico_City",
	6: "America/El_Salvador",
	7: "US/Mountain",
	8: "America/Juneau",
	10: "Pacific/Honolulu",
	0: "Atlantic/Reykjavik",
	"-1": "Pacific/Chatham",
	"-2": "Africa/Cairo",
	"-3": "Europe/Tallinn",
	"-4": "Asia/Dubai",
	"-4.5": "Asia/Tehran",
	"-5": "Asia/Ashkhabad",
	"-5.5": "Asia/Kolkata",
	"-5.75": "Asia/Kathmandu",
	"-6": "Asia/Almaty",
	"-7": "Asia/Bangkok",
	"-8": "Asia/Shanghai",
	"-9": "Asia/Seoul",
	"-9.5": "Australia/Adelaide",
	"-10": "Australia/Sydney",
	"-13": "Pacific/Fakaofo",
};

const timezone = () => {
	let i = new Date().getTimezoneOffset() / 60;
	if (zone[i]) {
		return zone[i];
	} else {
		return "Asia/Hong_Kong";
	}
};

const validType = (type) => {
	if (type === "sline" || type < 60) return "1";
	if (type === "60" || type === "240") return "60";
	if (type === "1D" || type === "W" || type === "M") return "1D";
	return type;
};

export const Chart = {
	study: false,
	widget: null,
	initType: 3,
	mType: 1,
	type: "sline",
	initial: false,
	volume: true,
	reset: true,
	code: "",
	// changeFum: null,

	_initWaiting: null,
	_initLooping(options) {
		if (Object.keys(options.contract).length !== 0 && options.contract.index !== "") {
			if (options.contract.initial) {
				this._initWaiting = null;
				this._init(options);
			} else if (this._initWaiting !== null) {
				this._initWaiting = setTimeout(() => this._initLooping(options), 300);
			}
		} else {
			this._initWaiting = setTimeout(() => this._initLooping(options), 300);
			console.warn("还没获取到行情信息,Relex chart.js");
		}
	},

	_init(options) {
		if (this.initial) return;
		this.initial = true;
		const RAISE = options.RAISE || "#e34c4c";
		const FALL = options.FALL || "#00b38f";
		if (options.volume === false) this.volume = false;
		if (options.rest === false) this.reset = false;

		// this.changeFum = options.onChangeHandler;

		// const overrides = Object.assign(getDefaultOverrides(RAISE, FALL), options.overrides || {});
		const overrides = Object.assign(options.overrides, getDefaultOverrides(RAISE, FALL));

		const studies_overrides = Object.assign(
			{
				"volume.volume.color.0": FALL,
				"volume.volume.color.1": RAISE,
				"volume.volume.transparency": 0,
			},
			options.studies_overrides || {}
		);

		try {
			if (!options.dom) return console.error("缺少chart容器ID");
			if (!options.code) return console.error("缺少初始商品ID");
			this.code = options.code;
			const cover = Object.options({
				// fullscreen: true,
				// autosize: true,
				autosize: options.autosize,
				height: options.height,
				width: options.width,
				preset: options.preset,
				toolbar_bg: options.toolbar_bg,
				theme: options.theme,
			});

			const disabled_features = options.disabled_features || [
				"header_widget",
				"timeframes_toolbar",
				"display_market_status",
				"symbol_info",
				// "use_localstorage_for_settings",
				"header_symbol_search",
				// "header_resolutions",
				// "header_saveload",
				// "header_screenshot",
				// "header_settings",
				// "header_compare",
				// "header_undo_redo",
				// "header_widget_dom_node",
				// "remove_library_container_border",
				// "border_around_the_chart",
				// "display_market_status",
				// "show_logo_on_all_charts",
				// "show_chart_property_page",
				// "panel_context_menu",
				// "legend_context_menu"
			];

			const enabled_features = options.enabled_features || ["study_templates"];
			let Widget = (this.widget = new widget(
				Object.assign(
					{
						symbol: options.code,
						interval: "1",
						timezone: timezone(),
						container_id: options.dom,
						datafeed: new DataFeed(options.contract, options.onReady, options.onLoaded),
						library_path: process.env.PUBLIC_URL + "/chart/",
						disabled_features,
						enabled_features,
						locale: options.lang || "zh",
						client_id: "CX.com",
						overrides,
						preset: "web",
						studies_overrides,
						custom_css_url: options.css || "",
						favorites: {
							intervals: ["1", "5", "30", "60", "240", "D", "W", "M"],
							chartTypes: ["Area", "Line"],
						},
						//	Regression Trend-related functionality is not implemented yet, so it's hidden for a while
						// drawings_access: { type: "black", tools: [{ name: "Regression Trend" }] },
						// custom_css_url: process.env.PUBLIC_URL + "/chart/static/css/bityard.css" || "",
						// user_id: 'public_user_id',
						// charts_storage_url: 'http://saveload.tradingview.com',
						// charts_storage_api_version: "1.1",
					},
					cover
				)
			));
			Widget.onChartReady(() => {
				if (!this.widget) return;
				try {
					if (options.initType !== undefined) {
						this.initType = options.initType;
					}

					this.table = this.widget.activeChart();

					//todo 行情滑动
					this.table.setScrollEnabled(true);
					// todo 行情缩放
					this.table.setZoomEnabled(true);
					if (this.type === "sline") {
						this.table.setChartType(1);
						this._addTechnicalIndicators();
					} else {
						this.table.setChartType(this.mType);
					}

					this.table.onIntervalChanged().subscribe(null, (interval, obj) => {
						this._onIntervalChanged(interval, options.onChangeHandler);
					});
				} catch (e) {
					this.initial = false;
					console.warn("Widget.onChartReady", e);
				}
			});
		} catch (err) {
			this.initial = false;
			console.warn(err);
		}
	},

	_onIntervalChanged(interval) {
		if (interval === "1" && this.type === "sline") {
			this.widget.activeChart().setChartType(1);
		} else {
			this.widget.activeChart().setChartType(this.mType);
		}
	},

	/**
	 * 指标生成
	 * @param {Boolean} [live] 是否是分时线
	 * @private
	 */
	_addTechnicalIndicators() {
		this.widget.activeChart().removeAllStudies();
		this._createMA();
		if (this.volume) {
			this.widget.activeChart().createStudy("Volume", false, false, [], {
				"volume ma.color": "rgba(132,170,213,0.7)",
			});
		}
	},

	/**
	 * 均线生成器
	 * @param options
	 * @private
	 */
	_createMA(options) {
		options = options || [
			[5, "rgba(150,95,196,0.7)"],
			[10, "rgba(132,170,213,0.7)"],
			[20, "rgba(85,178,99,0.7)"],
			[40, "rgba(183,36,138,0.7)"],
		];
		options.forEach((e) => {
			if (!(e instanceof Array)) e = [];
			const [min = 5, color = "rgba(150,95,196,0.7)"] = e || [];
			this.widget.chart().createStudy("Moving Average", true, false, [min, "close", 0], {
				"plot.color": color,
			});
		});
	},

	init(options) {
		clearTimeout(this._initWaiting);
		this._initWaiting = true;
		this._initLooping(options);
	},

	swap(options) {
		try {
			if (!this.widget) return;
			if (!options) return console.error("切换表格缺少参数 options");
			this.widget.activeChart().resetData();
			//todo 模式一 切换类型
			if (!!options.type && !options.code) {
				if (this.type === "sline" && options.type === "1") {
					this.widget.activeChart().setChartType(1);
					this.type = options.type;
				} else if (this.type === "1" && options.type === "sline") {
					this.widget.activeChart().setChartType(this.initType);
					this.type = options.type;
				} else {
					this.type = options.type;
					const type = options.type === "sline" ? "1" : options.type;
					this.widget.activeChart().setResolution(type);
				}
			}
			//todo 模式二 切换合约
			if (!!options.code && !options.type) {
				this.code = options.code;
				this.widget.activeChart().setSymbol(options.code);
			}
			CX.Launcher.CFD.updateQuoteData(validType(options.type));
		} catch (e) {
			console.warn("这里显示错误。。。。", e);
		}
	},
	/**
	 * 更新覆盖
	 * @param {Object} overrides
	 */
	updateOverrides(overrides) {
		if (!this.widget) return;
		overrides = overrides || {};
		this.widget.activeChart().applyOverrides(overrides);
	},

	updateLanguage(ln) {
		try {
			//todo 这个方法还不成熟 不建议使用
			this.widget.setLanguage(ln);
		} catch (e) {
			console.warn(e);
		}
	},

	updateTheme(theme = "Dark") {
		try {
			if (!this.widget) return;
			theme = theme === "light" ? "Light" : "Dark";
			this.widget.changeTheme(theme);
		} catch (e) {
			console.warn(e);
		}
	},

	update(options) {
		try {
			if (!this.widget) return;
			this.widget.applyOverrides(options.overrides);
		} catch (e) {
			console.warn(e);
		}
	},

	switchAreaStyle(color, color1) {
		if (!this.table && !this.widget) return;
		this.widget.onChartReady(() => {
			if (!this.widget) return;
			this.widget.applyOverrides({
				"mainSeriesProperties.areaStyle.color1": color,
				"mainSeriesProperties.areaStyle.linecolor": color1 || color,
			});
		});
	},

	exit() {
		if (!this.widget) return;
		this.widget.remove();
		this.widget = null;
		this.code = "";
		this.type = "sline";
		this.initial = false;
		this.volume = true;
	},
};
