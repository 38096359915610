import React, { useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Slider, Switch, notification } from "antd";
// core
import CX from "@/core";
// store
import Store from "@/store";
// components
import { TradeIcon } from "@/view/components/Svg";
import Popover from "@/view/components/Popover";

// assets
import { ReactComponent as Close } from "@assets/common/x.svg";
// style
import styles from "../index.module.scss";

const OverNightHint = () => (
	<div className={styles.hint}>
		<p>默认开启，订单可持仓过夜，但会收取过夜费</p>
		<p>过夜费=保证金*杠杆*过夜费比例</p>
		<p>如未开启，订单会在清仓时间被系统强制平仓</p>
	</div>
);

const Setting = ({ close }) => {
	const { tp, sl, confirmClose, confirmPlace, overnight } = Store.Futures.orderSetting || {};
	const [confirmPlaceNew, setConfirmPlaceNew] = useState(confirmPlace);
	const [confirmCloseNew, setConfirmCloseNew] = useState(confirmClose);
	const [overnightNew, setOvernightNew] = useState(overnight);
	const [confirmCloseAll, setConfirmCloseAll] = useState(CX.PositionClose.switch);
	const tradeInfo = Store.Futures;

	const submit = () => {
		confirmPlace !== confirmPlaceNew && Store.Futures.switchOrderConfirm();
		confirmClose !== confirmCloseNew && Store.Futures.switchCloseConfirm();
		overnight !== overnightNew && Store.Futures.switchOvernight();
		Store.Futures.saveSetting()
			.then(() => {
				CX.PositionClose.setSwitch(confirmCloseAll);
				notification.success({ message: "保存成功" });
				close();
			})
			.catch(({ message }) => {
				notification.error({ message });
				Store.User.updateOrderDefault();
				close();
			});

		// props.update();
	};
	const stopLossInfo = useMemo(
		() => ({ min: Math.abs(tradeInfo.stopLossList[0].mul(100)), max: Math.abs(tradeInfo.stopLossList[1].mul(100)) }),
		[tradeInfo.stopLossList]
	);
	const stopProfitInfo = useMemo(
		() => ({ min: tradeInfo.stopProfitList[0].mul(100), max: tradeInfo.stopProfitList[1].mul(100) }),
		[tradeInfo.stopProfitList]
	);
	return (
		<div className={styles.settingModal}>
			<div className={styles.close} onClick={close}>
				<Close />
			</div>
			<div className={styles.header}>
				<span className={styles.title}>交易设置</span>
			</div>
			<div className={styles.content}>
				<div className={styles.setTitle}>
					<span>默认止盈比例</span>
					<b>{tp.mul(100).toFixed(0)}%</b>
				</div>
				<div className={styles.setBox}>
					<div className={styles.sliderTop}>
						<div className={styles.btn} onClick={() => Store.Futures.updateProfitPercent(tp.mul(100).sub(1))}>
							<TradeIcon img="loss" />
						</div>
						<Slider
							className={"trade-setting stopProfit stopLoss"}
							value={tp.mul(100)}
							min={stopProfitInfo.min}
							max={stopProfitInfo.max}
							step={1}
							onChange={(val) => Store.Futures.updateProfitPercent(val)}
						/>
						<div className={styles.btn} onClick={() => Store.Futures.updateProfitPercent(tp.mul(100).add(1))}>
							<TradeIcon img="add" />
						</div>
					</div>
					<div className={styles.sliderBottom}>
						<div>{stopProfitInfo.min}%</div>
						<div>{stopProfitInfo.max}%</div>
					</div>
				</div>
				<div className={styles.setTitle}>
					<span>默认止损比例</span>
					<b>{sl.mul(100).toFixed(0)}%</b>
				</div>
				<div className={styles.setBox}>
					<div className={styles.sliderTop}>
						<div className={styles.btn} onClick={() => Store.Futures.updateLossPercent(sl.mul(100).sub(1))}>
							<TradeIcon img="loss" />
						</div>
						<Slider
							className={"trade-setting stopProfit stopLoss"}
							value={sl.mul(100)}
							min={stopLossInfo.min}
							max={stopLossInfo.max}
							step={1}
							onChange={(val) => Store.Futures.updateLossPercent(val)}
						/>
						<div className={styles.btn} onClick={() => Store.Futures.updateLossPercent(sl.mul(100).add(1))}>
							<TradeIcon img="add" />
						</div>
					</div>
					<div className={styles.sliderBottom}>
						<div>{stopLossInfo.min}%</div>
						<div>{stopLossInfo.max}%</div>
					</div>
				</div>
				{!Store.Quote.simulate && (
					<div className={styles.setLine}>
						<label>
							持仓过夜
							<Popover placement="right" content={<OverNightHint />}>
								<i>
									<TradeIcon img="help" />
								</i>
							</Popover>
						</label>
						<Switch
							className="trade-switch"
							checked={overnightNew}
							onChange={() => setOvernightNew((state) => !state)}
						/>
					</div>
				)}
				<div className={styles.setLine}>
					<label>下单确认</label>
					<Switch
						className="trade-switch"
						checked={confirmPlaceNew}
						onChange={() => setConfirmPlaceNew((state) => !state)}
					/>
				</div>
				<div className={styles.setLine}>
					<label>平仓确认</label>
					<Switch
						className="trade-switch"
						checked={confirmCloseNew}
						onChange={() => setConfirmCloseNew((state) => !state)}
					/>
				</div>
				<div className={styles.setLine}>
					<label>一键平仓确认</label>
					<Switch
						className="trade-switch"
						checked={confirmCloseAll === "true"}
						onChange={() => {
							const val = confirmCloseAll === "true" ? "false" : "true";
							setConfirmCloseAll(val);
						}}
					/>
				</div>
				<div className={styles.setBtn}>
					<div onClick={close}>取消</div>
					<div onClick={submit}>确定</div>
				</div>
			</div>
		</div>
	);
};
export default observer(Setting);
