import React from "react";
import { Link } from "react-router-dom";
// components
import CsAlert from "@/view/components/CsAlert";
import { HomeIcon } from "@/view/components/Svg";
// style
import styles from "./index.module.scss";

export default function Footer() {
	return (
		<footer className={styles.footerWrapper}>
			<CsAlert />
			<div className={styles.content}>
				<div className={styles.main}>
					<div className={styles.left}>
						<div className={styles.nav}>
							<div>
								<Link to={"/aboutUs"}>
									<HomeIcon img="guanyuwomen" />
								</Link>
							</div>
						</div>
						<div className={styles.info}>
							<p>
								<HomeIcon img="benwangzhan" />
							</p>
						</div>
					</div>
					<div className={styles.right}></div>
				</div>
			</div>
			<div className={styles.tips}>
				<div className={styles.main}>
					<p>
						<HomeIcon img="tasuojuzhu" />
					</p>
					<div className={styles.copyRight}>
						<div>
							<HomeIcon img="qihuoyoufengxian" />
						</div>
						<div>
							<HomeIcon img="copyright" />
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
