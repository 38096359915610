/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { notification } from "antd";
// store
import Store from "@/store";
//lib
import EVENT from "@/library/api";
// compoents
import Layout from "@/view/components/Layout";
import { ActivityIcon } from "@/view/components/Svg";

// assets
import banner from "@assets/activity/banner.png";
import title1 from "@assets/activity/title_1.png";
import title2 from "@assets/activity/title_2.png";
import box1 from "@assets/activity/box3_1.png";
import box2 from "@assets/activity/box3_2.png";
import blockTitle1 from "@assets/activity/block_title_1.png";
import blockTitle2 from "@assets/activity/block_title_2.png";
import firstmoney from "@assets/activity/firstmoney.png";

// style
import styles from "./index.module.scss";

export default function Activity(props) {
	const [status, setStatus] = useState({
		register: -1,
		realName: -1,
		phoneAndEmail: -1,
		firstDeposit: -1, //首次入金
		firstJiao: -1,
		firstTrade: -1, //首次交易
		firstYuan: -1,
	});
	useEffect(() => {
		if (Store.User.isLogin) {
			EVENT.Account.getActiveTask()
				.then((res) => {
					const tempStatusList = status;
					res.data
						.filter((item) => item.category === 2)
						.forEach((item) => {
							switch (item.type) {
								case 1:
									tempStatusList.register = item.status;
									break;
								case 4:
									tempStatusList.realName = item.status;
									break;
								case 15:
									tempStatusList.phoneAndEmail = item.status;
									break;
								case 2:
									tempStatusList.firstDeposit = item.status;
									break;
								case 7:
									tempStatusList.firstJiao = item.status;
									break;
								case 8:
									if (item.prizeValue === "158") {
										tempStatusList.firstYuan = item.status;
									} else {
										tempStatusList.firstTrade = item.status;
									}
									break;
								default:
									break;
							}
						});
					setStatus(tempStatusList);
				})
				.catch(({ message }) => {
					notification.error({ message, duration: 2 });
				});
		}
	}, [Store.User.isLogin]);
	const getButtonStatus = (status, text, path, item) => {
		if ([0, 1, 2].includes(status)) {
			if (item === "phoneAndEmail") {
				text = "已绑定";
			} else if (item === "register") {
				text = "注册成功";
			} else if (item === "realName") {
				text = "已认证";
			} else {
				status === 1 && (text = "已领取");
				status === 2 && (text = "已过期");
			}

			return (
				<span className={styles.grayBtn}>
					{text}&nbsp;&nbsp;&nbsp;{`>`}
				</span>
			);
		} else {
			if (Store.User.isLogin && path.includes("/register")) {
				return (
					<div>
						<span className={styles.linkBtn}>{text}&nbsp;&nbsp;&nbsp;{`>`}</span>
					</div>
				);
			}
			return (
				<Link to={Store.User.isLogin ? path : "/common/login"}>
					<span className={styles.linkBtn}>
						{text}&nbsp;&nbsp;&nbsp;{`>`}
					</span>
				</Link>
			);
		}
	};
	return (
		<Layout customClass={styles.activityWrapper}>
			<section className={styles.bannerBox}>
				<img src={banner} alt="" className={styles.bg} />
			</section>
			<main>
				<img src={blockTitle1} alt="" />
				<div className={styles.block1}>
					<img src={title1} alt="" />
					<div style={{ marginTop: "100px" }}>
						<div>
							<div className={styles.redBox}>
								66<span>元</span>
							</div>
							<p>注册成功</p>
							{getButtonStatus(status.register, "立即注册", "/common/register", "register")}
						</div>
						<div>
							<div className={styles.redBox}>
								28<span>元</span>
							</div>
							<p>实名认证</p>
							{getButtonStatus(status.realName, "立即认证", "/account/nameVerify", "realName")}
						</div>
						<div>
							<div className={styles.redBox}>
								20<span>元</span>
							</div>
							<p>额外绑定邮箱/手机</p>
							{getButtonStatus(status.phoneAndEmail, "立即绑定", "/account/userCenter", "phoneAndEmail")}
						</div>
					</div>
				</div>
				<div className={styles.block2}>
					<img src={title2} alt="" />
					<div>
						<img src={firstmoney} height="150" alt="" />
						{getButtonStatus(status.firstDeposit, "立即入金", "/account/recharge", "firstDeposit")}
						<div className={styles.littlePerson} />
					</div>
					<div>
						<div>
							<div className={styles.redBox}>
								58<span>元</span>
							</div>
							<p style={{ marginBottom: 39 }}>
								<ActivityIcon img="jiaoyijiaomoshi" />
							</p>
							{getButtonStatus(status.firstJiao, "去完成", "/trade/futures/CL", "firstJiao")}
						</div>
						<div>
							<div className={styles.redBox}>
								58<span>元</span>
							</div>
							<p style={{ marginBottom: 21 }}>
								<div>
									<ActivityIcon img="shoucijiaoyi" />
									<em>
										<ActivityIcon img="xianshuzi" />
									</em>
								</div>
							</p>
							{getButtonStatus(status.firstTrade, "去完成", "/trade/crypto/BTCUSDT", "firstTrade")}
						</div>
						<div>
							<div className={styles.redBox}>
								158<span>元</span>
							</div>
							<p style={{ marginBottom: 39 }}>
								<ActivityIcon img="jiaoyiyuanmoshi" />
							</p>
							{getButtonStatus(status.firstYuan, "去完成", "/trade/futures/CL", "firstYuan")}
						</div>
					</div>
				</div>
				<img src={blockTitle2} alt="" />
				<div className={styles.block3}>
					<div>
						<img src={box1} alt="" />
					</div>
					<div>
						<img src={box2} alt="" />
					</div>
				</div>
			</main>
		</Layout>
	);
}
