import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Select, notification } from "antd";
import QRCode from "qrcode.react";

// lib
import EVENT from "@/library/api";
import { RESTRICT } from "@/library/standard";
// utils
import { isEmpty, formatDate } from "@/utils";
import { statusText, goodsImg } from "@/utils/common";

// components
import { TradeIcon } from "@/view/components/Svg";
import Copy from "@/view/components/Copy";

// assets
import funds from "@assets/common/funds_light.png";
// style
import styles from "./index.module.scss";

const { Option } = Select;

const Crypto = ({ balance, list: fundList }) => {
	const [rate, setRate] = useState();
	const [cryptoList, setCryptoList] = useState([]);
	const [currency, setCurrency] = useState("USDT");
	const [chainList, setChainList] = useState([]);
	const [chain, setChain] = useState("TRC20");
	const [address, setAddress] = useState("");
	useEffect(() => {
		getCryptoList();
	}, []);
	useEffect(() => {
		getRate(currency);
		getCryptoChains(currency);
	}, [currency]);

	const rechargeMin = useMemo(() => chainList.find((item) => item.chain === chain)?.rechargeMin || 0, [chainList]);

	// useEffect(() => {
	// 	getCryptoAddress(currency, chain);
	// }, [chain]);
	// 取得匯率
	const getRate = (currency) => {
		EVENT.Assets.getCryptoRate({ src: "CNY", des: currency, type: 1 })
			.then(({ rate }) => setRate(rate))
			.catch(({ message }) => {
				notification.error({ message });
			});
	};

	const getCryptoList = () => {
		EVENT.Payment.getDepositCurrency()
			.then(({ data }) => {
				setCryptoList(data);
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	const getCryptoChains = (val) => {
		EVENT.Payment.getChains(val)
			.then(({ data }) => {
				setChainList(data);
				if (data.length > 0) {
					const chain = data[0].chain;
					setChain(chain);
					getCryptoAddress(val, chain);
				}
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	const getCryptoAddress = (currency, chain) => {
		EVENT.Payment.getCryptoAddress(currency, chain)
			.then(({ data }) => {
				setAddress(data.address);
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};

	return (
		<>
			<div className={styles.cryptoRecharge}>
				<div className={styles.infoRight}>
					<div>
						<div className={styles.selectCoin}>
							<label>选择币种</label>
							<Select className={styles.selectBox} value={currency} onChange={(val) => setCurrency(val)}>
								{cryptoList.map((key) => (
									<Option key={key} value={key}>
										<div className="option">
											<TradeIcon img={`goods/${goodsImg[key]}`} svgClass={styles.code} />
											<b>{key}</b>
										</div>
									</Option>
								))}
							</Select>
						</div>
						<div className={styles.balance}>
							<label>可用资产:</label> <b>{balance}</b> 元{" "}
							<span>
								≈ {RESTRICT.float(+balance.mul(rate), 2).toFixed(2)} {currency}
							</span>
						</div>
					</div>
					<div className={styles.tips}>
						<h6>温馨提示</h6>
						<ul>
							<li
								style={{
									color: "#F78C1C",
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-start",
								}}
							>
								<div>·</div>{" "}
								<div style={{ marginLeft: 2 }}>
									每次充币需在此页面获取充币地址，不按提示操作充值旧地址的资金无法被找回，且平台将无法协助挽回
								</div>
							</li>
							<li>
								· 最新参考汇率：1 {currency} ≈ {RESTRICT.float(1 / rate)} CNY。
							</li>
							<li>
								· 单笔充值不得低于 {rechargeMin} {currency}， 小于此金额将不会成功。
							</li>
							{/* <li>· {Dom.deposit('tips.3')}。</li> */}
							<li>· 请勿向上述地址充值任何非 {currency} 资产，否则资产将不可找回。</li>
						</ul>
					</div>
				</div>
				<div className={styles.infoLeft}>
					<div className={styles.chain}>
						<label>链名称</label>
						<div className={styles.chainName}>
							{chainList.map((item) => (
								<div
									key={item.chain}
									className={chain === item.chain ? styles.active : ""}
									onClick={() => {
										setChain(item.chain);
										getCryptoAddress(currency, item.chain);
									}}
								>
									{item.chain}
								</div>
							))}
						</div>
					</div>
					<div className={styles.qr}>{!!address && <QRCode value={address} />}</div>
					{!!address && (
						<div className={styles.address}>
							<div>
								<b>{address}</b>
								<Copy className={styles.copy} text={address} />
							</div>
						</div>
					)}
				</div>
			</div>
			<div className={styles.recentRecord}>
				<div className={styles.record}>
					<h5>近期充值记录</h5>
					<Link to="/account/fundsDetail" state={2}>
						更多 》
					</Link>
				</div>
				<ul className={styles.cryptoList}>
					<li className={styles.header}>
						<div>时间</div>
						<div>充值币种</div>
						<div>金额(元)</div>
						<div className={styles.longer}>充币地址</div>
						<div>ID</div>
						<div>状态</div>
					</li>
					{fundList.map((item, key) => (
						<li key={key}>
							<div>{formatDate("m-d h:i:s", { date: item.time })}</div>
							<div>{item.srcCurrency}</div>
							<div>{item.money}</div>
							<div className={styles.longer}>{item.address}</div>
							<div>{item.id}</div>
							<div>{statusText(item.status)}</div>
						</li>
					))}
				</ul>
				{isEmpty(fundList) && (
					<div className={`empty ${styles.empty}`}>
						<div>
							<img src={funds} alt="" />
							<b>暂无记录</b>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default Crypto;
