//皮肤
export default class Theme {
    #default = "night";
    #key = "_CX_theme";
    #theme = null;

    constructor() {
        this.#theme = window.localStorage.getItem(this.#key) || this.#default;
    }

    setTheme(val) {
        if (this.#theme === val) return;
        this.#theme = val;
        window.localStorage.setItem(this.#key, this.#theme)
    }

    get theme() {
        return this.#theme;
    }

}
