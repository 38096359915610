import React, { useEffect, useState, useMemo, useRef } from "react";
import { message, notification } from "antd";
// utils
import { arrayToStr } from "@/utils";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import Layout from "./modal";
import SimpleInput from "@/view/components/SimpleInput";
import ImgValid from "@/view/components/ImgValid";
import { imgValidModal } from "@/view/components/Modal";
// style
import styles from "./index.module.scss";

export default function BindEmail({ close }) {
	const [editType] = useState("BIND_EMAIL");
	const [email, setEmail] = useState("");
	const [msgCode, setMsgCode] = useState("");
	const [formatVerify, setFormatVerify] = useState({ email: false, code: false });
	const [countText, setCountText] = useState("获取验证码");
	const [countTime, setCountTime] = useState(null);
	const [isSend, setIsSend] = useState(false);
	const timer = useRef();

	useEffect(() => {
		return () => {
			clearTimeout();
		};
	}, []);
	useEffect(() => {
		if (countTime === null) {
			return;
		}
		if (countTime === 0) {
			message.warning("输入超时，请重新发送验证码!");
			setCountText("获取验证码");
			setIsSend(false);
			setCountTime(null);
			clearTimeout(timer.current);
		} else {
			timer.current = setTimeout(() => {
				setCountTime((time) => time - 1);
				setCountText(`${countTime}秒后重发`);
				setIsSend(true);
			}, 1000);
		}
	}, [countTime]);

	// 輸入欄位驗證
	const isValid = useMemo(() => {
		return Object.values(formatVerify).every((value) => !!value);
	}, [formatVerify]);

	const showCode = () => {
		if (!formatVerify.email) {
			return notification.warn({ message: "请输入邮箱地址" });
		}
		if (isSend) return;
		imgValidModal(<ImgValid account={email} sendCodeStatus={() => setCountTime(90)} type={editType} isMask={false} />);
	};

	const bindEmail = () => {
		EVENT.Account.bindEmail(email)
			.then(() => {
				Store.User.updateDetail();
				notification.success({
					message: "绑定成功",
				});
				close();
			})
			.catch(({ message }) => notification.error({ message }));
	};

	const submit = () => {
		if (!isValid) {
			return;
		}
		EVENT.Account.verifyEmail(email, msgCode, editType)
			.then(() => {
				bindEmail();
			})
			.catch(({ message }) => notification.error({ message }));
	};

	return (
		<Layout close={close} title="绑定邮箱">
			<SimpleInput
				label="电子邮箱"
				placeholder="请输入邮箱地址"
				type={"email"}
				format={"EMAIL"}
				notice={"email"}
				value={email}
				onChange={(email, format) => {
					if (format !== formatVerify.email) {
						setFormatVerify((props) => ({ ...props, email: format }));
					}
					setEmail(email);
				}}
			/>
			<div className={styles.sendCode}>
				<SimpleInput
					customClass={styles.simpleInput}
					label="邮箱验证码"
					placeholder="请输入邮箱验证码"
					type={"verifyCode"}
					format={"GLOBAL_CAPTCHA"}
					notice={"msgCode"}
					value={msgCode}
					onChange={(code, format) => {
						if (format !== formatVerify.code) {
							setFormatVerify((props) => ({ ...props, code: format }));
						}
						setMsgCode(code);
					}}
				/>
				<div className={styles.getCode} onClick={showCode}>
					{countText}
				</div>
			</div>
			<div className={arrayToStr(["confirm_btn", styles.confirmBtn, !isValid ? "error" : ""])} onClick={submit}>
				确认
			</div>
		</Layout>
	);
}
