import service from "../network/axios";

/**
 * 获取资产信息
 * @param {number} type 0-法币 1-数字货币
 */
const getMyAssets = (type) => {
  const o = { type };
  return service.get("/api/user/asset/list", { params: o });
};

/**
 * 获取单个汇率
 * @param {*} src 基准货币代码
 * @param {*} des 目标货币代码
 * @param {*} type 1-充值汇率 2-提款汇率
 */
const getCryptoRate = (data) => {
  const { src, des, type } = data;
  const o = { src, des };
  if (type) o.type = type;
  return service.get("/api/home/currency/rate", { params: o });
};

/**
 * 获取闪兑历史记录
 * @param {*}
 */
const getExchangeHistory = (data) => {
  const { page, rows = 10, createTimeGe, createTimeLe, srcCurrency, desCurrency } = data;
  const o = {
    page,
    rows,
    createTimeGe,
    createTimeLe,
    srcCurrency,
    desCurrency,
  };
  return service.post("/api/user/asset/exchangeHistory", o);
};

/**
 * 获取币种列表
 */
const getCurrencyList = () => {
  return service.get("/api/home/currency/list");
};

/**
 * 获取所有币种汇率
 * @param {string} data:需查询币种
 * @param {string} des:所兑换币种汇率
 * @param {string} type:1-充值汇率 2-提款汇率
 */
const getCryptoRates = (data) => {
  const o = { src: data, des: "CNY" };
  return service.get("/api/home/currency/rates", { params: o });
};

/**
 * 闪兑
 */
const exchangeApply = (data) => {
  if (data.srcCurrency === "USDT") {
    return service.post("/api/user/asset/exchangeApply", data);
  }
  return service.post("/api/user/asset/swap/apply", data);
};

export default {
  getMyAssets,
  getCryptoRate,
  getExchangeHistory,
  getCurrencyList,
  getCryptoRates,
  exchangeApply,
};
