import React, { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
// utils
import { idMask, nameMask } from "@/utils";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import SimpleInput from "@/view/components/SimpleInput";
// style
import styles from "./index.module.scss";

const NameVerify = () => {
	const { kyc, name, identityNumber, identityNumberValid } = Store.User.accountInfo;
	const [hasId, setHasId] = useState(identityNumberValid);
	const [identityName, setUserName] = useState("");
	const [idNumber, setIdNumber] = useState("");
	const [formatVerify, setFormatVerify] = useState({ name: false, id: false });
	const isNameVerify = useMemo(() => identityNumberValid || kyc, [identityNumberValid, kyc]);
	const isValid = useMemo(() => {
		return Object.values(formatVerify).every((value) => !!value);
	}, [formatVerify]);
	useEffect(() => {
		setHasId(identityNumberValid);
	}, [identityNumberValid]);
	const verify = () => {
		if (!isValid) {
			return;
		}
		EVENT.Account.nameVerification(identityName, idNumber)
			.then(() => {
				setHasId(true);
				Store.User.updateDetail();
				notification.success({
					message: "实名认证成功",
				});
			})
			.catch(({ message }) => notification.error({ message }));
	};

	return (
		<div className={styles.nameVerify}>
			<section className={styles.verifyTitle}>
				<h5>实名认证</h5>
				<div>
					<p>为了方便您快速通过审核，请确保您填写的信息与您证件上的信息一致</p>
				</div>
			</section>
			<section className={styles.main}>
				<div className={styles.mainTitle}>身份信息</div>
				<div className={styles.mainInfo}>
					{isNameVerify || hasId ? (
						<>
							<div className={styles.verifyInfo}>
								<label>姓名</label>
								<p>{nameMask(name)}</p>
							</div>
							<div className={styles.verifyInfo}>
								<label>身份证号</label>
								<p>{idMask(identityNumber)}</p>
							</div>
							<div className={`confirm_btn error ${styles.confirmBtn}`}>已认证</div>
						</>
					) : (
						<>
							<SimpleInput
								label="姓名"
								placeholder="请输入真实姓名"
								format={"CNNAME"}
								notice={"cn_name"}
								type={"text"}
								maxLength={20}
								onChange={(name, format) => {
									if (format !== formatVerify.name) {
										setFormatVerify((props) => ({ ...props, name: format }));
									}
									setUserName(name);
								}}
							/>
							<SimpleInput
								label="身份证号"
								placeholder="请输入证件号码"
								format={"ID"}
								notice={"ID"}
								type={"id"}
								onChange={(id, format) => {
									if (format !== formatVerify.id) {
										setFormatVerify((props) => ({ ...props, id: format }));
									}
									setIdNumber(id);
								}}
							/>
							<div className={`confirm_btn ${styles.confirmBtn} ${!isValid ? "error" : ""}`} onClick={verify}>
								认证
							</div>
						</>
					)}
				</div>
			</section>
		</div>
	);
};

export default observer(NameVerify);
