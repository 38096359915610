import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
//utils
import { arrayToStr } from "@/utils";
// lib
// import Futures from "@/library/futuresRule";
// import Crypto from "@/library/whitePage";
import ruleJson from "./tradeRule";

// style
import styles from "../index.module.scss";

export default function TradeRule({ orderType, tradeType }) {
	const { id } = useParams();
	const [rule, setRule] = useState({});
	useEffect(() => {
		setRule(ruleJson[id]);
	}, [id]);
	const isDeriv = useMemo(() => tradeType === "deriv", [tradeType]);
	return (
		<div className={arrayToStr([styles.tradeRule, orderType === 1 ? styles.short : styles.high])}>
			<h5>商品信息</h5>
			<div>
				<label>合约名称</label>
				<span>{rule?.name}</span>
			</div>
			<div>
				<label>商品编号</label>
				<span>{rule?.code}</span>
			</div>
			{isDeriv && (
				<div>
					<label>交易所</label>
					<span>{rule?.exchange}</span>
				</div>
			)}
			<div>
				<label>货币单位</label>
				<span>{rule?.currency}</span>
			</div>
			<div>
				<label>合约类型</label>
				<span>{rule?.type}</span>
			</div>
			{isDeriv ? (
				<>
					<div>
						<label>合约规模</label>
						<span>{rule?.unit}</span>
					</div>
					<div>
						<label>合约价值</label>
						<span>{rule?.volatility}</span>
					</div>
					<div>
						<label>最小变动单位</label>
						<span>{rule?.volatilityIncome}</span>
					</div>
				</>
			) : (
				<>
					<div>
						<label>最低保证金</label>
						<span>{rule?.min}</span>
					</div>
					<div>
						<label>最高保证金</label>
						<span>{rule?.max}</span>
					</div>
					<div>
						<label>杠杆</label>
						<span>{rule?.lever}</span>
					</div>
					<div>
						<label>手续费</label>
						<span>{rule?.charge}</span>
					</div>
					<div>
						<label>过夜费</label>
						<span dangerouslySetInnerHTML={{ __html: rule?.overnight }} />
					</div>
					{!!rule?.overnight_fee ? (
						<div>
							<label>过夜费收取时间</label>
							<span dangerouslySetInnerHTML={{ __html: rule?.overnight_fee }} />
						</div>
					) : (
						<div>
							<label>过夜费收取时间</label>
							<span>每日05:55:00</span>
						</div>
					)}
				</>
			)}
			<div>
				<label>买入时间</label>
				<span dangerouslySetInnerHTML={{ __html: rule?.buyTimeAM }} />
			</div>
			{!!rule?.buyTimePM && (
				<div>
					<label />
					<span dangerouslySetInnerHTML={{ __html: rule?.buyTimePM }} />
				</div>
			)}
			{!!rule?.buyTimeAMWinter && (
				<div>
					<label />
					<span dangerouslySetInnerHTML={{ __html: rule?.buyTimeAMWinter }} />
				</div>
			)}
			<div>
				<label>卖出时间</label>
				<span dangerouslySetInnerHTML={{ __html: rule?.sellTimeAM }} />
			</div>
			{!!rule?.sellTimePM && (
				<div>
					<label />
					<span dangerouslySetInnerHTML={{ __html: rule?.sellTimePM }} />
				</div>
			)}
			{!!rule?.sellTimeAMWinter && (
				<div>
					<label />
					<span dangerouslySetInnerHTML={{ __html: rule?.sellTimeAMWinter }} />
				</div>
			)}
			{isDeriv ? (
				<div>
					<label>清仓时间</label>
					<span dangerouslySetInnerHTML={{ __html: rule?.clearTime }} />
				</div>
			) : (
				<>
					<div>
						<label>持仓时间</label>
						<span>永久</span>
					</div>
					{!!rule?.position_time ? (
						<div>
							<label>不持仓过夜</label>
							<span dangerouslySetInnerHTML={{ __html: rule?.position_time }} />
						</div>
					) : (
						<div>
							<label>不持仓过夜</label>
							<span>05:55:00自动平仓</span>
						</div>
					)}
				</>
			)}
			{!!rule?.clearTimeWinter && (
				<div>
					<label />
					<span dangerouslySetInnerHTML={{ __html: rule?.clearTimeWinter }} />
				</div>
			)}
			{tradeType === "crypto" && (
				<>
					<div>
						<label>发行时间</label>
						<span>{rule?.publicTime}</span>
					</div>
					<div>
						<label>发行总量</label>
						<span>{rule?.publicTotal}</span>
					</div>
					<div>
						<label>白皮书</label>
						<span className={styles.search}>{rule?.shu}</span>
					</div>
					<div>
						<label>官方网站</label>
						<span>{rule?.publicWeb}</span>
					</div>
					<div>
						<label>区块链查询</label>
						<span className={styles.search}>{rule?.publicSearch}</span>
					</div>
					<div className={styles.production}>
						<b>介绍</b>
						<span>{rule?.dec}</span>
					</div>
				</>
			)}
			{tradeType === "stock" && (
				<>
					<div>
						<label>官方网站</label>
						<span>{rule?.publicWeb}</span>
					</div>
					<div className={styles.production}>
						<b>介绍</b>
						<span>{rule?.dec}</span>
					</div>
				</>
			)}
		</div>
	);
}
