import React, { useState, useEffect } from "react";
import { Pagination, notification } from "antd";
// lib
import EVENT from "@/library/api";
// utils
import { isEmpty, formatDate, splitAry } from "@/utils";
// assets
import funds from "@assets/common/funds_light.png";
// style
import styles from "./index.module.scss";

const EagleDetail = () => {
	const [eagleInfo, setEagleInfo] = useState({ list: [], total: 0 });
	const [page, setPage] = useState(1);
	useEffect(() => {
		getRecord();
	}, []);
	const getRecord = () => {
		EVENT.History.getRecordHistory(3, true)
			.then(({ data }) => {
				let o = [];
				if (!isEmpty(data)) {
					o = data.filter((item) => item.assetType === 3);
				}
				setEagleInfo({ list: splitAry(o, 10), total: o.length });
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	const onChangePage = (page) => {
		if (page - 1 >= eagleInfo.list.length) return;
		setPage(Number(page));
	};

	return (
		<div className={styles.detailContent}>
			<ol className={styles.eagleList}>
				<li className={styles.header}>
					<div>时间</div>
					<div>金额</div>
					<div>明细</div>
					<div>ID</div>
				</li>
				{!isEmpty(eagleInfo.list) ? (
					eagleInfo.list[page - 1].map((item, key) => (
						<li key={key}>
							<div>{formatDate("m-d-y h:i:s", { date: item.createTime })}</div>
							<div className={item.type === 100 ? styles.red : styles.green}>
								{item.type === 100 ? "+" : "-"}
								{Number(item.money).toFixed(2)}
							</div>
							<div>{item.type === 200 ? "礼金抵扣" : "资金存入"}</div>
							<div>{item.id}</div>
						</li>
					))
				) : (
					<div className={styles.empty}>
						<div>
							<img src={funds} alt="" />
							<b>暂无记录</b>
						</div>
					</div>
				)}
			</ol>
			<div className="pagination">
				<Pagination
					onChange={onChangePage}
					total={eagleInfo.total}
					defaultPageSize={10}
					current={page}
					showSizeChanger={false}
				/>
			</div>
		</div>
	);
};

export default EagleDetail;
