import React, { useState } from "react";

// compoents
import Layout from "@/view/components/Layout";
import ImgMagnifier from "@/view/components/ImgMagnifier";

// assets
import mission from "@assets/aboutUs/mission.png";
import safe from "@assets/aboutUs/safe.png";
import qualification from "@assets/aboutUs/qualification.png";
import service from "@assets/aboutUs/service.svg";
import title1 from "@assets/aboutUs/license/title_img/title_NFA.png";
import title2 from "@assets/aboutUs/license/title_img/title_MSB.png";
import title3 from "@assets/aboutUs/license/title_img/title_CR.png";
import title4 from "@assets/aboutUs/license/title_img/title_HK.png";
import big_nfa from "@assets/aboutUs/license/license_info/big_nfa.png";
import big_msb from "@assets/aboutUs/license/license_info/big_msb.png";
import big_cs from "@assets/aboutUs/license/license_info/big_cs.png";
import big_hk from "@assets/aboutUs/license/license_info/big_hk.png";
import star from "@assets/aboutUs/star.svg";
import media1 from "@assets/aboutUs/media1.png";
import media2 from "@assets/aboutUs/media2.png";

// style
import styles from "./index.module.scss";

export default function AboutUs(props) {
	const [imgIndex, setImgIndex] = useState(1);

	return (
		<Layout customClass={styles.aboutUsWrapper}>
			<img src={mission} alt="" />
			<div className={styles.section}>
				<div className={styles.main}>
					<h6>交易通</h6>
					<span>业内领先的金融衍生品经纪商</span>
					<p>
						交易通是美国信达资本集团旗下领先的国际期货互联网交易品牌，经营主体为中衍(香港)期貨有限公司，提供主要环球衍生品市场的交易结算服务、优越的市场接入和风险管理解决方案，并可针对不同客户需求量身定制个性化、差异化服务。公司经美国金融监管机构权威认证，持有美国全国期货协会颁发的NFA，美国财政部FinCEN颁发的MSB等金融合法牌照。
					</p>
					<p>
						信达资本集团创始于2003年，总部位于美国，在香港、澳大利亚、欧盟的爱沙尼亚等地设有分公司。集团管理团队拥有数十年国际金融市场经验和互联网产品技术背景，主要成员来自芝加哥交易所集团、高盛、摩根士丹利、嘉信理财、谷歌、腾讯等知名金融机构和互联网公司，全球共近千名员工。无论客户身处何处，我们都能协助广大客户及时掌握市场信息，即时作出决策，在瞬息万变的全球金融市场中掌握投资先机。
					</p>
				</div>
			</div>
			<img src={safe} alt="" />
			<div className={styles.service}>
				<div className={styles.main}>
					<h6>服务优势</h6>
					<img src={service} alt="" />
				</div>
			</div>
			<div className={styles.qualification}>
				<a href="#" name={"qualification"} />
				<img src={qualification} className={styles.last} alt="" />
				<div className={styles.main}>
					<div className={styles.left}>
						<h5>资质监管</h5>
						<p>
							交易通是美国信达资本集团旗下国际期货交易平台的品牌和商标，经营主体为中衍(香港)期貨有限公司，总部位于美国，持有美国全国期货协会颁发的NFA，美国财政部FinCEN颁发的MSB等金融合法牌照，并在香港有分公司以满足亚太地区客户快速增长的金融服务需求。
						</p>
					</div>
					<div className={styles.right}>
						<div className={styles.titleImg}>
							<img src={title1} alt="" onMouseEnter={() => setImgIndex(1)} />
							<img src={title2} alt="" onMouseEnter={() => setImgIndex(2)} />
							<img src={title3} alt="" onMouseEnter={() => setImgIndex(3)} />
							<img src={title4} alt="" onMouseEnter={() => setImgIndex(4)} />
						</div>
						{imgIndex === 1 && (
							<div className={`${styles.imgBox} ${styles.nfa}`}>
								<ImgMagnifier className={styles.magnifier} src={big_nfa} width={464} height={360} />
							</div>
						)}
						{imgIndex === 2 && (
							<div className={styles.imgBox}>
								<ImgMagnifier className={styles.magnifier} src={big_msb} width={298} height={400} />
							</div>
						)}
						{imgIndex === 3 && (
							<div className={styles.imgBox}>
								<ImgMagnifier className={styles.magnifier} src={big_cs} width={298} height={400} />
							</div>
						)}
						{imgIndex === 4 && (
							<div className={styles.imgBox}>
								<ImgMagnifier className={styles.magnifier} src={big_hk} width={298} height={400} />
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={styles.mediaBox}>
				<h6>媒体报道</h6>
				<div>
					<img src={star} alt="" />
					<div>
						<img src={media1} alt="" />
						<img src={media2} alt="" />
					</div>
				</div>
			</div>
		</Layout>
	);
}
