import React from "react";
import ReactDOM from "react-dom/client";
import { notification, message } from "antd";
import "./style/init.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import CX from "./core/index";
import Store from "./store";
import { getSearch, setCookie } from "./utils";

const root = ReactDOM.createRoot(document.getElementById("root"));

message.config({
	top: 400,
	duration: 1.5,
	maxCount: 3,
});

notification.config({
	placement: "topRight",
	top: 100,
	duration: 2,
});
CX.Launcher.start()
	.then(() => {
		Store.User.initUser();
		Store.Wallet.updateWallet();
		const params = getSearch(window.location.href);
		if (params?.ru) {
			const ru = params?.ru;
			setCookie("ru", ru);
			Store.User.getRu(ru);
		}
		if (params?.f) {
			Store.User.getFrom(params.f);
		}
	})
	.catch((err) => console.warn(err));
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
