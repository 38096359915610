export const INPUT = {
	/**
	 * 数字
	 */
	NUMBER: /^[0-9]*$/,
	/**
	 * 字母
	 */
	ALPHABET: /^[A-Za-z]+$/,
	/**
	 * 中文
	 */
	CHINESE: /^[\u4e00-\u9fa5]{0,}$/,
	/**
	 * 符号
	 */
	SYMBOL: 4,
	/**
	 * 账号(数字+字母)
	 */
	ACCOUNT: 5,
	/**
	 * 昵称(数字+字母+中文)
	 */
	NICKNAME: 6,
};

export const TEST = {
	/**
	 * 密码
	 */
	PASSWORD: /^.{6,16}$/,

	SET_WITHDRAWPASSWORD: /^(?=.{6,16})(?=.*[a-zA-Z])(?=.*[0-9])[0-9a-zA-Z]*$/,
	/**
	 * 注册密码
	 */
	// SET_PASSWORD: /^[A-Za-z0-9!@#$%^&*?]{6,16}$/,
	SET_PASSWORD: /^.*(?=.{6,16})(?=.*\d)(?=.*[a-z])[A-Za-z0-9!@#$%^&*?].*$/,
	/**
	 * 强力密码
	 */
	STRONG_PASSWORD: /^.*(?=.{6,16})(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*?]).*$/,
	/**
	 * 中文名
	 */
	CNNAME: /^[\u4e00-\u9fa5·]{0,15}$/,
	/**
	 * 昵称
	 */
	NICKNAME: /^[\u4e00-\u9fa5A-Za-z0-9-_]{2,16}$/,
	/**
	 * 银行卡
	 */
	BANKCARD: /^[1-9]\d{15,18}$/,
	/**
	 * 验证码
	 */
	CAPTCHA: /^\d{4}$/,

	GLOBAL_CAPTCHA: /^\d{6}$/,

	/**
	 * 手机号
	 */
	PHONE: /^1[023456789]\d{9}$/,
	/**
	 *身份证号
	 */
	ID: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,

	GLOBAL_TEL: /^\d{5,15}$/,

	EMAIL: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
};
