import { getIdentity, getPlatform } from "@/utils/index";
import service from "../network/axios";
import { passport } from "@/utils/adv";
import Store from "@/store";

/**
 * 数据初始化
 */
const init = async () => {
	const login = window.localStorage.getItem("isLogin");
	if (!!login && JSON.parse(login)) {
		const { isLogin } = await service.post("/api/user/isLogin");
		console.log("login status " + isLogin);
		return isLogin;
	} else {
		return login === null ? false : true;
	}
};
/**
 * 持续更新数据
 */
const _keepUpdateDetail = () => {
	return service.get("/api/user/detail");
};
/**
 * 登录
 * @param {String} [username] 用户名
 * @param {String} [password] 密码
 */
const login = async (data) => {
	const { account, password, countryCode, countryId, ignore } = data || {};
	if (!ignore) {
		// geetestToken = await this.geetest();
	}
	const o = {
		username: account,
		password,
		// geetestToken,
		vHash: getIdentity(32),
		terminal: getPlatform(),
	};
	let result = await service.post("/api/sso/user_login_check", o);
	return result;
};
/**
 * 退出登录
 */
const logout = async () => {
	const result = await service.post("/api/sso/user_logout");
	return result;
};
/**
 * 获取历史签到信息
 */
const getCheckInStatus = async () => {
	const result = await service.get("/api/user/checkin/history.htm");
	return result;
};
/**
 * 签到
 */
const checkIn = async () => {
	const result = await service.get("/api/user/checkin/pound.htm");
	return result;
};
/**
 * 获取活动信息
 */
const getActiveTask = () => {
	return service.get("/api/activity/task.htm");
};
/**
 * 获取推广详情
 */
const updatePromotionInfo = () => {
	return service.get("/api/mine/union.htm");
};
/**
 * 获取推广详情数据
 */
const updateUserList = () => {
	return service.get(`/api/mine/agent/sub-list?rows=${99999}`);
};
/**
 * 发送手机验证码
 * @param
 * REGISTER           注册
  LOGIN              登录
  BIND_PHONE         绑定手机
  BIND_EMAIL         绑定邮箱
  BIND_GA            绑定谷歌验证器
  UNBIND_PHONE       解绑手机
  UNBIND_EMAIL       解绑邮箱
  UNBIND_GA          解绑谷歌验证器
  FORGOT_PASSWORD    忘记登录密码
  FORGOT_WITHDRAW    忘记提款密码
  CHANGE_PASSWORD    修改登录密码
  CREATE_ADDRESS     添加提币地址
  CREATE_WITHDRAW    提币申请
  CREATE_ACCESS      申请密钥
  CHANGE_ACCESS      修改秘钥
 */
const sendSMS = (data) => {
	const { type, hash, code, account, geetestToken, countryCode } = data;
	const o = {
		type,
		account,
		// countryCode,
		vHash: hash,
		vCode: code,
	};
	if (geetestToken) {
		o.geetestToken = geetestToken;
	}
	return service.post("/api/system/sendSMS", o);
};

const sendEmail = (data) => {
	const { type, hash, code, account, geetestToken } = data;
	const o = {
		type,
		account,
		vHash: hash,
		vCode: code,
	};
	if (geetestToken !== "") {
		o.geetestToken = geetestToken;
	}
	return service.post("/api/system/sendEmail", o);
};

const verifySMS = (account, code, type) => {
	const o = {
		type,
		account,
		code,
	};
	return service.post("/api/system/checkSMS", o);
};

const verifyEmail = (account, code, type) => {
	const o = {
		type,
		account,
		code,
	};
	return service.post("/api/system/checkEmail", o);
};

const bindEmail = (email) => {
	const o = {
		email,
	};
	return service.post("/api/user/bind-email", o);
};

/**
 * 绑定邮箱
 * @param {String} [oldEamil] 旧邮箱
 * @param {String} [newEmail] 新邮箱
 */
const updateEmail = (oldEmail, newEmail) => {
	const o = { oldEmail, newEmail };
	return service.post("/api/user/update-email", o);
};

/**
 * 绑定手机
 * @param {String} [phone] 手机
 */
const bindPhone = (phone) => {
	const o = { phone };
	return service.post("/api/user/bind-phone", o);
};
/**
 * 修改手机
 * @param {String} [oldphone] 旧手机
 * @param {String} [newPhone] 新手机
 */
const updatePhone = (oldPhone, newPhone) => {
	const o = {
		oldPhone,
		newPhone,
	};
	return service.post("/api/user/update-phone", o);
};
/**
 *注册
 * @param {Object} data
 * @param {String} [account] 账号
 * @param {String} [password] 密码
 * @param {Number} [countryCode] 国家代码
 * @param {String} [countryId] 国家ID
 */
const register = (data) => {
	const { account, password, username, countryCode, countryId, ru } = data;
	const f = Store.User.f;
	const o = { password };
	if (account.indexOf("@") === -1) {
		o.phone = account;
	} else {
		o.email = account;
	}
	if (username) {
		o.username = username;
	}
	o.sign = passport(o);
	if (ru) o.ru = ru;
	if (f) o.f = f;
	return service.post("/api/register/submit", o);
};
/**
 * 旧版本 实名认证
 * @param {String} [identityName] 姓名
 * @param {String} [identityNumber] 身份号码
 */
const nameVerification = (name, identityNumber) => {
	const o = {
		action: "authIdentity",
		name,
		identityNumber,
	};
	return service.post("/api/mine/profileAuth.htm", o);
};
/**
 * kyc1 实名认证第一步
 * @param {String} [identityName] 姓名
 * @param {String} [identityNumber] 身份号码
 * @param {Number} [countryId] 国家ID （47 中国ID）
 * @param {Number} [identityType] 1=身份证 2=护照
 */
const kycStepOne = (data) => {
	const { identityName, identityNumber, countryId, identityType = 1 } = data;
	const name = encodeURI(identityName);
	const o = { identityName: name, identityNumber, identityType };
	countryId === undefined ? (o.countryId = 47) : (o.countryId = countryId);
	return service.post("/api/mine/profile/kyc1", o);
};
/**
 * kyc2 验证实名认证
 * @returns
 */
const ValidKycStepOne = () => {
	const result = service.get("/api/mine/profile/lastKyc1");
	return result;
};

/**
 * kyc2 验证实名认证
 * @returns
 */
const ValidKycStepTwo = () => {
	const result = service.get("/api/mine/profile/lastKyc2");
	return result;
};

/**
 *  设置/修改提款密码
 *  @param {String} [password] 旧密码 或 登录密码
 *  @param {String} [withdrawPw] 新密码
 *  @param {String} [withdrawPwCfm] 确认密码
 */
const withdrawPassword = (data) => {
	const { oldPass, newPass, cfmPass } = data;
	const o = {
		password: oldPass,
		withdrawPw: newPass,
		withdrawPwCfm: cfmPass,
	};
	return service.post("/api/mine/atmPasswd.htm", o);
};
/**
 *  修改登录密码
 *  @param {String} [password] 旧登录密码
 *  @param {String} [withdrawPw] 新密码
 *  @param {String} [withdrawPwCfm] 确认密码
 */
const changeLoginPassword = (data) => {
	const { oldPass, newPass, cfmPass } = data;
	let o = {
		oldPass,
		newPass,
		newPassCfm: cfmPass,
	};
	return service.post("/api/mine/loginPasswd.htm", o);
};
/**
 * 获取银行卡
 */
const getAllBankCard = () => {
	return service.get("/api/mine/bankCard.htm");
};
/**
 *  设置默认银行卡
 *  @param {String} [action] 请求类型
 *  @param {String} [id] 银行卡ID
 */
const setDefaultBankCard = (id) => {
	const o = {
		action: "setDefault",
		id,
	};
	return service.post("/api/mine/bankCardUpdate.htm", o);
};
/**
 *  删除银行卡
 *  @param {String} [action] 请求类型
 *  @param {String} [id] 银行卡ID
 */
const deleteBankCard = (id) => {
	const o = {
		action: "del",
		id,
	};
	return service.post("/api/mine/bankCardUpdate.htm", o);
};
/**
 *  添加银行卡
 */
const addBankCard = (data) => {
	const { bankType, province, city, cardNumber, cfmCardNumber, subbranch } = data;
	const o = {
		action: "add",
		bank: bankType,
		province,
		city,
		subbranch,
		cardNumber: cardNumber.replace(/\s+/g, ""),
		cardNumberCfm: cfmCardNumber.replace(/\s+/g, ""),
	};
	return service.post("/api/mine/bankCardAdd.htm", o);
};
/**
 *  添加支付宝
 */
const addAlipay = (cardNumber) => {
	const o = {
		action: "addALP",
		bank: "支付宝",
		cardNumber: cardNumber.replace(/\s+/g, ""),
		cardNumberCfm: cardNumber.replace(/\s+/g, ""),
	};
	console.log(cardNumber, o)
	return service.post("/api/mine/bankCardAdd.htm", o);
};

/**
 *  修改银行卡信息
 *  @param {*} province 省份
 *  @param {*} city 城市
 *  @param {*} subbranch 支行
 *  @param {*} id 银行卡ID
 */
const changeBankInfo = (province, city, subbranch, id) => {
	const o = {
		action: "update",
		province,
		city,
		subbranch,
		id,
	};
	return service.post("/api/mine/bankCardUpdate.htm", o);
};
/**
 * 提款
 * @param {*} data  money 金额 bankCard 银行卡ID password 密码
 */
const withdraw = async (data) => {
	const { money, bankCard, password } = data;
	const o = {
		type: 0,
		action: "apply",
		currency: "CNY",
		money,
		bankCard,
		password,
	};
	const result = await service.post("/api/pay/withdraw.htm", o);
	return result;
};
/**
 * 取消提款
 * @param {*} id 提款ID
 * @returns
 */
const cancelWithdraw = async (id) => {
	const o = { id, action: "cancel" };
	const result = await service.get("/api/pay/withdraw.htm", { params: o });
	return result;
};
/**
 * 安全验证(获取Token)
 * @param {*} account 账户
 */
const securityVerify = async (account) => {
	const o = { account };
	const result = await service.post("/api/forgot/securify-verify", o);
	return result;
};
/**
 * 找回提款密码
 * @param {*} data
 */
const retrieveFundsPass = async (data) => {
	const { account, oldPassword, newPassword } = data;
	const o = {
		account,
		withdrawPw: newPassword,
	};
	if (oldPassword) {
		o.password = oldPassword;
	}
	const result = await service.post("/api/user/update-withdrawPw", o);
	return result;
};
/**
 * 找回登录密码
 * @param {*} data
 */
const retrieveLoginPass = async (data) => {
	const { newPass, cfmPass, token } = data;
	const o = {
		newPassword: newPass,
		cfmPassword: cfmPass,
		token,
	};
	const result = await service.post("/api/forgot/reset-password", o);
	return result;
};

/**
 * 设置自选
 */
const getFavor = async () => {
	const result = await service.get("/api/user/favor/detail");
	return result;
};

const setFavor = async (id, del) => {
	const o = {
		commodityCodes: id,
	};
	if (del) o.delete = del;
	const result = await service.post("/api/user/favor/update", o);
	return result;
};

/**
 * 设置获取默认止盈止损
 * @returns
 */
const getOrderDefault = async () => {
	const result = await service.get("/api/user/setting/detail");
	return result;
};

/**
 * 保存设置
 */
const saveOrderDefault = async (o) => {
	const result = await service.post("/api/user/setting/update", o);
	return result;
};

/**
 * 国家列表（注册或登录时的手机号国际代码）
 * @returns
 */
const country = async () => {
	const result = await service.get("/api/home/country/list");
	return result;
};

/**
 * 根据IP定位国家
 * @returns
 */
const location = async () => {
	const result = await service.get("/api/home/country/location");
	return result;
};

/**
 * 修改昵称
 * @param {*} username
 * @returns
 */
const updateNickname = async (username) => {
	const o = {
		username,
	};
	const result = await service.post("/api/user/update-username", o);
	return result;
};

/**
 * 获取提币地址数据
 * @returns
 */
const getWithdrawCoinAddress = async () => {
	const o = {};
	const result = await service.get("/api/user/withdraw-address/list", o);
	return result;
};

/**
 * 删除提币地址
 * @param {*} addressID
 * @returns
 */
const deleteWithdrawCoinAddress = async (addressID) => {
	const result = await service.post(`/api/user/withdraw-address/delete/${addressID}`);
	return result;
};

/**
 * 添加提笔地址
 * @param {*} currency
 * @param {*} address
 * @param {*} remark
 * @param {*} chain
 * @param {*} addressTag
 * @returns
 */
const addWithdrawCoinAddress = async (currency, address, remark, chain, addressTag) => {
	let data = {
		address: address,
		currency: currency,
		remark: remark,
		chain: chain,
	};
	if (addressTag !== "") {
		data.addressTag = Number(addressTag);
	}
	const result = await service.post("/api/user/withdraw-address/create", data);
	return result;
};

/**
 * 专属客服
 * @returns
 */
const getSuperiorContact = async () => {
	const result = await service.get("/api/user/superior-contact");
	return result;
};

const updateAgentStat = async (currency) => {
	const result = await service.get("/api/mine/agent/sub-stat", { params: { currency } });
	return result;
};

/**
 *
 * @param {Object} [options]
 * @param {Number} [options.createTimeGe] 创建时间-起
 * @param {String} [options.createTimeLe] 创建时间-止
 * @param {String} [options.currency] 币种
 * @param {String} [options.subName] 下级用户名
 * @param {Number} [options.biz] BIZ_FUTURE-合约 BIZ_SPOT-现货
 * @param {Number} [options.type] COMMISSION_PROMOTE-推广 COMMISSION_TRADER-带单
 * @param {Number} [options.page] 页数
 * @param {Number} [options.rows] 每页记录数
 * @return {Promise<unknown>}
 */
const updateCommission = async (options) => {
	options = options || {};
	options = Object.assign({ page: 1, rows: 10, _: new Date().getTime() }, options);
	const result = await service.get("/api/mine/agent/commission", { params: options });
	return result;
};

/**
 * 累积佣金
 */
const updateCommissionTotal = async () => {
	const result = await service.get("/api/mine/agent/commission-all");
	return result;
};

const updateDayCommission = async () => {
	const result = await service.get("/api/mine/agent/commission-day");
	return result;
};

export default {
	init,
	login,
	logout,
	_keepUpdateDetail,
	getCheckInStatus,
	checkIn,
	getActiveTask,
	updatePromotionInfo,
	updateUserList,
	sendSMS,
	sendEmail,
	verifySMS,
	verifyEmail,
	updateEmail,
	bindEmail,
	register,
	nameVerification,
	kycStepOne,
	ValidKycStepOne,
	ValidKycStepTwo,
	getAllBankCard,
	setDefaultBankCard,
	deleteBankCard,
	withdrawPassword,
	changeLoginPassword,
	addBankCard,
	changeBankInfo,
	updatePhone,
	bindPhone,
	withdraw,
	cancelWithdraw,
	securityVerify,
	retrieveFundsPass,
	retrieveLoginPass,
	getFavor,
	setFavor,
	getOrderDefault,
	saveOrderDefault,
	country,
	location,
	getSuperiorContact,
	updateNickname,
	getWithdrawCoinAddress,
	deleteWithdrawCoinAddress,
	addWithdrawCoinAddress,
	addAlipay,
	updateAgentStat,
	updateCommission,
	updateCommissionTotal,
	updateDayCommission,
};
