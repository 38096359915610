import React, { useEffect, useState, useMemo, useRef } from "react";
import { message, notification } from "antd";
// utils
import { arrayToStr } from "@/utils";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import Layout from "./modal";
import SimpleInput from "@/view/components/SimpleInput";
import ImgValid from "@/view/components/ImgValid";
import { imgValidModal } from "@/view/components/Modal";

// style
import styles from "./index.module.scss";

export default function BindPhone({ close }) {
	const [editType] = useState("BIND_PHONE");
	const [account, setAccount] = useState("");
	const [msgCode, setMsgCode] = useState("");
	const [formatVerify, setFormatVerify] = useState({ phone: false, code: false });
	const [countText, setCountText] = useState("获取验证码");
	const [countTime, setCountTime] = useState(null);
	const [isSend, setIsSend] = useState(false);
	const timer = useRef();

	useEffect(() => {
		return () => {
			clearTimeout();
		};
	}, []);
	useEffect(() => {
		if (countTime === null) {
			return;
		}
		if (countTime === 0) {
			message.warning("输入超时，请重新发送验证码!");
			setCountText("获取验证码");
			setIsSend(false);
			setCountTime(null);
			clearTimeout(timer.current);
		} else {
			timer.current = setTimeout(() => {
				setCountTime((time) => time - 1);
				setCountText(`${countTime}秒后重发`);
				setIsSend(true);
			}, 1000);
		}
	}, [countTime]);

	// 輸入欄位驗證
	const isValid = useMemo(() => {
		return Object.values(formatVerify).every((value) => !!value);
	}, [formatVerify]);

	const showCode = () => {
		if (!formatVerify.phone) {
			return notification.warn({ message: "请输入手机号码" });
		}
		if (isSend) return;
		imgValidModal(
			<ImgValid account={account} sendCodeStatus={() => setCountTime(90)} type={editType} isMask={false} />
		);
	};

	const bindPhone = () => {
		EVENT.Account.bindPhone(account)
			.then(() => {
				Store.User.updateDetail();
				notification.success({
					message: "绑定成功",
				});
				close();
			})
			.catch(({ message }) => notification.error({ message }));
	};

	const submit = () => {
		if (!isValid) {
			return;
		}
		EVENT.Account.verifySMS(account, msgCode, editType)
			.then(() => {
				bindPhone();
			})
			.catch(({ message }) => notification.error({ message }));
	};

	return (
		<Layout close={close} title="绑定手机">
			<SimpleInput
				label="手机号码"
				placeholder="请输入手机号码"
				type={"tel"}
				format={"PHONE"}
				notice={"tel"}
				maxLength={11}
				value={account}
				onChange={(phone, format) => {
					if (format !== formatVerify.phone) {
						setFormatVerify((props) => ({ ...props, phone: format }));
					}
					setAccount(phone);
				}}
			/>
			<div className={styles.sendCode}>
				<SimpleInput
					customClass={styles.simpleInput}
					label="短信验证码"
					placeholder="请输入手机验证码"
					type={"verifyCode"}
					format={"GLOBAL_CAPTCHA"}
					notice={"msgCode"}
					value={msgCode}
					onChange={(code, format) => {
						if (format !== formatVerify.code) {
							setFormatVerify((props) => ({ ...props, code: format }));
						}
						setMsgCode(code);
					}}
				/>
				<div className={styles.getCode} onClick={showCode}>
					{countText}
				</div>
			</div>
			<div className={arrayToStr(["confirm_btn", styles.confirmBtn, !isValid ? "error" : ""])} onClick={submit}>
				确认
			</div>
		</Layout>
	);
}
