import React, { useState, useMemo } from "react";
import { notification } from "antd";
// utils
import { color, getBankIcon } from "@/utils/common";
import { arrayToStr, idMask } from "@/utils";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import FormModal from "@/view/components/Modal/form";
import SimpleInput from "@/view/components/SimpleInput";
import Linkage from "./linkage";
import { BankIcon } from "@/view/components/Svg";

// style
import styles from "./index.module.scss";

export default function EditBankcard({ accountInfo, editInfo, close }) {
	const [province, setProvince] = useState(editInfo.province);
	const [city, setCity] = useState(editInfo.city);
	const [subbranch, setSubBranch] = useState(editInfo.subbranch);
	const { name } = accountInfo;
	const isVerify = useMemo(() => province && city && subbranch, [province, city, subbranch]);
	const submit = () => {
		if (!isVerify) {
			return;
		}
		if (!/^[\u4e00-\u9fa5_a-zA-Z]{0,}$/.test(subbranch)) {
			return notification.error({ message: "开户支行信息输入格式错误，请重新输入" });
		}
		EVENT.Account.changeBankInfo(province, city, subbranch, editInfo.id)
			.then(() => {
				notification.success({ message: "银行卡信息修改成功", onClose: () => Store.User.updateDetail() });
				close();
			})
			.catch(({ message }) => notification.error({ message }));
	};
	return (
		<FormModal title="修改银行卡" close={close}>
			<div className={arrayToStr([styles.editBankBox, styles[color(editInfo.bank)]])}>
				<div className={styles.imgBox}>
					<div className={styles.img}>
						<BankIcon img={getBankIcon(editInfo.bank)} />
					</div>
				</div>
				<div className={styles.info}>
					<div className={styles.bankName}>
						<b>{editInfo.bank}</b>
						<p>储蓄卡</p>
					</div>
					<div className={styles.bankcard}>{idMask(editInfo.cardNumber)}</div>
				</div>
			</div>
			<SimpleInput label={"开户姓名"} value={name} disable={true} />

			<Linkage
				province={province}
				city={city}
				getProvince={(val) => setProvince(val)}
				getCity={(val) => setCity(val)}
			/>
			<SimpleInput
				label={"开户支行"}
				placeholder={"请输入银行卡开户支行"}
				type={"text"}
				maxlength={30}
				value={subbranch}
				onChange={(val) => {
					setSubBranch(val);
				}}
			/>
			<div className={`confirm_btn ${!isVerify ? "error" : ""} ${styles.confirmBtn}`} onClick={submit}>
				修改
			</div>
		</FormModal>
	);
}
