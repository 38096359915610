import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { message, notification } from "antd";

// lib
import EVENT from "@/library/api";
// utils
import { isEmpty, formatDate, arrayToStr, getSearch } from "@/utils";
import { statusText, paymentImg } from "@/utils/common";

// components
import { UserIcon } from "@/view/components/Svg";
import SimpleInput from "@/view/components/SimpleInput";

// assets
import funds from "@assets/common/funds_light.png";
// style
import styles from "./index.module.scss";

const Fiat = ({ balance, list: fundList, update }) => {
	const [quick] = useState([300, 500, 1000, 2000, 5000, 10000, 20000]);
	const [payInfo, setPayInfo] = useState({ list: [], first: true });
	const [amount, setAmount] = useState("");
	const [activePayment, setActivePayment] = useState({});
	const [accountName, setAccountName] = useState("");
	useEffect(() => {
		getPaymentList();
		return () => {
			localStorage.removeItem("_CX_deposit");
		};
	}, []);

	const isNeedAccountName = useMemo(() => activePayment.isNeedAccountName, [activePayment]);
	const isValid = useMemo(
		() => (activePayment.isNeedAccountName ? !!amount && !!accountName : !!amount),
		[activePayment, amount, accountName]
	);

	const getPaymentList = () => {
		let paymentInfo = localStorage.getItem("_CX_deposit");
		if (paymentInfo) {
			paymentInfo = JSON.parse(paymentInfo);
			setPayInfo(dealData(paymentInfo));
			return;
		}
		EVENT.Payment.getPaymentList()
			.then(({ payList, payFirst }) => {
				if (isEmpty(payList)) return;
				paymentInfo = { list: payList, first: payFirst };
				localStorage.setItem("_CX_deposit", JSON.stringify(paymentInfo));
				setPayInfo(dealData(paymentInfo));
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	const dealData = (data) => {
		return {
			...data,
			list: data.list
				.filter((item) => !item.name.includes("UBIT"))
				.map((item) => {
					const { app = "bank", min, max, type, confirm } = getSearch(item.url);
					return { ...item, app, min, max, isNeedAccountName: type.includes("NAME"), confirm };
				}),
		};
	};
	const submit = () => {
		if (!isValid) {
			return;
		}
		if (isEmpty(activePayment)) {
			return notification.warning({ message: "请选择充值方式" });
		}
		const { max, min } = activePayment;
		if (Number(amount) > Number(max)) {
			return message.warning(`充值金额不能超过${max}元!`);
		}
		if (Number(amount) < Number(min)) {
			return message.warning(`充值金额不能低于${min}元!`);
		}
		const o = {
			url: activePayment.url,
			money: amount,
			channel: "ALIPAY_WAP",
		};
		if (isNeedAccountName) o.accountName = accountName;
		EVENT.Payment.fastPayment(o)
			.then((url) => {
				message.success("加载中...");
				update();
				window.open(url, "_blank");
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	return (
		<>
			<div className={styles.fiatRecharge}>
				<div className={styles.fiatBalance}>
					<p>账户余额</p>
					<div>
						<b>
							{balance} <span>CNY</span>
						</b>
					</div>
				</div>
				<div className={styles.payMethod}>
					<label>支付方式</label>
					<div className={styles.payBox}>
						{payInfo.list.map((item) => (
							<div
								key={item.id}
								className={arrayToStr([styles.pay, activePayment.id === item.id ? styles.active : ""])}
								onClick={() => setActivePayment(item)}
							>
								<UserIcon img={paymentImg(item.app)} />
								<div>
									<b>{item.name}</b>
									<p>{item.descr}</p>
								</div>
							</div>
						))}
					</div>
				</div>
				{isNeedAccountName && (
					<div className={styles.payName}>
						<SimpleInput
							customClass={styles.simpleInput}
							label={"充值账户姓名"}
							placeholder={"请输入充值使用的账户姓名"}
							type={"text"}
							value={accountName}
							onChange={(val) => setAccountName(val.replace(/<[^>]+>/g, ""))}
						/>
					</div>
				)}
				<div className={styles.payMoney}>
					<SimpleInput
						label={"充值金额"}
						placeholder={"请输入充值金额"}
						type={"number"}
						maxLength={8}
						value={amount}
						onChange={(val) => {
							setAmount(val);
						}}
					/>
					{quick.map((item) => {
						return (
							<div
								key={item}
								className={`${styles.money} ${amount === item ? styles.active : ""}`}
								onClick={() => setAmount(item)}
							>
								{item}
							</div>
						);
					})}
				</div>
				<div className={styles.submitBox}>
					<div className={styles.payBtn}>
						<p>
							应付金额：<b>{!amount ? 0 : amount} CNY</b>
						</p>
						<div className={`confirm_btn ${!isValid ? "error" : ""}`} onClick={submit}>
							确定
						</div>
					</div>
					<div className={styles.tips}>
						<div>
							<h6>温馨提示</h6>
							<p>· 申请金额与转账金额必须一致，否则无法到账。</p>
							<p>· 使用支付宝转账，请在转账时间内完成，否则不会自动到账。</p>
							<p>· 第三方收款账户不定期更新，充值前需获取最新收款信息。</p>
							<p>· 操作过程中如有问题，请联系客服。</p>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.recentRecord}>
				<div className={styles.record}>
					<h5>近期充值记录</h5>
					<Link to={{ pathname: "/account/fundsDetail" }}>更多 》</Link>
				</div>
				<ul>
					<li className={styles.header}>
						<div>时间</div>
						<div>金额(元)</div>
						<div>ID</div>
						<div>状态</div>
					</li>
					{fundList.map((item) => (
						<li key={item.id}>
							<div>{formatDate("m-d h:i:s", { date: item.createTime })}</div>
							<div>{item.srcMoney}</div>
							<div>{item.id}</div>
							<div>{statusText(item.status)}</div>
						</li>
					))}
				</ul>
				{isEmpty(fundList) && (
					<div className={`empty ${styles.empty}`}>
						<div>
							<img src={funds} alt="" />
							<b>暂无记录</b>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default Fiat;
