import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
// lib
import EVENT from "@/library/api";
// utils
import { formatDate } from "@/utils";
// style
import styles from "./index.module.scss";

const LoginHistory = () => {
	const [loginList, setLoginList] = useState([]);
	useEffect(() => {
		EVENT.History.getLoginHistory().then(({ data }) => setLoginList(data));
	}, []);
	return (
		<div className={styles.loginHistory}>
			<h5>账户活动</h5>
			<div className={styles.main}>
				<div className={styles.title}>登录历史</div>
				<div className={styles.list}>
					<div className={styles.header}>
						<div className={styles.country}>国家/地区</div>
						<div className={styles.system}>系统&浏览器</div>
						<div className={styles.ip}>IP地址</div>
						<div className={styles.time}>时间</div>
					</div>
					{loginList.map((item, key) => (
						<div className={styles.item} key={key}>
							<div className={styles.country}>
								<b>{item.location || item.region || "未知"}</b>
							</div>
							<div className={styles.system}>
								<p>{item.agent}</p>
							</div>
							<div className={styles.ip}>{item.ip}</div>
							<div className={styles.time}>{formatDate("m-d-y h:i:s", { date: item.time })}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default observer(LoginHistory);
