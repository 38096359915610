import React, { useEffect, useState, useRef } from "react";
import logo from "@assets/common/logo.svg";
const useDynamicSVGImport = (source, options = {}) => {
	const ImportedIconRef = useRef();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const { onCompleted, onError } = options;

	useEffect(() => {
		setLoading(true);
		const importIcon = async () => {
			try {
				console.log(source);
				const ReactComponent = (await import(`@assets/${source}`)).ReactComponent;
				ImportedIconRef.current = ReactComponent;
				console.log(ReactComponent);
				if (onCompleted) {
					onCompleted(source, ImportedIconRef.current);
				}
			} catch (err) {
				console.log(err);
				if (onError) {
					onError(err);
				}
				setError(err);
			} finally {
				setLoading(false);
			}
		};
		importIcon();
	}, [source, onCompleted, onError]);

	return { error, loading, SvgIcon: ImportedIconRef.current };
};
const SvgComp = ({ svgClass, onClick, source, onCompleted, onError }) => {
	const { error, loading, SvgIcon } = useDynamicSVGImport(source, {
		onCompleted,
		onError,
	});
	if (error) {
		console.log(error);
		return error.message;
	}
	if (loading) {
		console.log("Loading");
		return "Loading...";
	}
	if (SvgIcon) {
		return <SvgIcon className={svgClass} onClick={onClick} />;
	}
	return null;
};
function imgErrorHandler() {
	console.log(111, this);
}

const SvgContainer = (props) => {
	try {
		return props.isSvgComponent ? (
			<SvgComp {...props} />
		) : (
			<img
				src={require(`@assets/${props.source}`)}
				alt=""
				className={props.svgClass}
				onClick={props.onClick}
				style={props.style}
			/>
		);
	} catch (e) {
		return <img src={logo} className={props.svgClass} onClick={props.onClick} style={props.style} alt="" />;
	}
};

export const CommonIcon = (props) => <SvgContainer {...props} source={`common/${props.img}.svg`} />;
export const HomeIcon = (props) => <SvgContainer {...props} source={`home/${props.img}.svg`} />;
export const TradeIcon = (props) => <SvgContainer {...props} source={`trade/${props.img}.svg`} />;
export const UserIcon = (props) => <SvgContainer {...props} source={`userCenter/${props.img}.svg`} />;
export const DownloadIcon = (props) => <SvgContainer {...props} source={`download/${props.img}.svg`} />;
export const CooperationIcon = (props) => <SvgContainer {...props} source={`cooperation/${props.img}.svg`} />;
export const ActivityIcon = (props) => <SvgContainer {...props} source={`activity/${props.img}.svg`} />;
export const BankIcon = (props) => <SvgContainer {...props} source={`userCenter/bank/bankcard/${props.img}.svg`} />;

export default SvgComp;
