const channelToSubscription = new Map();

export function getTradeViewData(data) {
  try {
    if (!data) return;
    const o = JSON.parse(data);
    const subscriptionItem = channelToSubscription.get(o.symbol);
    if (!subscriptionItem) return;
    if (o.s === "ok") {
      let bar = {
        time: o.t * 1000,
        open: o.o,
        high: o.h,
        low: o.l,
        close: o.c,
      };
      if (subscriptionItem.volume) {
        bar.volume = o.v;
      }
      for (let { callback } of subscriptionItem.handlers) {
        if (callback) {
          let left, right;
          if (subscriptionItem.lastDailyBar !== undefined) {
            if (subscriptionItem.resolution === "1D") {
              left = subscriptionItem.lastDailyBar.time;
              right = o.t * 1000 + 8 * 60 * 60 * 1000;
              if (right >= left) {
                bar.time = right;
                callback(bar);
              }
            } else {
              left = subscriptionItem.lastDailyBar.time;
              right = bar.time;
              if (right >= left) {
                callback(bar);
              }
            }
          }
        }
      }
    }
  } catch (e) {
    console.warn(e);
  }
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar,
  contracts
) {
  const channelString = symbolInfo.ticker.toUpperCase();
  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscribeUID,
    resolution,
    lastDailyBar,
    volume: symbolInfo.volume,
    handlers: [handler],
  };
  channelToSubscription.set(channelString, subscriptionItem);
  // let worker = null;
  // if (!window.ojbk) {
  //   WorkerSubscript(contracts._book);
  // }
  // worker = window.ojbk;
  // worker.postMessage(JSON.stringify({ cmd: "chart_resolution", resolution }));
}

export function unsubscribeFromStream(subscriberUID, contracts) {
  // find a subscription with id === subscriberUID
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex((handler) => handler.id === subscriberUID);

    if (handlerIndex !== -1) {
      // remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // unsubscribe from the channel, if it was the last handler
        console.log("[unsubscribeBars]: Unsubscribe from streaming. Channel:", channelString);
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}
