import React, { useMemo, useCallback } from "react";
// compoents
import Layout from "@/view/components/Layout";
import Popover from "@/view/components/Popover";
import { DownloadIcon } from "@/view/components/Svg";

// assets
import Top from "@assets/download/top.png";
import section2_1 from "@assets/download/section2-1.png";
import section2_2 from "@assets/download/section2-2.svg";
import section3_2 from "@assets/download/section3-2.svg";
import section3_1 from "@assets/download/section3-1.png";
import section4_1 from "@assets/download/section4-1.svg";
import section4_2 from "@assets/download/section4-2.svg";
import downloadQR from "@assets/home/download/downloadQR.png";
import download_qr from "@assets/common/qrCode0922.png";
// style
import styles from "./index.module.scss";

export default function Download(props) {
	const qrcode = useMemo(
		() => (
			<div className={styles.downloadQR}>
				<img src={download_qr} alt="" />
			</div>
		),
		[]
	);
	const handleDownload = useCallback(() => {
		window.open("https://cxx-apk.zbb6.top/jyt-gw.html", "_blank");
	}, []);
	return (
		<Layout customClass={styles.downloadWrapper}>
			<section className={styles.section1}>
				<img src={Top} alt="" />
				<div className={styles.main}>
					<h4>
						<DownloadIcon img="jiaoyitongyidongduan" />
					</h4>
					<p>
						<DownloadIcon img="yizhanshi" />
					</p>
					<div className={styles.btnBox}>
						<div className={styles.android} onClick={handleDownload} />
						<div className={styles.ios} onClick={handleDownload} />
						<Popover content={qrcode} placement="bottom">
							<img className={styles.qrCode} src={downloadQR} alt="" />
						</Popover>
					</div>
				</div>
			</section>
			<section className={styles.section2}>
				<div className={styles.main}>
					<div>
						<img className={styles.left} src={section2_1} alt="" />
					</div>
					<div>
						<img className={styles.right} src={section2_2} alt="" />
					</div>
				</div>
			</section>
			<section className={styles.section3}>
				<div className={styles.main}>
					<div>
						<img className={styles.right} src={section3_2} alt="" />
					</div>
					<div>
						<img className={`${styles.left} ${styles.pc}`} src={section3_1} alt="" />
					</div>
				</div>
			</section>
			<section className={styles.section4}>
				<div className={styles.main}>
					<img src={section4_1} alt="" />
					<img src={section4_2} alt="" />
				</div>
			</section>
		</Layout>
	);
}
