import { makeAutoObservable, runInAction } from "mobx";
import EVENT from "@/library/api";
import Storage from "@/library/storage";
import { RESTRICT } from "@/library/standard";
import Store from ".";
import CX from "@/core";
import { useNavigate } from "react-router";
import Brand from "../core/brand";

export class User {
	accountInfo = {
		username: "",
		unread: 0,
	};
	allAssets = [];
	assets = {
		money: 0,
		eagle: 0,
		game: 0,
		prize: 0,
	};
	favor = [];
	isLogin = window.localStorage.getItem("isLogin") || false;
	_loop = null;
	_detailLoop = null;
	ru = "";
	f = "";
	eye = true;

	constructor() {
		makeAutoObservable(this);
	}
	//todo 初始化
	initUser() {
		EVENT.Account.init()
			.then((e) => {
				if (e) {
					this.updateDetail();
					this.updateOrderDefault();
					if (Brand.code === "FK") {
						this.updateAssets(true);
					}
				} else {
					this.setLogout();
				}
			})
			.catch((e) => console.log(e));
	}

	//todo 登录回调
	loginCallback(info) {
		this.isLogin = true;
		CX.Launcher.CFD.getItemDetail();
		window.localStorage.setItem("isLogin", true);
		window.localStorage.setItem("mobile", info.mobile);
		window.localStorage.setItem("email", info.email);
		window.localStorage.setItem("principal", info.account === info.phone);
		this.updateDetail();
		this.updateOrderDefault();
		if (Brand.code === "FK") {
			this.updateAssets(true);
		} else {
			Store.Wallet.updateWallet();
		}
	}

	//todo 持续刷新用户信息
	updateDetail(val = false) {
		this.isLogin = true;
		EVENT.Account._keepUpdateDetail()
			.then(({ user }) => runInAction(() => (this.accountInfo = user)))
			.catch(({ code }) => {
				if (code === 401) {
					const navigate = useNavigate();
					navigate("/login");
					this.setLogout();
				}
			})
			.finally(() => {
				if (val) this._detailLoop = setTimeout(() => this.updateDetail(true), 15000);
			});
	}

	//todo 持续刷新资产信息
	updateAssets(val = false) {
		EVENT.Assets.getMyAssets()
			.then(({ data }) => {
				if (data) {
					Storage.setItem("_wallet_", JSON.stringify(data), 3600000).then(() => console.info("钱包信息缓存成功"));
					data.forEach((e) => {
						e.money = RESTRICT.float(e.money, 2);
						e.game = RESTRICT.float(e.game, 2);
					});
					this.allAssets = data;
					this.currentCurrency();
				}
			})
			.catch((err) => console.warn(err, "userStore.Assets"))
			.finally(() => {
				if (val) this._loop = setTimeout(() => this.updateAssets(true), 15000);
			});
	}

	//todo 获取当前币种资产信息
	currentCurrency(cur = "CNY") {
		this.assets = this.allAssets.filter((o) => o.currency === cur)[0];
	}

	//todo 获取自选
	getFavor() {
		if (!this.isLogin) return;
		EVENT.Account.getFavor().then(({ data }) => {
			runInAction(() => (this.favor = data || []));
		});
	}

	//todo 是否是自选
	isFavor(id) {
		let b;
		this.favor.findIndex((o) => o === id) === -1 ? (b = false) : (b = true);
		return b;
	}

	//todo 设置自选
	setFavor(id) {
		let del = false;
		if (this.isFavor(id)) del = true;
		return new Promise((resolve) => {
			EVENT.Account.setFavor(id, del).then(() => this.getFavor());
			del ? resolve("移除成功") : resolve("添加成功");
		});
	}

	//todo 获取代理ID
	getRu(ru) {
		if (ru) this.ru = ru;
	}

	//todo 获取用户来源
	getFrom(f) {
		if (f) this.f = f;
	}

	//todo 显示或隐藏资产信息
	switchEyes(status) {
		this.eye = status;
	}

	//todo 获取用户止盈止损默认状态
	updateOrderDefault() {
		EVENT.Account.getOrderDefault().then(({ data }) => {
			if (data.tp === 0 && data.sl === 0) {
				data.tp = 5;
				data.sl = 0.9;
			}
			Store.Futures.getOrderSet(data);
		});
	}

	//todo 登出
	setLogout(state) {
		!!state && (this.isLogin = state);
		if (!this.isLogin) return;
		this.isLogin = false;
		Storage.removeItem("isLogin");
		Storage.removeItem("mobile");
		Storage.removeItem("email");
		Storage.removeItem("_wallet_");
		Store.Position.end();
		Store.Position.endLimit();
		Store.Position.endSpot();
		clearTimeout(this._loop);
		clearTimeout(this._detailLoop);
		this._loop = null;
		this._detailLoop = null;
		this.accountInfo = {
			username: "",
			unread: 0,
		};
		this.assets = {
			money: 0,
			eagle: 0,
			game: 0,
			prize: 0,
		};
		return new Promise((resolve) => {
			const result = EVENT.Account.logout();
			resolve(result);
		});
	}
}
