import service from "../network/axios";
import Store from "@/store";

/**
 * 获取充值记录
 * @returns
 */
const getDepositHistory = async () => {
	const result = await service.get("/api/pay/rechargeHistory.htm");
	return result;
};

/**
 * 获取交易记录
 * @param {*} beginId 最后一条记录的id用于做上啦刷新
 */
const getRecordHistory = (type, isAssetType = false, size = 9999, beginId, page = 1) => {
	const data = {
		action: "more",
		// assetType: type,
		size,
	};
	if (isAssetType) {
		data.assetType = type;
	} else {
		data.type = type;
	}

	if (!!beginId) {
		if (beginId === "pc") {
			data.action = "pc";
			data.page = page;
		} else {
			data.beginId = beginId;
		}
	}
	return service.get("/api/mine/funds.htm", { params: data });
};

/**
 * 获取提款记录(旧版 FK)
 */
const getWithdrawHistory = async () => {
	const result = await service.get("/api/pay/withdrawHistory.htm");
	return result;
};

/**
 * 获取提款记录
 */
const newWithdrawHistory = async () => {};

/**
 * 结算
 * @param {number} schemeSort 不知道是啥 反正就是2就对了
 * @param {number} tradeType 交易类型 1.实盘 2.模拟
 */
const settleHistory = () => {
	const o = {
		schemeSort: 2,
		tradeType: Store.Quote.simulate ? 2 : 1,
		_: new Date().getTime(),
	};
	return service.get("/api/trade/scheme/history", { params: o });
};

/**
 * 流单
 * @param {number} tradeType 交易类型 1.实盘 2.模拟
 */
const flowOrder = () => {
	const o = {
		tradeType: Store.Quote.simulate ? 2 : 1,
		_: new Date().getTime(),
	};
	return service.get("/api/trade/scheme/abortion", { params: o });
};

/**
 * 登录历史
 * @returns
 */
const getLoginHistory = async (page = 1, rows = 10) => {
	const o = {
		page,
		rows,
	};
	const result = await service.get("/api/user/login-history", { params: o });
	return result;
};

/**
 * 提币
 * @returns
 */
const withdrawHistory = async () => {
	const result = await service.get("/api/mine/funds/withdraw");
	return result;
};

/**
 * 充提记录 deposit-withdraw
 * @returns
 */
const depositWithdraw = async (o) => {
	const result = await service.get("/api/mine/funds/deposit-withdraw", { params: o });
	return result;
};

/**
 * 充币记录
 * @returns
 */
const depositHistory = async () => {
	const result = await service.get("/api/mine/funds/deposit");
	return result;
};

/**
 * 首页缩略走势图
 * @param {*} symbols
 * @param {*} resolution
 * @param {*} from
 * @param {*} to
 * @returns
 */
const tradingView = async (symbols, resolution, from, to) => {
	const o = {
		symbols,
		resolution,
		from,
		to,
	};
	const result = await service.get("/api/tv/tradingView/history", { params: o });
	return result;
};

/**
 * 现货 委托历史
 */
const spotPendingHistory = async (createTimeGe, createTimeLe, buyType = "", coin = "", rows = 999) => {
	const o = {
		createTimeGe,
		createTimeLe,
		rows,
	};
	if (buyType !== "") {
		if (buyType) {
			o.srcCurrency = "USDT";
			o.desCurrency = coin;
		} else {
			o.srcCurrency = coin;
			o.desCurrency = "USDT";
		}
	}

	const result = await service.get("/api/order/history", { params: o });
	return result;
};

/**
 * 现货 历史成交
 */
const spotRecord = async (createTimeGe, createTimeLe, buyType = "", coin = "", rows = 999) => {
	const o = { createTimeGe, createTimeLe, rows };
	if (buyType !== "") {
		if (buyType) {
			o.srcCurrency = "USDT";
			o.desCurrency = coin;
		} else {
			o.srcCurrency = coin;
			o.desCurrency = "USDT";
		}
	}

	const result = await service.get("/api/order/detail-list", { params: o });
	return result;
};

export default {
	getDepositHistory,
	getRecordHistory,
	getWithdrawHistory,
	settleHistory,
	flowOrder,
	getLoginHistory,
	withdrawHistory,
	depositWithdraw,
	depositHistory,
	tradingView,
	spotPendingHistory,
	spotRecord,
};
