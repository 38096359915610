import React, { useEffect, useState, useMemo, useRef } from "react";
import { message, notification } from "antd";
// utils
import { arrayToStr } from "@/utils";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import Layout from "./modal";
import SimpleInput from "@/view/components/SimpleInput";
import ImgValid from "@/view/components/ImgValid";
import { imgValidModal } from "@/view/components/Modal";
// style
import styles from "./index.module.scss";

export default function ForgotWithdrawPwd({ close, accountInfo }) {
	const { account, phone, email } = accountInfo;
	const [editType] = useState("CHANGE_WITHDRAW");
	const [principal, setPrincipal] = useState(account === phone);
	const [newPass, setNewPass] = useState("");
	const [cfmPass, setCfmPass] = useState("");
	const [msgCode, setMsgCode] = useState("");
	const [formatVerify, setFormatVerify] = useState({ new: false, confirm: false, code: false });
	const [countText, setCountText] = useState("获取验证码");
	const [countTime, setCountTime] = useState(null);
	const [isSend, setIsSend] = useState(false);
	const timer = useRef();

	useEffect(() => {
		return () => {
			clearTimeout();
		};
	}, []);
	useEffect(() => {
		if (countTime === null) {
			return;
		}
		if (countTime === 0) {
			message.warning("输入超时，请重新发送验证码!");
			setCountText("获取验证码");
			setIsSend(false);
			setCountTime(null);
			clearTimeout(timer.current);
		} else {
			timer.current = setTimeout(() => {
				setCountTime((time) => time - 1);
				setCountText(`${countTime}秒后重发`);
				setIsSend(true);
			}, 1000);
		}
	}, [countTime]);
	// 兩種驗證都有
	const haveBotAuth = useMemo(() => Boolean(email) && Boolean(phone), [email, phone]);
	// 輸入欄位驗證
	const isValid = useMemo(() => {
		return Object.values(formatVerify).every((value) => !!value);
	}, [formatVerify]);
	// 顯示圖片驗證碼
	const showCode = () => {
		if (isSend) return;
		imgValidModal(
			<ImgValid
				account={principal ? phone : email}
				sendCodeStatus={() => setCountTime(90)}
				type={editType}
				isMask={false}
			/>
		);
	};
	// 設置|修改提款密碼
	const submit = () => {
		if (newPass !== cfmPass) {
			return message.warning("两次输入密码不一致");
		}
		if (!isValid) {
			return;
		}
		EVENT.Account[account.indexOf("@") === -1 ? "verifySMS" : "verifyEmail"](account, msgCode, editType)
			.then(() => changeWithdrawPwd())
			.catch(({ message }) => notification.error({ message }));
	};

	const changeWithdrawPwd = () => {
		EVENT.Account.retrieveFundsPass({
			account,
			newPassword: newPass,
		})
			.then(() => {
				Store.User.updateDetail();
				notification.success({
					message: "资金密码修改成功",
				});
				close();
			})
			.catch(({ message }) => notification.error({ message }));
	};

	return (
		<Layout close={close} title="忘记提款密码">
			<SimpleInput
				label={"资金密码"}
				placeholder="请输入资金密码"
				type={"password"}
				format={"PASSWORD"}
				notice={"notice"}
				simple={true}
				force={true}
				value={newPass}
				onChange={(password, format) => {
					if (format !== formatVerify.new) {
						setFormatVerify((props) => ({ ...props, new: format }));
					}
					setNewPass(password);
				}}
			/>
			<SimpleInput
				label={"确认资金密码"}
				placeholder="请再次输入资金密码"
				type={"password"}
				format={"PASSWORD"}
				notice={"notice"}
				simple={true}
				force={true}
				value={cfmPass}
				onChange={(password, format) => {
					if (format !== formatVerify.confirm) {
						setFormatVerify((props) => ({ ...props, confirm: format }));
					}
					setCfmPass(password);
				}}
			/>
			<div className={styles.sendCode}>
				<SimpleInput
					customClass={styles.simpleInput}
					label={principal ? "短信验证码" : "邮箱验证码"}
					placeholder={principal ? "请输入手机验证码" : "请输入邮箱验证码"}
					type={"verifyCode"}
					format={"GLOBAL_CAPTCHA"}
					notice={"msgCode"}
					value={msgCode}
					onChange={(code, format) => {
						if (format !== formatVerify.code) {
							setFormatVerify((props) => ({ ...props, code: format }));
						}
						setMsgCode(code);
					}}
				/>
				<div className={styles.getCode} onClick={showCode}>
					{countText}
				</div>
			</div>
			{haveBotAuth && (
				<div className={styles.switchVerify}>
					<div onClick={() => setPrincipal((state) => !state)}>{principal ? "使用邮箱验证" : "使用手机验证"}</div>
				</div>
			)}
			<div className={arrayToStr(["confirm_btn", styles.confirmBtn, !isValid ? "error" : ""])} onClick={submit}>
				确认
			</div>
		</Layout>
	);
}
