import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// utils
import { arrayToStr, idMask, mobileMask, emailMask, isEmpty } from "@/utils";
import { color, getBankIcon } from "@/utils/common";
import { TEST } from "@/utils/test";
// conponents
import { customModal } from "@/view/components/Modal";
import { BankIcon } from "@/view/components/Svg";
import AddBankCard from "./addBankCard";
import EditBankCard from "./editBankCard";

// assets
import position from "@assets/common/position_light.png";
// style
import styles from "./index.module.scss";

const BankCard = () => {
  const [bankList, setBankList] = useState([]);
  useEffect(() => {
    updateBankList();
  }, []);

  const updateBankList = () => {
    EVENT.Account.getAllBankCard()
      .then((res) => {
        // 不展示支付宝
        let cards = res.bankCards.filter((x) => x.bank !== "支付宝");
        setBankList(cards);
      })
      .catch(({ message }) => notification.error({ message }));
  };
  const updateDefaultBankCard = (id) => {
    EVENT.Account.setDefaultBankCard(id)
      .then(() => {
        notification.success({ message: "默认银行卡设置成功" });
        updateBankList();
      })
      .catch(({ message }) => notification.error({ message }));
  };
  const deleteBankCard = (id) => {
    EVENT.Account.deleteBankCard(id)
      .then(() => {
        notification.success({
          message: "删除成功",
          onClose: () => Store.User.updateDetail(),
        });
        updateBankList();
      })
      .catch(({ message }) => notification.error({ message }));
  };

  // 支付宝号码隐藏
  const getContentMask = (value) => {
    if (TEST.PHONE.test(value)) return mobileMask(value);
    return emailMask(value);
  };

  return (
    <div className={styles.bankcard}>
      <div className={styles.bankcardTitle}>
        <h5>银行卡管理</h5>
        <div
          onClick={() => {
            const { identityNumberValid } = Store.User.accountInfo;
            if (!identityNumberValid) {
              return notification.error("请先实名认证");
            }
            customModal(
              <AddBankCard accountInfo={Store.User.accountInfo} />,
              false,
              updateBankList
            );
          }}
        >
          添加银行卡
        </div>
      </div>
      <div className={styles.bankMain}>
        <div className={styles.switchBtn}>
          <div>我的银行卡</div>
        </div>
        <div className={styles.bankcardList}>
          {!isEmpty(bankList) ? (
            bankList.map((item, key) => (
              <div
                key={key}
                className={arrayToStr([
                  styles.bankBox,
                  styles[color(item.bank)],
                ])}
              >
                <div className={styles.imgBox}>
                  <div className={styles.img}>
                    <BankIcon img={getBankIcon(item.bank)} />
                  </div>
                </div>
                <div className={styles.info}>
                  <div className={styles.bankName}>
                    <b>{item.bank}</b>
                    <p>{item.bank !== "支付宝" ? "储蓄卡" : ""}</p>
                  </div>
                  <div className={styles.bankcard}>
                    {item.bank !== "支付宝"
                      ? idMask(item.cardNumber.substr(-16, 16))
                      : getContentMask(item.cardNumber)}
                  </div>
                  <div className={styles.btnBox}>
                    {item.bank !== "支付宝" ? (
                      <div
                        className={styles.btn}
                        onClick={() => {
                          customModal(
                            <EditBankCard
                              accountInfo={Store.User.accountInfo}
                              editInfo={item}
                            />,
                            true,
                            updateBankList
                          );
                        }}
                      >
                        编辑
                      </div>
                    ) : (
                      <div />
                    )}
                    <div className={styles.right}>
                      {item.def === 0 && (
                        <div
                          className={styles.btn}
                          onClick={() => updateDefaultBankCard(item.id)}
                        >
                          设为默认
                        </div>
                      )}
                      <div
                        className={styles.btn}
                        onClick={() => deleteBankCard(item.id)}
                      >
                        删除
                      </div>
                    </div>
                  </div>
                </div>
                {item.def === 1 && <div className={styles.default} />}
              </div>
            ))
          ) : (
            <div className={styles.empty}>
              <div>
                <img src={position} alt="" />
                <b>暂无银行卡</b>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(BankCard);
