import Cache from "./cache";

export default class Item extends Cache {
  //商品信息是否更新中
  #contractUpdating = false;
  //交易信息是否更新中
  #tradeUpdating = false;
  //是否完成信息加载
  #usable = false;
  //是否完成手续费加载
  #tradeAble = false;
  //是否数字货币
  #DC = false;
  //是否期货
  #future = false;
  //是否现货
  #spot = false;
  //别名
  #alias = "";
  //商品分类
  #zone = "";
  #map = null;

  constructor(id, name, map) {
    super(id);
    this.#map = map;
    this.#alias = name;
    this.cache.then((hasCache) => {
      if (hasCache) {
        this.#analysis();
      }
    });
  }

  #analysis() {
    if (this.id.indexOf(this.config.currency) !== -1) {
    } else if (this.id.indexOf("_CC") !== -1) {
    }
    this.#usable = true;
  }

  shouldUpdateInit() {
    return !this._hasCache;
  }

  //todo 计时更新商品缓存
  shouldUpdate() {
    return new Promise((resolve) =>
      this.cache.then((hasCache) => {
        if (hasCache) {
          resolve(
            Date.now().sub(this.config.lastUpdateTimeForContract) > 300000 &&
              !this.#contractUpdating
          );
        } else {
          resolve(true);
        }
      })
    );
  }

  shouldUpdateTrade() {
    return (
      Date.now().sub(this.lastUpdateTimeForTrade) > 300000 &&
      !this.#tradeUpdating
    );
  }

  get zone() {
    return this.#zone;
  }

  set zone(zone) {
    if (this.#zone !== "") return;
    this.#zone = zone;
  }

  set usable(value) {
    if (value !== true) return;
    this.#usable = true;
  }

  get usable() {
    return this.#usable;
  }

  set tradeAble(value) {
    if (value !== true) return;
    this.#tradeAble = true;
  }

  get tradeAble() {
    return this.#tradeAble;
  }

  set contractUpdating(value) {
    this.#contractUpdating = value;
  }

  get contractUpdating() {
    return this.#contractUpdating;
  }

  get tradeUpdating() {
    return this.#tradeUpdating;
  }

  set tradeUpdating(value) {
    this.#tradeUpdating = value;
  }

  set charge(value) {
    super.charge = value;
    this.#tradeUpdating = false;
  }
  get charge() {
    return super.charge;
  }
  set config(value) {
    super.config = value;
    this.#contractUpdating = false;
  }
  get config() {
    return super.config;
  }
}
