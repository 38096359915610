import React from "react";
import { notification, message } from "antd";
// store
import Store from "@/store";
import EVENT from "@/library/api";
//utils
import { isEmpty, arrayToStr, formatDate } from "@/utils";
// components
import Empty from "./empty";
import Copy from "@/view/components/Copy";
// style
import styles from "../index.module.scss";

const DerivList = ({ list, cancel }) => {
	return (
		<>
			<div className={styles.header}>
				<i />
				<div className={styles.pendingLong}>商品名称</div>
				<div>模式</div>
				<div>手数</div>
				<div>挂单价</div>
				<div>保证金</div>
				<div>止盈</div>
				<div>止损</div>
				<div className={styles.pendingLong}>挂单时间</div>
				<div className={arrayToStr([styles.pendingLong, styles.id])}>订单号</div>
				<div className={arrayToStr([styles.pendingLonger, styles.cancel])}>操作</div>
			</div>
			<div className={styles.listBox}>
				{!isEmpty(list) ? (
					list.map((item) => {
						return (
							<div key={item.id} className={styles.item}>
								<i className={item.isBuy ? styles.redB : styles.greenB} />
								<div className={styles.pendingLong}>
									{item.commodity}({item.contCode})
								</div>
								<div>{item.moneyType === 0 ? "标准" : "迷你"}</div>
								<div>{item.volume}</div>
								<div>{item.price}</div>
								<div>{item.margin}</div>
								<div className={"red"}>{Math.round(item.stopProfit)}</div>
								<div className={"green"}>{Math.round(item.stopLoss)}</div>
								<div className={styles.pendingLong}>{formatDate("m-d h:i:s", { date: item.tradeTime })}</div>
								<div className={arrayToStr([styles.pendingLong, styles.id])}>
									<p>{item.id}</p>
									<Copy text={item.id} />
								</div>
								<div className={styles.pendingLonger}>
									<div onClick={() => cancel(item.id)}>撤销</div>
								</div>
							</div>
						);
					})
				) : (
					<Empty message="暂无委托" />
				)}
			</div>
		</>
	);
};
const OtherList = ({ list, cancel }) => {
	return (
		<>
			<div className={styles.header}>
				<i />
				<div className={styles.pendingLong}>商品名称</div>
				<div>杠杆</div>
				<div>限价金额</div>
				<div>保证金</div>
				<div>止盈</div>
				<div>止损</div>
				<div>过夜</div>
				<div className={styles.pendingLong}>挂单时间</div>
				<div className={arrayToStr([styles.pendingLong, styles.id])}>订单号</div>
				<div className={arrayToStr([styles.pendingLonger, styles.cancel])}>操作</div>
			</div>
			<div className={styles.listBox}>
				{!isEmpty(list) ? (
					list.map((item) => {
						return (
							<div key={item.id} className={styles.item}>
								<i className={item.isBuy ? styles.redB : styles.greenB} />
								<div className={styles.pendingLong}>
									{item.commodity}({item.commodityCode})
								</div>
								<div>{`${item.lever}X`}</div>
								<div>{item.price}</div>
								<div>{item.margin}</div>
								<div className={"red"}>{Math.round(item.stopProfit)}</div>
								<div className={"green"}>{Math.round(item.stopLoss)}</div>
								{!Store.Quote.simulate && <div>{item.defer ? "是" : "否"}</div>}
								<div className={styles.pendingLong}>{formatDate("m-d h:i:s", { date: item.tradeTime })}</div>
								<div className={arrayToStr([styles.pendingLong, styles.id])}>
									<p>{item.id}</p>
									<Copy text={item.id} />
								</div>
								<div className={styles.pendingLonger}>
									<div onClick={() => cancel(item.id)}>撤销</div>
								</div>
							</div>
						);
					})
				) : (
					<Empty message="暂无委托" />
				)}
			</div>
		</>
	);
};

const PendingList = ({ tradeType, list }) => {
	const cancel = (id) => {
		EVENT.Trade.cancelOrder(id)
			.then(() => {
				Store.Position.startLimit(false);
				Store.User.updateAssets();
				message.success("撤单请求已处理");
			})
			.catch((message) => notification.error({ message }));
	};
	return (
		<div className={styles.positionList}>
			{tradeType === "futures" ? <DerivList list={list} cancel={cancel} /> : <OtherList list={list} cancel={cancel} />}
		</div>
	);
};

export default PendingList;
