import Account from "./account";
import News from "./news";
import History from "./history";
import Assets from "./assets";
import Payment from "./payment";
import Trade from "./trade";
import Game from "./game";
import Spot from "./spot";

const EVENT = {
  Account,
  News,
  History,
  Assets,
  Payment,
  Trade,
  Game,
  Spot,
};

export default EVENT;
