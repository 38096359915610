import React from "react";
import { notification } from "antd";

// store
import Store from "@/store";
//utils
import { isEmpty, arrayToStr, formatDate } from "@/utils";
// lib
import { RESTRICT } from "@/library/standard";
// components
import Empty from "./empty";
import Copy from "@/view/components/Copy";
import { customModal } from "@/view/components/Modal";
import { SPSL, DerivSPSL } from "./spsl";
// assets
import { ReactComponent as Close } from "@assets/common/x.svg";
// style
import styles from "../index.module.scss";

const SellConfirmModal = ({ close, sell }) => (
	<div className={styles.sellModal}>
		<div className={styles.close} onClick={close}>
			<Close />
		</div>
		<span className={styles.confirmInfo}>确定是否平仓?</span>
		<div className={styles.btnBox}>
			<div onClick={close}>取消</div>
			<div
				className={styles.confirm}
				onClick={() => {
					sell();
					close();
				}}
			>
				确定
			</div>
		</div>
	</div>
);

const DerivList = ({ list, setting, sell }) => {
	return (
		<>
			<div className={styles.header}>
				<i />
				<div className={styles.positionLong}>商品名称</div>
				<div>模式</div>
				<div>手数</div>
				<div>保证金</div>
				<div>开仓价</div>
				<div>止盈</div>
				<div>止损</div>
				<div>盈亏(元)</div>
				<div className={styles.positionShort}>开仓时间</div>
				<div className={arrayToStr([styles.positionLong, styles.id])}>订单号</div>
				<div className={styles.positionLonger}>操作</div>
			</div>
			<div className={styles.listBox}>
				{!isEmpty(list) ? (
					list.map((item) => {
						return (
							<div key={item.id} className={styles.item}>
								<i className={item.isBuy ? styles.redB : styles.greenB} />
								<div className={styles.positionLong}>
									{item.commodity}({item.contCode})
								</div>
								<div>{item.moneyType === 0 ? "标准" : "迷你"}</div>
								<div>{item.volume}</div>
								<div>{item.margin}</div>
								<div>{item.opPrice}</div>
								<div className={"red"}>{RESTRICT.float(item.stopProfit, 2)}</div>
								<div className={"green"}>{RESTRICT.float(item.stopLoss, 2)}</div>
								<div className={item.income > 0 ? "red" : "green"}>{RESTRICT.float(item.income.toFixed(2), 2)}</div>
								<div className={styles.positionShort}>{formatDate("m-d h:i:s", { date: item.tradeTime })}</div>
								<div className={arrayToStr([styles.positionLong, styles.id])}>
									<p>{item.id}</p>
									<Copy text={item.id} />
								</div>
								<div className={arrayToStr([styles.positionLonger, styles.btnBox])}>
									<div onClick={() => setting(item.id)}>设置</div>
									<div
										className={item.tradeStatus === 12 ? styles.alreadySell : ""}
										onClick={() => {
											if (item.tradeStatus === 12) return;
											sell(item.id);
										}}
									>
										平仓
									</div>
								</div>
							</div>
						);
					})
				) : (
					<Empty message="暂无持仓" />
				)}
			</div>
		</>
	);
};
const OtherList = ({ list, setting, sell }) => {
	return (
		<>
			<div className={styles.header}>
				<i />
				<div className={styles.positionLong}>商品名称</div>
				<div>杠杆</div>
				<div>保证金</div>
				<div>开仓价</div>
				<div>止盈</div>
				<div>止损</div>
				<div>盈亏(元)</div>
				{!Store.Quote.simulate && <div>过夜天数</div>}
				<div className={styles.positionShort}>开仓时间</div>
				<div className={arrayToStr([styles.positionLong, styles.id])}>订单号</div>
				<div className={styles.positionLonger}>操作</div>
			</div>
			<div className={styles.listBox}>
				{!isEmpty(list) ? (
					list.map((item) => {
						return (
							<div key={item.id} className={styles.item}>
								<i className={item.isBuy ? styles.redB : styles.greenB} />
								<div className={styles.positionLong}>
									{item.commodity}({item.commodityCode})
								</div>
								<div>{`${item.lever}X`}</div>
								<div>{item.margin}</div>
								<div>{item.opPrice}</div>
								<div className={"red"}>{RESTRICT.float(item.stopProfit, 2)}</div>
								<div className={"green"}>{RESTRICT.float(item.stopLoss, 2)}</div>
								<div className={item.income > 0 ? "red" : "green"}>{RESTRICT.float(item.income, 2)}</div>
								{!Store.Quote.simulate && <div>{item.defer ? item.deferDays : "--"}</div>}
								<div className={styles.positionShort}>{formatDate("m-d h:i:s", { date: item.tradeTime })}</div>
								<div className={arrayToStr([styles.positionLong, styles.id])}>
									<p>{item.id}</p>
									<Copy text={item.id} />
								</div>
								<div className={arrayToStr([styles.positionLonger, styles.btnBox])}>
									<div onClick={() => setting(item.id)}>设置</div>
									<div
										className={item.tradeStatus === 12 ? styles.alreadySell : ""}
										onClick={() => {
											if (item.tradeStatus === 12) return;
											sell(item.id);
										}}
									>
										平仓
									</div>
								</div>
							</div>
						);
					})
				) : (
					<Empty message="暂无持仓" />
				)}
			</div>
		</>
	);
};

const PositionList = ({ tradeType, list }) => {
	// 設置
	const setting = (id) => {
		Store.FuturesSPSL.getSPSLById(id);
		customModal(tradeType === "futures" ? <DerivSPSL id={id} /> : <SPSL id={id} />, false);
	};
	// 平倉確認
	const sellConfirm = (id) => {
		if (Store.Futures.orderSetting.confirmClose) {
			customModal(<SellConfirmModal sell={() => sell(id)} />);
		} else {
			sell(id);
		}
	};
	// 平倉
	const sell = (id) => {
		Store.Position.closePosition(id)
			.then(() => {
				notification.success({ message: `订单平仓成功` });
				Store.Position.start(false);
				Store.User.updateAssets();
			})
			.catch(({ message }) => notification.error({ message }));
	};
	return (
		<div className={styles.positionList}>
			{tradeType === "futures" ? (
				<DerivList list={list} setting={setting} sell={sellConfirm} />
			) : (
				<OtherList list={list} setting={setting} sell={sellConfirm} />
			)}
		</div>
	);
};

export default PositionList;
