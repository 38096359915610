import React, { useRef, useEffect, useState } from "react";
// library
import EVENT from "@/library/api";
// utils
import { formatDate } from "@/utils";
// store
import Store from "@/store";
// components
import { CommonIcon } from "@/view/components/Svg";
// assets
import CsIcon from "@assets/common/cs.png";
// style
import styles from "./index.module.scss";

const MessageBox = ({ close }) => {
	const [message, setMessage] = useState([]);
	const timer = useRef();

	useEffect(() => {
		getMessage();
		return () => {
			clearTimeout(timer.current);
			timer.current = null;
		};
	}, []);
	const getMessage = () => {
		EVENT.News.getMessage()
			.then(({ data }) => {
				let ary = data.reverse();
				setMessage(ary);
				Store.User.updateDetail();
			})
			.finally(() => (timer.current = setTimeout(() => getMessage(), 5000)));
	};

	return (
		<div className={styles.messageBox}>
			<div className={styles.head}>
				<b>站内信</b>
				<CommonIcon
					img="x"
					onClick={() => {
						clearTimeout(timer.current);
						close();
					}}
				/>
			</div>
			<ul className={styles.list}>
				{message
					.filter((item) => [3, 4].includes(item.status))
					.map((item, key) => (
						<li key={key} className={styles.item}>
							<div className={styles.info}>
								<img src={CsIcon} alt="" />
								<p>{item.content}</p>
							</div>
							<div className={styles.time}>{formatDate("y-m-d h:i", { date: item.time })}</div>
						</li>
					))}
			</ul>
		</div>
	);
};

export default MessageBox;
