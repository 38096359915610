import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
// store
import Store from "@/store";
// lib
import EVENT from "@/library/api";
// components
import { UserIcon } from "@/view/components/Svg";
import Crypto from "./crypto";
import Fiat from "./fiat";

import { isEmpty } from "@/utils";

// style
import styles from "./index.module.scss";

const Recharge = () => {
  const location = useLocation();
  const { money } = Store.User.assets;
  const [type, setType] = useState(location.state || 0);
  const [cryptoList, setCryptoList] = useState([]);
  const [fiatList, setFiatList] = useState([]);
  useEffect(() => {
    Store.User.updateAssets();
    updateRechargeRecord({});
    updateRechargeHistory({});
  }, []);

  const updateRechargeRecord = ({
    page = 1,
    rows = 10,
    startDate,
    endDate,
    currency,
    currencyType = 0,
    transfer = false,
    type = 100,
  }) => {
    EVENT.History.depositWithdraw({
      page,
      rows,
      startDate,
      endDate,
      currency,
      currencyType,
      transfer,
      type,
    }).then(({ data }) => {
      if (data.length > 0) {
        setCryptoList(data.filter((item) => !!item.address));
      }
    });
  };

  const updateRechargeHistory = ({ page = 1, startDate, endDate }) => {
    EVENT.History.depositWithdraw({
      page,
      rows: 10,
      createTimeGe: startDate,
      createTimeLe: endDate,
    }).then(({ data }) => {
      let o = [];
      !isEmpty(data) &&
        data.forEach((item) => {
          if (
            (item.address.length === 0 && item.status === 1) ||
            (item.address.length === 0 && item.status === 0)
          ) {
            o.push(item);
          }
        });
      setFiatList(o.slice(0, 5));
      console.log(data);
    });
  };

  return (
    <div className={styles.recharge}>
      <div className={styles.rechargeTitle}>
        <h5>充值</h5>
      </div>
      <div className={styles.rechargeMain}>
        <div className={styles.switchBtn}>
          <div
            onClick={() => setType(0)}
            className={type === 0 ? styles.active : ""}
          >
            <UserIcon img="rechargeshuzi" />
          </div>
          <div
            onClick={() => setType(1)}
            className={type === 1 ? styles.active : ""}
          >
            法币
          </div>
        </div>
        {type === 0 ? (
          <Crypto
            list={cryptoList}
            balance={money}
            update={() => updateRechargeRecord({})}
          />
        ) : (
          <Fiat
            list={fiatList}
            balance={money}
            update={() => updateRechargeRecord({})}
          />
        )}
      </div>
    </div>
  );
};

export default observer(Recharge);
