import { User } from "./userStore";
import { News } from "./newsStore";
import { DataStore } from "./dataStore";
import { FuturesStore } from "./futuresTrade";
import { FuturesSPSL } from "./futuresSPSL";
import { QuoteStore } from "./quoteStore";
import { BriefStore } from "./briefStore";
import { PositionStore } from "./positionStore";
import { Game } from "./gameStore";
import { SpotStore } from "./spotStore";
import { WalletStore } from "./walletStore";

const Store = {
  User: new User(),
  News: new News(),
  Data: new DataStore(),
  Brief: new BriefStore(),
  Quote: new QuoteStore(),
  Futures: new FuturesStore(),
  FuturesSPSL: new FuturesSPSL(),
  Position: new PositionStore(),
  Game: new Game(),
  Spot: new SpotStore(),
  Wallet: new WalletStore(),
};

export default Store;
