import React, { useEffect, useState, useMemo, useRef } from "react";
import { message, notification } from "antd";
// utils
import { emailMask, arrayToStr } from "@/utils";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import Layout from "./modal";
import SimpleInput from "@/view/components/SimpleInput";
import ImgValid from "@/view/components/ImgValid";
import { imgValidModal } from "@/view/components/Modal";

// style
import styles from "./index.module.scss";

const OneStep = ({ oldEmail, showCode, countText, changeStep, type }) => {
	const [codeInfo, setCodeInfo] = useState({ code: "", format: false });
	const next = () => {
		if (!codeInfo.format) {
			return;
		}
		EVENT.Account.verifyEmail(oldEmail, codeInfo.code, type)
			.then(() => changeStep())
			.catch(({ message }) => notification.error({ message }));
	};

	return (
		<>
			<SimpleInput label="电子邮箱" value={emailMask(oldEmail)} disable={true} />
			<div className={styles.sendCode}>
				<SimpleInput
					customClass={styles.simpleInput}
					label="验证码"
					placeholder="请输入验证码"
					type={"verifyCode"}
					format={"GLOBAL_CAPTCHA"}
					notice={"msgCode"}
					value={codeInfo.code}
					onChange={(code, format) => {
						setCodeInfo({ code, format });
					}}
				/>
				<div className={styles.getCode} onClick={showCode}>
					{countText}
				</div>
			</div>
			<div className={arrayToStr(["confirm_btn", styles.confirmBtn, !codeInfo.format ? "error" : ""])} onClick={next}>
				下一步
			</div>
		</>
	);
};
const TwoStep = ({ oldEmail, showCode, countText, getNewEmail, type, close }) => {
	const [account, setAccount] = useState("");
	const [msgCode, setMsgCode] = useState("");
	const [formatVerify, setFormatVerify] = useState({ email: false, code: false });
	// 輸入欄位驗證
	const isValid = useMemo(() => {
		return Object.values(formatVerify).every((value) => !!value);
	}, [formatVerify]);

	const submit = () => {
		if (!isValid) {
			return;
		}
		EVENT.Account.verifyEmail(account, msgCode, type)
			.then(() => changeEmail())
			.catch(({ message }) => notification.error({ message }));
	};
	const changeEmail = () => {
		EVENT.Account.updateEmail(oldEmail, account)
			.then(() => {
				Store.User.updateDetail();
				notification.success({
					message: "邮箱修改成功！",
				});
				close();
			})
			.catch(({ message }) => notification.error({ message }));
	};
	return (
		<>
			<SimpleInput
				label="新邮箱地址"
				placeholder="请输入新邮箱地址"
				type={"email"}
				format={"EMAIL"}
				notice={"email"}
				value={account}
				onChange={(phone, format) => {
					if (format !== formatVerify.email) {
						setFormatVerify((props) => ({ ...props, email: format }));
					}
					setAccount(phone);
					getNewEmail(phone);
				}}
			/>
			<div className={styles.sendCode}>
				<SimpleInput
					customClass={styles.simpleInput}
					label="验证码"
					placeholder="请输入验证码"
					type={"verifyCode"}
					format={"GLOBAL_CAPTCHA"}
					notice={"msgCode"}
					value={msgCode}
					onChange={(code, format) => {
						if (format !== formatVerify.code) {
							setFormatVerify((props) => ({ ...props, code: format }));
						}
						setMsgCode(code);
					}}
				/>
				<div
					className={styles.getCode}
					onClick={() => {
						if (!formatVerify.email) {
							return message.warning("邮箱格式错误");
						}
						showCode();
					}}
				>
					{countText}
				</div>
			</div>
			<div className={arrayToStr(["confirm_btn", styles.confirmBtn, !isValid ? "error" : ""])} onClick={submit}>
				确认
			</div>
		</>
	);
};

export default function ChangeEmail({ close, accountInfo }) {
	const { email } = accountInfo;
	const [next, setNext] = useState(false);
	const [account, setAccount] = useState(email);
	const [countText, setCountText] = useState("获取验证码");
	const [countTime, setCountTime] = useState(null);
	const [isSend, setIsSend] = useState(false);
	const timer = useRef();

	useEffect(() => {
		return () => {
			clearTimeout();
		};
	}, [account]);
	useEffect(() => {
		if (countTime === null) {
			return;
		}
		if (countTime === 0) {
			message.warning("输入超时，请重新发送验证码!");
			resetTimer();
		} else {
			timer.current = setTimeout(() => {
				setCountTime((time) => time - 1);
				setCountText(`${countTime}秒后重发`);
				setIsSend(true);
			}, 1000);
		}
	}, [countTime]);

	const editType = useMemo(() => (next ? "BIND_EMAIL" : "CHANGE_EMAIL"), [next]);

	const showCode = () => {
		if (isSend) return;
		imgValidModal(
			<ImgValid
				account={account}
				countText={countText}
				sendCodeStatus={() => setCountTime(90)}
				type={editType}
				isMask={false}
			/>
		);
	};

	const resetTimer = () => {
		setCountText("获取验证码");
		setIsSend(false);
		setCountTime(null);
		clearTimeout(timer.current);
	};

	const gotoNext = () => {
		resetTimer();
		setNext(true);
	};

	const getNewEmail = (email) => setAccount(email);

	return (
		<Layout close={close} title="修改邮箱">
			{!next ? (
				<OneStep oldEmail={email} showCode={showCode} countText={countText} type={editType} changeStep={gotoNext} />
			) : (
				<TwoStep
					oldEmail={email}
					showCode={showCode}
					countText={countText}
					type={editType}
					getNewEmail={getNewEmail}
					close={close}
				/>
			)}
		</Layout>
	);
}
