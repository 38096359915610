import { create, all } from "mathjs/number";
const math = create(all);

Number.prototype.add = function (arg) {
  if (isNaN(arg)) {
    console.warn("%s 不是一个合法的数字", arg);
    arg = 0;
  }
  return math.add(this, arg);
};

Number.prototype.sub = function (arg) {
  if (isNaN(arg)) {
    console.warn("%s 不是一个合法的数字", arg);
    arg = 0;
  }
  return math.subtract(this, arg);
};

Number.prototype.mul = function (arg) {
  if (isNaN(arg)) {
    console.warn("%s 不是一个合法的数字", arg);
    arg = 1;
  }
  return math.multiply(this, arg);
};

Number.prototype.div = function (arg) {
  if (isNaN(arg)) {
    console.warn("%s 不是一个合法的分母", arg);
    arg = 1;
  }
  if (arg === 0) {
    arg = 1;
    console.warn("除法的分母不能为零");
  }
  return math.divide(this, arg);
};

Number.prototype.floatLength = function () {
  let s = this.toString();
  if (s.indexOf(".") === -1) {
    return 0;
  } else {
    return s.split(".")[1].length;
  }
};

String.prototype.add = function (arg) {
  let n = 0;
  if (!isNaN(this)) {
    n = Number(this);
  } else {
    console.warn("%s 不是一个合法的数字", this);
  }
  if (isNaN(arg)) {
    console.warn("%s 不是一个合法的数字", arg);
    arg = 0;
  }
  return math.add(n, Number(arg));
};

String.prototype.sub = function (arg) {
  let n = 0;
  if (!isNaN(this)) {
    n = Number(this);
  } else {
    console.warn("%s 不是一个合法的数字", this);
  }
  if (isNaN(arg)) {
    console.warn("%s 不是一个合法的数字", arg);
    arg = 0;
  }
  return math.subtract(n, arg);
};

String.prototype.mul = function (arg) {
  let n = 1;
  if (!isNaN(this)) {
    n = Number(this);
  } else {
    console.warn("%s 不是一个合法的数字", this);
  }
  if (isNaN(arg)) {
    console.warn("%s 不是一个合法的数字", arg);
    arg = 1;
  }
  return math.multiply(n, arg);
};

String.prototype.div = function (arg) {
  let n = 1;
  if (!isNaN(this)) {
    n = Number(this);
  } else {
    console.warn("%s 不是一个合法的数字", this);
  }
  if (isNaN(arg)) {
    console.warn("%s 不是一个合法的数字", arg);
    arg = 1;
  }
  return math.divide(n, arg);
};

/**
 * 科学避险
 * @param digit
 * @return {String|string}
 */
String.prototype.toFixed = function (digit = 2) {
  if (isNaN(Number(this))) {
    console.log(this);
    console.warn("String类型不支持toFixed方法");
    return Number(0).toFixed(digit);
  } else {
    return Number(this).toFixed(digit);
  }
};

String.prototype.toFormat = function (digit = 2) {
  if (isNaN(Number(this))) {
    console.warn("String类型不支持toFormat方法");
    return Number(0).toFixed(digit);
  } else {
    return Number(this).toLocaleString("zh", { minimumFractionDigits: digit });
  }
};

Array.prototype.unique = function () {
  return Array.from(new Set(this));
};

Array.prototype.remove = function (arg) {
  for (let i = 0; i < this.length; ) {
    if (this[i] === arg) {
      this.splice(i, 1);
    } else {
      i++;
    }
  }
};

Array.prototype.filterPromise = async function (callback) {
  const fail = Symbol();
  return (await Promise.all(this.map(async (item) => ((await callback(item)) ? item : fail)))).filter(
    (i) => i !== fail
  );
};

Array.prototype.differ = function (target) {
  return this.filter((e) => {
    return !target.includes(e);
  });
};

Array.prototype.insert = function (target) {
  target.forEach((e) => {
    if (!this.includes(e)) this.push(e);
  });
};

Array.prototype.matrix = function (size) {
  const result = [];
  for (let x = 0; x < Math.ceil(this.length / size); x++) {
    let start = x * size;
    let end = start + size;
    result.push(this.slice(start, end));
  }
  return result;
};

Array.prototype.last = function () {
  return this[this.length - 1];
};

/**
 * 用于生成配置对象,将会移除配置中的undefined
 * @param data
 * @returns {*}
 */
Object.options = function (data) {
  for (let o of Object.keys(data)) {
    if (data[o] === undefined) {
      delete data[o];
    }
  }
  return data;
};
/**
 * 千分位格式化+toFixed合体
 * @param {Number} [digit]
 * @return {string}
 */
 Number.prototype.toFormat = function (digit = 2) {
  return this.toLocaleString("zh", { minimumFractionDigits: digit });
};

