import { makeAutoObservable, runInAction } from "mobx";
import { getIdentity, getPlatform } from "../utils";
import EVENT from "@/library/api";
import Store from ".";
import { Brand } from "../core";
import { RESTRICT } from "@/library/standard";

export class FuturesStore {
	//商品模型
	commodity = null;
	//交易手续费
	chargeInfo = null;
	//页面展示数据
	tradeData = {};
	//原价
	priceOriginal = 1;
	//价格变化
	priceChange = 0;
	//价格单位
	priceUnit = 0;
	//下单模式  0:实时 1:挂单
	orderType = 0;
	//当前价格
	price = 0;
	//预期价格
	targetPrice = 0;
	//买涨价格
	buyPrice = 0;
	//买跌价格
	sellPrice = 0;
	//交易截止时间
	closeTime = "00:00:00";
	//折扣类型 1.现金折扣  2.比例折扣
	discountType = 0;
	//折扣金额
	discountVal = 0;
	//实际折扣金额
	discountAmount = 0;
	//保证金及保证金范围
	depositList = [5, 10000];
	deposit = 0;
	//默认选择的index
	select = 0;
	//用户设置止损
	stopLoss = 0;
	stopLossList = [];
	//用户设置止盈
	stopProfit = 0;
	stopProfitList = [];
	//手数
	volumeIndex = 0;
	volume = 1;
	volumeList = [];
	//true = 买涨;false = 买跌
	isBuy = true;
	//是否是模拟
	simulate = false;
	//积分相关
	useEagle = false;
	usableEagle = true;
	deductMoney = 0;
	deductEagle = 0;
	deductCharge = 0;
	//手续费相关
	charge = 0;
	chargeList = [];
	chargeSpec = false;
	//元角模式
	moneyType = 0;
	moneyTypeList = [];
	rateList = [1, 0.1];
	rate = 1;
	//数字货币手续费模式
	_coinFormula = 0;
	//数字货币手续费计算因子X
	_coinFormulaX = 0;

	//杠杆
	lever = 0;
	leverList = [];
	leverShowList = [];
	//最大开仓量
	max = null;
	marginArea = [];
	//止盈与止损比例
	stopProfitRate = 5;
	stopLossRate = -0.9;
	//递延
	defer = false;
	deferBase = 0;
	deferType = 0;
	deferFee = 0;

	userInfo = {
		eagleMax: 1,
		partDeduct: 0,
	};

	//默认设置
	orderSetting = {
		sl: 0,
		tp: 0,
	};

	constructor() {
		makeAutoObservable(this);
	}

	getOrderSet(data) {
		runInAction(() => (this.orderSetting = data));
		window.localStorage.setItem("orderSetting", JSON.stringify(data));
	}

	//todo 获取商品相关配置
	getConfig(config, charge) {
		this.commodity = config;
		this.chargeInfo = charge;
		// this.isOpen = Rest.isOpening(origin)
		this.id = config.code;
		this.type = config.type;
		this.code = config.contractCode;
		this.crypto = config.coins;
		this.currency = config.currency;
		this.name = config.name;
		this.priceOriginal = config.priceOriginal;
		this.priceChange = config.priceChange;
		this.priceUnit = config.priceChange.mul(config.priceUnit);
		this.priceDigit = config.priceDigit;
		this.closeTime = config.closeTime;
		this.depositList = config.depositList;
		this.deposit = 0;
		if (config.code.indexOf("_CC") === -1) {
			this.stopLossList = config.stopLossList;
			this.stopLoss = config.stopLossList.map((e) => e.mul(this.deposit));
			this.stopProfitList = config.stopProfitList;
			this.stopProfit = config.stopProfitList[0];
		}
		this.volumeList = config.volumeList;
		this.volume = this.volumeList[this.volumeIndex];
		//CT
		if (this.crypto && config.code.indexOf("_CC") === -1) {
			this.lever = config.leverShowList[2];
			this.leverList = config.leverList || [];
			this.leverShowList = config.leverShowList;
		}

		if (Object.keys(charge).length !== 0 && charge.chargeUnitList.length > 1) {
			this.chargeSpec = true;
			this.chargeList = charge.chargeUnitList;
			this._coinFormula = Math.abs(charge.chargeUnitList[0]);
			this._coinFormulaX = charge.chargeUnitList[1].div(1000);
		} else {
			this.chargeSpec = false;
			this.charge = charge.chargeUnit;
		}
		config.moneyTypeList.length !== 0 ? (this.moneyTypeList = config.moneyTypeList) : this.moneyTypeList.push(0);
		//最大持仓量
		if (config?.maxHoldOne > 0) {
			this.max = config.maxHoldOne;
		}
		// 配置手续费折扣
		this.discountType = charge.discountType;
		this.discountVal = charge.discountVal;
		//递延
		this.deferFee = config.deferFee;
		this.deferType = config.deferType;
		//积分相关
		this.eagle = Store.User.accountInfo?.eagle || 0;
		this.eagleRatio = Store.User.accountInfo?.eagleRatio || 0;
		this.commodityItem();
	}

	//页面展示参数
	commodityItem() {
		this.calculate();
		let marginPrize = 0,
			chargeLucky = 0;
		//礼金抵扣计算
		if (!Store.Quote.simulate) {
			if (Brand.code === "FK") {
				marginPrize = Math.min(Store.User.assets.prize, this.deposit * Brand.prizeTrade);
			} else {
				marginPrize = Math.min(Store.User.accountInfo.prize, this.deposit * Brand.prizeTrade);
				chargeLucky = Math.min(Store.User.accountInfo.lucky, Number(this.charge).mul(Brand.luckyTrade));
			}
		}
		this.tradeData = {
			name: this.name,
			code: this.code,
			id: this.id,
			isBuy: this.isBuy,
			orderType: this.orderType,
			moneyType: this.moneyType,
			moneyTypeList: this.moneyTypeList,
			targetPrice: this.targetPrice,
			buyPrice: this.buyPrice.toFixed(this.priceDigit),
			sellPrice: this.sellPrice.toFixed(this.priceDigit),
			volume: this.volume,
			volumeIndex: this.volumeIndex,
			volumeList: this.volumeList,
			select: this.select,
			stopLoss: this.stopLoss,
			stopLossList: this.stopLossList,
			stopProfit: this.stopProfit,
			margin: this.deposit,
			marginArea: this.marginArea,
			stopProfitList: this.stopProfitList,
			leverList: this.leverList || [],
			leverShowList: this.leverShowList,
			total: this.deductCharge.add(this.deposit).sub(marginPrize).sub(chargeLucky),
			//抵扣信息
			marginPrize: RESTRICT.float(marginPrize, 2),
			chargeLucky,
			//是否使用积分抵扣
			useEagle: this.useEagle,
			//是否可用积分抵扣
			usableEagle: this.usableEagle,
			//当前抵扣的金额
			deductMoney: this.deductMoney,
			//当前消耗的积分
			deductEagle: this.useEagle ? this.deductEagle : 0,
			//积分抵扣后剩余的手续费
			deductCharge: Number(this.deductCharge).toFixed(2),
			//原始的手续费
			charge: this.charge.toFixed(2),
			//折扣金额
			discount: Number(this.discountAmount).toFixed(2),
			// isOpen: this.isOpen,
			//点差
			priceChange: this.priceChange,
			priceDigit: this.priceDigit,
			defer: this.defer,
			eagle: this.eagle,
			eagleRatio: this.eagleRatio,
		};
	}

	//todo 换算商品相关参数
	calculate() {
		if (this.crypto) {
			//数字货币保证金范围
			this.marginArea = this.depositList;
			if (this.max !== null) {
				this.marginArea[1] =
					this.lever === "" || this.lever < 10 ? this.depositList[1] : Math.trunc(+this.max.div(+this.lever));
				if (this.deposit > this.marginArea[1]) {
					this.deposit = this.marginArea[1];
				}
			}
		} else {
			//期货保证金范围
			const depositListBase = this.depositList.map((e) => e.mul(this.volume).mul(this.rate));
			this.stopLoss = this.stopLossList.map((o, key) => RESTRICT.float(depositListBase[key], 1));
			this.stopProfit = this.stopProfitList.map((o, key) => depositListBase[key]);
			this.deposit = this.stopProfit.length !== 0 && this.stopProfit[this.select];
		}

		//计算手续费
		if (this.chargeSpec) {
			let x = this.priceOriginal.mul(this.volume).mul(this.isBuy ? this.buyPrice : this.sellPrice);
			if (this._coinFormula === 1) {
				this.charge = x.sub(Math.abs(this.stopLoss)).mul(this._coinFormulaX);
			} else if (this._coinFormula === 2) {
				this.charge = x.mul(this._coinFormulaX);
			} else if (this._coinFormula === 3) {
				if (this.deposit === 0) {
					this.charge = 0;
				} else {
					this.charge = this.deposit * this.lever * this._coinFormulaX;
				}
			}
		} else {
			if (!!this.chargeInfo) {
				this.charge = this.chargeInfo.chargeUnit.mul(this.volume).mul(this.rate);
			}
		}
		//折扣手续费计算
		if (this.discountType === 1) {
			this.discountAmount = this.discountVal;
		} else if (this.discountType === 2) {
			if (this.discountVal === 0) {
				this.discountAmount = this.discountVal;
			} else {
				this.discountAmount = this.charge.mul(Math.abs(this.discountVal.sub(1)));
			}
		}
		if (this.discountType !== 0) {
			this.charge -= this.discountAmount;
		}
		// this.deductCharge = this.charge;

		//积分相关内容
		const available = this.eagle?.div(this.eagleRatio) || 0;
		const max = this.charge.mul(this.userInfo.eagleMax);
		this.usableEagle = (available >= max || this.userInfo.partDeduct) && !Store.Quote.simulate;
		if (this.usableEagle && this.useEagle) {
			if (max >= available) {
				this.deductEagle = this.userInfo.eagle;
				this.deductMoney = available;
				this.deductCharge = this.charge.sub(available);
			} else {
				this.deductEagle = max.mul(this.eagleRatio);
				this.deductMoney = max;
				this.deductCharge = this.charge.sub(max);
			}
		} else {
			this.useEagle = false;
			this.deductEagle = 0;
			this.deductMoney = 0;
			this.deductCharge = this.charge;
		}
	}

	//更新商品价格
	update(data) {
		if (data && data.id === this.id) {
			this.buyPrice = data.buyPrice;
			this.sellPrice = data.sellPrice;
			this.price = data.price;
			if (!this.orderType && this.orderType === 0 && this.targetPrice === "0") {
				this.targetPrice = this.isBuy ? this.buyPrice : this.sellPrice;
			}
		}
	}

	switchIsBuy(isBuy) {
		this.isBuy = isBuy;
		this.commodityItem();
	}

	//切换圆角模式
	switchMoneyType(index) {
		this.moneyType = index;
		this.rate = this.rateList[index];
		this.commodityItem();
	}

	//切换手数
	switchVolume(index, update = true) {
		if (index < 0) return;
		runInAction(() => {
			this.volumeIndex = index;
			this.volume = this.volumeList[index];
		});
		if (update) {
			this.commodityItem();
		}
	}

	//切换止损
	switchStopLoss(index) {
		if (index < 0) return;
		const max = this.stopLossList.length - 1;
		if (index > max) index = max;
		index = Math.max(index, 0);
		this.select = index;
		this.commodityItem();
	}

	//切换交易模式
	switchOrderType(state) {
		state ? (this.orderType = 1) : (this.orderType = 0);
		this.commodityItem();
	}

	//切换积分开关
	switchEagle(state) {
		this.useEagle = state;
		this.commodityItem();
	}

	//设置挂单价格
	setTargetPrice(val) {
		val = val.toString();
		if (val.indexOf(".") !== -1) {
			if (val.split(".")[1].length >= this.priceDigit) {
				val = val.toFixed(this.priceDigit);
			}
		}
		this.targetPrice = val;
		this.commodityItem();
	}

	//切换杠杆比例(CT)
	switchLever(val) {
		this.lever = val;
		this.commodityItem();
	}

	//数字货币保证金
	switchMarginFree(val) {
		this.deposit = val;
		this.commodityItem();
	}

	//修改数字货币止损比例
	switchStopLossRate(val) {
		this.stopLossRate = -val.div(100);
	}

	//修改数字货币止盈比例
	switchStopProfitRate(val) {
		this.stopProfitRate = val.div(100);
	}
	//是否递延
	switchDefer(val) {
		this.defer = val;
		this.commodityItem();
	}

	//退出页面重置默认选项(FT)
	clearSelect() {
		this.moneyType = 0;
		this.rate = this.rateList[this.moneyType];
		this.volumeIndex = 0;
		this.select = 0;
		this.useEagle = false;
		this.targetPrice = 0;
		this.orderType = 0;
		this.deductEagle = 0;
		this.commodityItem();
	}

	//todo 下单
	order(isBuy) {
		return new Promise(async (resolve, reject) => {
			try {
				const config = {
					identity: getIdentity(16),
					tradeType: Store.Quote.simulate ? 2 : 1, //模拟交易2 实盘交易1
					source: "下单", // 买入来源（下单、反向、快捷)
					commodity: this.id,
					contract: this.code,
					isBuy: isBuy,
					margin: this.deposit.toFixed(2),
					price: this.orderType === 0 || Store.Quote.simulate ? 0 : RESTRICT.float(this.targetPrice, this.priceDigit),
					serviceCharge: Number(this.deductCharge),
					eagleDeduction: this.useEagle ? this.deductEagle : 0,
					platform: getPlatform(),
					currency: Brand.defaultCurrency,
					moneyType: this.moneyTypeList[this.moneyType],
					shared: true, //跟单
				};
				if (this.crypto) {
					config.volume = this.deposit;
					config.lever = this.lever;
					config.stopProfit = this.stopProfitRate;
					config.stopLoss = this.stopLossRate;
					config.defer = Store.Quote.simulate ? false : this.defer;
					config.deferFee = this.defer && !Store.Quote.simulate ? this.deferFee.mul(this.deposit).mul(this.lever) : 0;
					config.leverType = 2;
				} else {
					config.volume = this.volume;
					config.stopProfit = this.stopProfitList[this.select];
					config.stopLoss = this.stopLossList[this.select];
				}
				const result = await EVENT.Trade.order(config);
				// if (Brand.code === "CX") {
				//   Store.Wallet.updateWallet();
				// }
				resolve(result);
			} catch (e) {
				reject(e);
			}
		});
	}

	//todo 平仓返下单
	reverseOrder(item, source = "反买") {
		// const { money, eagle } = Store.User.assets;
		const { money, eagle } = Store.Wallet.getCurrency("CNY");
		return new Promise(async (resolve, reject) => {
			try {
				let {
					id,
					commodityCode,
					contractCode,
					isBuy,
					volume,
					margin,
					tempVolume,
					serviceCharge,
					eagleDeduction,
					moneyType,
					crypto,
					lever,
					stopProfitBegin,
					stopLossBegin,
					defer,
					deferFee,
					stopProfitMax,
					stopLossMax,
					income,
				} = item;
				let tempMargin = item.margin;
				//期货反买可选择手数
				if (tempVolume && tempVolume !== volume && item.commodityCode.indexOf("USDT") === -1) {
					margin = margin.div(volume).mul(tempVolume);
					if (serviceCharge === 0) {
						eagleDeduction = eagleDeduction.div(volume).mul(tempVolume);
					} else {
						serviceCharge = serviceCharge.div(volume).mul(tempVolume);
					}
					volume = tempVolume;
				}
				if (serviceCharge === 0 && eagleDeduction === 0) return reject({ message: "订单数据错误" });
				if (eagleDeduction > eagle && income + tempMargin + money < serviceCharge + margin)
					return reject({ message: "余额不足" });
				if (eagleDeduction > eagle) {
					serviceCharge = eagleDeduction.div(10);
					eagleDeduction = 0;
				}
				if (source === "反买") {
					EVENT.Trade.close(id, "reverse");
				}
				const config = {
					identity: getIdentity(16),
					tradeType: Store.Quote.simulate ? 2 : 1, //模拟交易2 实盘交易1
					source: source,
					commodity: commodityCode,
					contract: contractCode,
					isBuy: source === "反买" ? !isBuy : isBuy,
					margin: margin,
					price: 0,
					serviceCharge: serviceCharge,
					eagleDeduction: eagleDeduction,
					platform: getPlatform(),
					currency: Brand.defaultCurrency,
					moneyType: moneyType,
					shared: true, //跟单
				};
				if (crypto) {
					config.volume = margin;
					config.lever = lever;
					config.stopProfit = stopProfitBegin.div(margin);
					config.stopLoss = stopLossBegin.div(margin);
					config.defer = defer;
					config.deferFee = defer ? deferFee : 0;
					config.leverType = 2;
				} else {
					config.volume = volume;
					config.stopProfit = stopProfitMax;
					config.stopLoss = stopLossMax;
				}
				const result = await EVENT.Trade.order(config);
				// if (Brand.code === "CX") {
				//   await Store.Wallet.updateWallet();
				// }
				resolve(result);
			} catch (e) {
				reject(e);
			}
		});
	}

	//todo 设置默认止盈比例
	updateProfitPercent(val) {
		if (val < 5 || val > 500) return;
		runInAction(() => (this.orderSetting.tp = val.div(100)));
	}

	//todo 设置默认止损比例
	updateLossPercent(val) {
		if (val < 5 || val > 90) return;
		runInAction(() => (this.orderSetting.sl = val.div(100)));
	}

	switchOrderConfirm() {
		runInAction(() => (this.orderSetting.confirmPlace = !this.orderSetting.confirmPlace));
	}

	switchCloseConfirm() {
		runInAction(() => (this.orderSetting.confirmClose = !this.orderSetting.confirmClose));
	}

	switchOvernight() {
		runInAction(() => (this.orderSetting.overnight = !this.orderSetting.overnight));
	}

	saveSetting() {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await EVENT.Account.saveOrderDefault(this.orderSetting);
				window.localStorage.setItem("orderSetting", JSON.stringify(this.orderSetting));
				Store.User.updateOrderDefault();
				resolve(result);
			} catch (e) {
				reject(e);
			}
		});
	}
}
