import React from "react";
import position from "@assets/common/position_black.png";

// style
import styles from "../index.module.scss";

export default function Empty({ message }) {
	return (
		<div className={styles.empty}>
			<div>
				<img src={position} alt="" />
				<b>{message}</b>
			</div>
		</div>
	);
}
