import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { notification } from "antd";

// store
import Store from "@/store";
// lib
import EVENT from "@/library/api";
import { safeValue } from "@/library/safety";
// components
import SimpleInput from "@/view/components/SimpleInput";
import { CommonIcon } from "@/view/components/Svg";
// style
import styles from "../index.module.scss";

const MobileForm = (props) => {
	const { loginInfo, setLoginInfo } = props;
	const [formatVerify, setFormatVerify] = useState({ phone: false, password: false });
	return (
		<div className={styles.form}>
			<SimpleInput
				label="手机号码"
				placeholder="请输入手机号码"
				type={"tel"}
				format={"PHONE"}
				notice={"tel"}
				value={loginInfo.phone}
				onChange={(phone, format) => {
					if (format !== formatVerify.phone) {
						setFormatVerify((props) => ({ ...props, phone: format }));
					}
					const correct =
						safeValue(phone, true) && safeValue(loginInfo.password, true) && format && formatVerify.password;
					setLoginInfo((props) => ({ ...props, phone, error: !correct }));
				}}
			/>
			<SimpleInput
				label="登录密码"
				placeholder="请输入登录密码"
				type={"password"}
				format={"SET_WITHDRAWPASSWORD"}
				notice={"withdrawpassword"}
				value={loginInfo.password}
				simple={true}
				force={true}
				onChange={(password, format) => {
					if (format !== formatVerify.password) {
						setFormatVerify((props) => ({ ...props, password: format }));
					}
					const correct = safeValue(loginInfo.phone, true) && safeValue(password, true) && format && formatVerify.phone;
					setLoginInfo((props) => ({ ...props, password, error: !correct }));
				}}
			/>
		</div>
	);
};
const EmailForm = (props) => {
	const { loginInfo, setLoginInfo } = props;
	const [formatVerify, setFormatVerify] = useState({ email: false, password: false });
	return (
		<div className={styles.form}>
			<SimpleInput
				label="电子邮箱"
				placeholder="请输入邮箱地址"
				type={"email"}
				format={"EMAIL"}
				notice={"email"}
				value={loginInfo.email}
				onChange={(email, format) => {
					if (format !== formatVerify.email) {
						setFormatVerify((props) => ({ ...props, email: format }));
					}
					const correct =
						safeValue(email, true) && safeValue(loginInfo.password, true) && format && formatVerify.password;
					setLoginInfo((props) => ({ ...props, email, error: !correct }));
				}}
			/>
			<SimpleInput
				label="登录密码"
				placeholder="请输入登录密码"
				type={"password"}
				format={"SET_WITHDRAWPASSWORD"}
				notice={"withdrawpassword"}
				value={loginInfo.password}
				simple={true}
				force={true}
				onChange={(password, format) => {
					if (format !== formatVerify.password) {
						setFormatVerify((props) => ({ ...props, password: format }));
					}
					const correct = safeValue(loginInfo.email, true) && safeValue(password, true) && format && formatVerify.email;
					setLoginInfo((props) => ({ ...props, password, error: !correct }));
				}}
			/>
		</div>
	);
};

export default function Login() {
	const location = useLocation();
	const navigate = useNavigate();
	const [loginInfo, setLoginInfo] = useState({
		type: "MOBILE",
		phone: "",
		email: "",
		password: "",
		error: true,
	});
	const switchLoginType = (type) => {
		setLoginInfo((props) => ({ ...props, type, phone: "", email: "", password: "" }));
	};
	const handleLogin = () => {
		if (loginInfo.error) {
			return;
		}

		EVENT.Account.login({
			account: loginInfo.type === "MOBILE" ? loginInfo.phone : loginInfo.email,
			password: loginInfo.password,
		})
			.then(({ user, message }) => {
				Store.User.loginCallback(user);
				// const from = location.state?.from || "/";

				notification.success({
					message,
					// duration: 2,
				});
				navigate("/trade/crypto/BTCUSDT");
			})
			.catch(({ message }) => notification.error({ message }));
	};

	return (
		<div className={styles.formWrapper}>
			<div className={styles.switchBtn}>
				<div className={loginInfo.type === "MOBILE" ? styles.active : ""} onClick={() => switchLoginType("MOBILE")}>
					手机登录
				</div>
				<div className={loginInfo.type !== "MOBILE" ? styles.active : ""} onClick={() => switchLoginType("EMAIL")}>
					邮箱登录
				</div>
			</div>
			{loginInfo.type === "MOBILE" ? (
				<MobileForm loginInfo={loginInfo} setLoginInfo={setLoginInfo} />
			) : (
				<EmailForm loginInfo={loginInfo} setLoginInfo={setLoginInfo} />
			)}
			<div className={styles.linkBox}>
				<Link className={styles.forgot} to={"/common/resetLoginPassword"}>
					忘记密码
				</Link>
				<a href={"/aboutUs#qualification"}>
					<CommonIcon img="USA" />
					美国金融监管机构权威认证
				</a>
			</div>
			<div className={`${styles.confirmBtn} ${loginInfo.error ? styles.error : ""}`} onClick={() => handleLogin()}>
				登录
			</div>
			<div className={styles.switchType}>
				<p>
					没有账户？<Link to={"/common/register"}>立即注册</Link>
				</p>
			</div>
		</div>
	);
}
