import { makeAutoObservable } from "mobx";
import { getPlatform, getIdentity } from "@/utils";
import EVENT from "../library/api";

export class SpotStore {
	check = {};
	//单个商品信息
	commodity = null;
	//最小
	volumeMin = 0;
	volumeDigit = 0;
	url;
	id;
	price = 0;
	priceDigit = 0;
	size = 20;
	type = 0; //请求类型 0全部 1买 2卖
	asks = [];
	asksTotal = 0;
	bids = [];
	bidsTotal = 0;
	defaultVolume = 6;
	defaultTurnover = 25;
	orderType = 0; // 0 限价 1 市价
	currency = "USDT";
	code = "";
	turnover = [];
	targetPrice = 0;

	constructor() {
		makeAutoObservable(this);
	}
	//获取所有商品
	getAll(total) {
		this.check = total;
	}

	setSpotId(id) {
		this.id = id;
		this.code = id.split("USDT")[0];
		if (Object.keys(this.check).length !== 0 && this.check[id] !== null) {
			this.commodity = this.check[this.id].config;
			this.volumeMin = this.commodity.volumeMin;
			this.priceDigit = this.commodity.priceDigit;
			const volumeMin = this.commodity.volumeMin.toString();
			this.volumeDigit = volumeMin.indexOf(".") !== -1 ? volumeMin.split(".")[1].length : 0;
		} else {
			this.waitData = setTimeout(() => this.setSpotId(this.id), 200);
		}
	}

	//todo 深度图数据
	getDepth(asks, bids) {
		if (asks === undefined || bids === undefined) return;
		this.asks = asks.slice().reverse();
		this.bids = bids;
		let a = 0,
			b = 0;
		asks.forEach((s, key) => {
			if (key < this.defaultVolume) a += s[1];
		});
		this.asksTotal = a;
		bids.forEach((i, key) => {
			if (key < this.defaultVolume) b += i[1];
		});
		this.bidsTotal = b;
	}

	//todo 获取最新价
	getTurnover(data) {
		if (this.turnover.length > this.defaultTurnover) {
			this.turnover.pop();
		}
		this.turnover.unshift(data);
	}

	//todo 最新价显示数量
	switchTurnover(num) {
		this.defaultTurnover = num;
	}

	//todo 深度显示显示数量（最大20）
	switchVolume(num) {
		this.defaultVolume = num;
	}

	//todo 当前价格更新
	update(data) {
		if (data && data.id === this.id) {
			this.price = data.price;
		}
	}

	//todo 切换限价/市价
	switchOrderType(status) {
		this.orderType = status;
	}

	//设置挂单价格
	setTargetPrice(val) {
		this.targetPrice = val;
	}

	order(isBuy, volume) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = {
					identity: getIdentity(16),
					commodity: this.id,
					buy: isBuy,
					type: this.orderType,
					srcCurrency: isBuy ? this.currency : this.code,
					desCurrency: isBuy ? this.code : this.currency,
					price: this.orderType === 0 ? this.targetPrice : 0,
					volume,
					platform: getPlatform(),
					chargeEagle: 0,
				};
				const result = await EVENT.Spot.order(data);
				resolve(result);
			} catch (e) {
				reject(e);
			}
		});
	}
}
