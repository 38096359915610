import md5 from "md5";

export function passport(obj) {
  let matrix = [
    "T",
    "h",
    "i",
    "s",
    " ",
    "a",
    "t",
    "o",
    "r",
    "y",
    ",",
    "b",
    "u",
    "H",
    "w",
    "P",
    "e",
    "v",
    "W",
    "l",
    "d",
    "f",
    "m",
    "V",
    "!",
    "A",
    "k",
    "n",
    "c",
    "g",
    "p",
    ".",
  ];
  let key =
    "13-16-19-19-7-10-4-20-16-5-8-4-21-8-2-16-27-20-3-24-4-18-16-19-28-7-22-16-4-6-7-4-11-2-6-9-5-8-20-24";
  key = key.split("-");
  key = key.map((e) => matrix[e]);
  key = key.join("");
  let book = [];
  for (let [k, value] of Object.entries(obj)) {
    book.push(`${k}=${value}`);
  }
  book.sort();
  book.push(`key=${key}`);
  return md5(book.join("&"));
}
