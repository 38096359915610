/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { decode } from "html-entities";

import dayjs from "dayjs";

// utils
import { isEmpty, arrayToStr } from "@/utils";
// store
import Store from "@/store";
// style
import styles from "./index.module.scss";

const transformHtml = (text) => {
	return { __html: decode(text) };
};

export default function AnnounceDetail({ item }) {
	const navigate = useNavigate();

	return (
		<div className={styles.announceDetail}>
			<h5 className={styles.header} onClick={() => navigate("/announcements")}>
				动态公告
			</h5>

			<div className={styles.detail}>
				{!isEmpty(item) && (
					<>
						<h2 className={styles.title}>{item.title}</h2>
						<div className={styles.subTitle}>{dayjs(item.time).format("YYYY-MM-DD HH:mm:ss")}</div>
						<hr />
						<div className={styles.html} dangerouslySetInnerHTML={transformHtml(item.content)} />
					</>
				)}
			</div>
		</div>
	);
}
