import React from "react";
import { Navigate } from "react-router-dom";
import Home from "@/view/home";
import Common from "@/view/common";
import Login from "@/view/common/login";
import Register from "@/view/common/register";
import ResetLoginPassword from "@/view/common/resetLoginPwd";
import Download from "@/view/download";
import Invite from "@/view/cooperation/invite";
import Proxy from "@/view/cooperation/proxy";
import Activity from "@/view/activity";
import AboutUs from "@/view/aboutUs";
import Account from "@/view/account";
import UserCenter from "@/view/account/userCenter";
import NameVerify from "@/view/account/nameVerify";
import LoginHistory from "@/view/account/loginHistory";
import MyAsset from "@/view/account/myAsset";
import Recharge from "@/view/account/recharge";
import Withdraw from "@/view/account/withdraw";
import Bankcard from "@/view/account/bankcard";
import AliPay from "@/view/account/alipay";
import FundsDetail from "@/view/account/fundsDetail";
import InviteFriends from "@/view/account/inviteFriends";
import Trade from "@/view/trade";
import Announcements from "@/view/announcements";

//路由都写在上面
import NoMatch from "./404";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Navigate to="/" />,
  },
  // 登入、註冊、忘記密碼
  {
    path: "/common",
    element: <Common />,
    nestRoutes: [
      {
        path: "/common/login",
        element: <Login />,
      },
      {
        path: "/common/register",
        element: <Register />,
      },
      {
        path: "/common/resetLoginPassword",
        element: <ResetLoginPassword />,
      },
    ],
  },
  {
    path: "/download",
    element: <Download />,
  },
  {
    path: "/cooperation/proxy",
    element: <Proxy />,
  },
  {
    path: "/cooperation/invite",
    element: <Invite />,
  },
  {
    path: "/activity",
    element: <Activity />,
  },
  {
    path: "/aboutUs",
    element: <AboutUs />,
  },
  // 會員中心頁
  {
    path: "/account",
    auth: true,
    element: <Account />,
    nestRoutes: [
      {
        path: "/account/userCenter",
        element: <UserCenter />,
      },
      {
        path: "/account/nameVerify",
        element: <NameVerify />,
      },
      {
        path: "/account/loginHistory",
        element: <LoginHistory />,
      },
      {
        path: "/account/myAsset",
        element: <MyAsset />,
      },
      {
        path: "/account/recharge",
        element: <Recharge />,
      },
      {
        path: "/account/withdraw",
        element: <Withdraw />,
      },
      {
        path: "/account/bankcard",
        element: <Bankcard />,
      },
      {
        path: "/account/aliPay",
        element: <AliPay />,
      },
      {
        path: "/account/fundsDetail",
        element: <FundsDetail />,
      },
      {
        path: "/account/inviteFriends",
        element: <InviteFriends />,
      },
    ],
  },
  // 交易頁
  {
    path: "/trade",
    element: <Trade />,
    nestRoutes: [
      {
        path: "/trade/futures/:id",
        // element: <Login />,
      },
      {
        path: "/trade/crypto/:id",
        // element: <Register />,
      },
      {
        path: "/trade/foreignE/:id",
        // element: <ResetLoginPassword />,
      },
      {
        path: "/trade/stock/:id",
        // element: <ResetLoginPassword />,
      },
    ],
  },
  {
    path: "/announcements",
    element: <Announcements />,
    nestRoutes: [
      {
        path: "/announcements/:id",
        // element: <UserCenter />,
      },
    ],
  },
  // 路由写上面
  {
    path: "*",
    element: <NoMatch />,
  },
];

export default routes;
