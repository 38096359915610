function parseJSONorNot(mayBeJSON) {
  if (typeof mayBeJSON === "string") {
    return JSON.parse(mayBeJSON);
  } else {
    return mayBeJSON;
  }
}

export function dataProcess(response, resolution, from, to, process, volume) {
  let data = parseJSONorNot(response);
  let nodata = data.s === "no_data";
  if (data.s !== "ok" || nodata) {
    return data;
  }
  let bars = [];
  //	data is JSON having format {s: "status" (ok, no_data, error),
  //  v: [volumes], t: [times], o: [opens], h: [highs], l: [lows], c:[closes], nb: "optional_unixtime_if_no_data"}

  let jet = 0;
  if (resolution === "1D") {
    // 日线是带时差的 UNIX时间 咱们的服务器返回的是 UTC+8
    jet = 8 * 60 * 60 * 1000;
  }

  let barsCount = nodata ? 0 : data.t.length;
  let o = 0;
  for (let i = 0; i < barsCount; ++i) {
    const t = data.t[i] * 1;
    if (t >= from && t < to) {
      if (process && data.c[i - 1] !== undefined) {
        o = data.c[i - 1];
      } else {
        o = data.o[i];
      }
      const n = {
        time: t * 1000 + jet,
        close: +data.c[i],
        open: +o,
        high: +data.h[i],
        low: +data.l[i],
      };
      if (volume) {
        n.volume = +data.v[i];
      }
      bars.push(n);
    }
  }
  return bars;
}

export async function makeApiRequest(path) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`/api/tv/tradingView${path}`);
      if (response.ok) {
        return resolve(response.json());
      } else {
        return reject(response.statusText);
      }
    } catch (error) {
      reject(error.status);
    }
  });
}
