import React, { useState } from "react";
// utils
import { districtData, cityData } from "@/utils";
import SimpleSelect from "@/view/components/SimpleSelect";
// style
import styles from "./index.module.scss";

export default function Linkage({ province, city, getProvince, getCity }) {
	return (
		<div className={styles.zone}>
			<SimpleSelect
				customClass={styles.simpleSelect}
				label="省份"
				options={districtData()}
				value={province}
				placeholder="选择省份"
				onChange={(val) => {
					getProvince(val);
					getCity(cityData(val)[0].value);
				}}
			/>
			<SimpleSelect
				customClass={styles.simpleSelect}
				label="城市/地区"
				options={cityData(province)}
				placeholder="选择城市/地区"
				disabled={!province}
				value={city}
				onChange={(val) => {
					getCity(val);
				}}
			/>
		</div>
	);
}
