import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
// import { toJS } from "mobx";
// utils
import { goodsImg } from "@/utils/common";
// store
import Store from "@/store";
// components
import { TradeIcon } from "@/view/components/Svg";
import TvChart from "@/view/components/tvChart";
// style
import styles from "../index.module.scss";

const Chart = ({ tradeType }) => {
	const { des, price, rate, percent, isUp, isOpen, max, min, name, id, totalVolume } = Store.Quote.quote;
	useEffect(() => {
		document.title = `${isUp ? "\u2191" : "\u2193"} ${price}(${des}) - 交易通`;
		return () => {
			document.title = "交易通 全球大类资产金融衍生品投资交易平台";
		};
	}, [id, isUp, price, des]);
	const displayName = useMemo(() => {
		if (tradeType === "crypto") {
			return id;
		}
		return `${name}(${id})`;
	}, [tradeType, name, id]);
	return (
		<div className={styles.chart}>
			<div className={styles.chartInfo}>
				<div className={styles.info}>
					<div>
						<div className={styles.infoName}>
							{id !== "--" && <TradeIcon img={`goods/${goodsImg[id]}`} />}
							<span>{displayName}</span>
						</div>
						<b className={isOpen ? (isUp ? "red" : "green") : "rest"}>{isOpen ? price : id !== "--" ? "休市" : "--"}</b>
					</div>
					<div className={styles.grid}>
						<label>涨跌额</label>
						<div className={isOpen ? (isUp ? "red" : "green") : "rest"}>{rate}</div>
					</div>
					<div className={styles.grid}>
						<label>涨跌幅</label>
						<div className={isOpen ? (isUp ? "red" : "green") : "rest"}>{percent}</div>
					</div>
					<div className={styles.grid}>
						<label>最高</label>
						<div>{max}</div>
					</div>
					<div className={styles.grid}>
						<label>最低</label>
						<div>{min}</div>
					</div>
					{tradeType !== "foreignE" && (
						<div className={styles.grid}>
							<label>24H成交量</label>
							<div>{totalVolume}</div>
						</div>
					)}
				</div>
			</div>
			<TvChart />
		</div>
	);
};

export default observer(Chart);
