import React, { useEffect, useState } from "react";
import { Pagination, notification } from "antd";
// lib
import EVENT from "@/library/api";
import { RESTRICT } from "@/library/standard";
// utils
import { isEmpty, formatDate, splitAry } from "@/utils";
// assets
import funds from "@assets/common/funds_light.png";
// style
import styles from "./index.module.scss";
export default function MyUser() {
	const [subName, setSubName] = useState("");
	const [myUserInfo, setMyUserInfo] = useState({ list: [], total: 0 });
	const [page, setPage] = useState(1);

	useEffect(() => {
		updateUserList();
	}, []);

	const updateUserList = async () => {
		EVENT.Account.updateUserList()
			.then(({ data }) => {
				const list = subName ? data.filter((item) => item.username === subName) : data;
				setMyUserInfo({ list: splitAry(list, 10), total: list.length });
				setPage(1);
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};

	const onChangePage = (page) => {
		if (page - 1 >= myUserInfo.list.length) return;
		setPage(Number(page));
	};
	return (
		<div className={styles.userBox}>
			<div className={styles.title}>
				<div className={styles.titleSection}>
					<label>被邀请人</label>
					<input type="text" placeholder="请输入昵称" maxLength={20} onChange={(e) => setSubName(e.target.value)} />
				</div>
				<div className={styles.search} onClick={updateUserList}>
					搜索
				</div>
			</div>
			<ol>
				<li className={styles.header}>
					<div>被邀请人</div>
					<div>今日交易量</div>
					<div>累计交易量</div>
					<div>累计交易次数</div>
					<div>注册时间</div>
					<div>最后登录</div>
				</li>
				{!isEmpty(myUserInfo.list) ? (
					myUserInfo.list[page - 1].map((item, key) => (
						<li key={key}>
							<div>{item.username}</div>
							<div>{item.tradeAmountDay}</div>
							<div>{item.tradeAmount}</div>
							<div>{item.tradeCount}</div>
							<div>{formatDate("y-m-d h:i:s", { date: item.registerTime })}</div>
							<div>{formatDate("y-m-d h:i:s", { date: item.loginTime })}</div>
						</li>
					))
				) : (
					<div className={styles.empty}>
						<div>
							<img src={funds} alt="" />
							<b>暂无记录</b>
						</div>
					</div>
				)}
			</ol>
			<div className="pagination">
				<Pagination
					onChange={onChangePage}
					total={myUserInfo.total}
					defaultPageSize={10}
					current={page}
					showSizeChanger={false}
				/>
			</div>
		</div>
	);
}
