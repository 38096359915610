import service from "../network/axios";
import { getPlatform, getSearch } from "@/utils";

/**
 * 获取法币充值渠道
 * @param {*} type
 * @returns
 */
const getPaymentList = async (type = 0) => {
  const o = {
    action: "getPayList",
    switchType: 1,
    paymentWay: type,
    platform: getPlatform(),
  };
  const result = await service.post("/api/pay/recharge.htm", o);
  return result;
};
/**
 * 获取可充值币种
 * @returns
 */
const getDepositCurrency = async () => {
  const result = await service.get("/api/setting/recharge-currency");
  return result;
};
/**
 * 验证chain 有效性
 * @param {*} currency 币种
 */
const getChains = async (currency) => {
  const o = { currency };
  const result = await service.get("/api/setting/chains", { params: o });
  return result;
};
/**
 * 获取充值地址
 * @param {*} currency 币种
 * @param {*} chain 链
 */
const getCryptoAddress = async (currency, chain) => {
  const o = { currency, chain };
  const result = await service.get("/api/pay/recharge/getAddress", {
    params: o,
  });
  return result;
};
/**
 * 支付
 * @param {*} data url 地址  money 金额 channel 标识
 * @param {*} pc PC
 */
const fastPayment = (data, pc) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, money, channel, accountName } = data;
      let o = getSearch(url);
      o.money = money;
      o.channel = channel;
      if (accountName) o.accountName = accountName;
      const result = await service.post("/api/pay/rechargeXXPay.htm", o, { timeout: 20000 });
      if (pc) return resolve(`/api/vf/tdCode.gif?url=${result.redirectURL}`);
      resolve(result.redirectURL);
    } catch ({ message }) {
      reject(message);
    }
  });
};

/**
 * 获取可提款币种
 */
const getWithdrawCurrency = async () => {
  const result = await service.get("/api/setting/withdraw-currency");
  return result;
};

/**
 * 闪兑？
 */
const getTransferCurrency = async () => {
  const result = await service.get("/api/setting/transfer-currency");
  return result;
};

export default {
  getPaymentList,
  getDepositCurrency,
  getWithdrawCurrency,
  getTransferCurrency,
  getChains,
  getCryptoAddress,
  fastPayment,
};
