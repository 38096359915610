import React from "react";
import { arrayToStr } from "@/utils";
import { ReactComponent as Close } from "@assets/common/x.svg";
import styles from "./index.module.scss";

export default function Modal({ title, close, children, className }) {
	return (
		<div className={styles.modal}>
			<div className={styles.content}>
				<div className={styles.close} onClick={close}>
					<Close />
				</div>
				<div className={styles.header}>
					<span className={styles.title}>{title}</span>
				</div>
				<div className={arrayToStr([styles.body, className])}>{children}</div>
			</div>
		</div>
	);
}
