import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { message } from "antd";
import { ReactComponent as CopyIcon } from "@assets/common/copy.svg";
import styles from "./index.module.scss";

export default function Copy({ text, className, title }) {
	return (
		<CopyToClipboard text={text}>
			<span
				className={`${styles.commonCopy} ${className}`}
				onClick={() => {
					if (text) {
						message.success("复制成功");
					}
				}}
			>
				{title ? title : <CopyIcon />}
			</span>
		</CopyToClipboard>
	);
}
