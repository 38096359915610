import React, { useState, useMemo } from "react";

// components
import OneStep from "./OneStep";
import TwoStep from "./TwoStep";
// style
import styles from "../index.module.scss";

export default function ResetLoginPassword() {
  const [resetInfo, setResetInfo] = useState({
    type: "MOBILE",
    step: 1,
    token: "",
  });

  const form = useMemo(
    () =>
      resetInfo.step === 1 ? (
        <OneStep
          type={resetInfo.type}
          next={(token) =>
            setResetInfo((props) => ({ ...props, token, step: 2 }))
          }
        />
      ) : (
        <TwoStep token={resetInfo.token} />
      ),
    [resetInfo]
  );
  return (
    <div className={styles.formWrapper}>
      <h6>重置登录密码</h6>
      {resetInfo.step === 1 && (
        <div className={styles.switchBtn}>
          <div
            className={`${resetInfo.type === "MOBILE" ? styles.active : ""}`}
            onClick={() =>
              setResetInfo((props) => ({ ...props, type: "MOBILE" }))
            }
          >
            手机号码
          </div>
          <div
            className={`${resetInfo.type !== "MOBILE" ? styles.active : ""}`}
            onClick={() =>
              setResetInfo((props) => ({ ...props, type: "EMAIL" }))
            }
          >
            电子邮箱
          </div>
        </div>
      )}
      {form}
    </div>
  );
}
