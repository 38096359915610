import React, { useEffect, useMemo } from "react";
import { useLocation, Navigate, useParams } from "react-router-dom";
// core
import CX from "@/core";
// store
import Store from "@/store";
// compoents
import Layout from "@/view/components/Layout";
import TradeHeader from "./components/header";
import TradeQuotation from "./components/quotation";
import TradeChart from "./components/chart";
import TradePosition from "./components/position";
import DerivTrade from "./components/derivTrade";
import LeverTrade from "./components/leverTrade";

// style
import styles from "./index.module.scss";

export default function Trade() {
	const { id } = useParams();
	const { pathname } = useLocation();
	const isSimulate = Store.Quote.simulate;
	useEffect(() => {
		CX.Launcher.CFD.startData();
		CX.Launcher.CFD.startQuoteData(id);
		Store.Quote.setQuoteId(id);
		return () => {
			CX.Launcher.CFD.endData();
			CX.Launcher.CFD.quitQuoteData(id);
			Store.Quote.quoteInitial();
		};
	}, [id]);
	// 登入後啟動持倉監聽
	useEffect(() => {
		if (Store.User.isLogin) {
			Store.Position.start();
			!isSimulate && Store.Position.startLimit();
		}
		return () => {
			Store.Position.end();
			Store.Position.endLimit();
		};
	}, [isSimulate]);
	// Store.Position

	// 判斷交易品類
	const tradeType = useMemo(() => {
		if (pathname.includes("crypto")) {
			return "crypto";
		} else if (pathname.includes("futures")) {
			return "futures";
		} else if (pathname.includes("foreignE")) {
			return "foreignE";
		} else if (pathname.includes("stock")) {
			return "stock";
		} else {
			return "";
		}
	}, [pathname]);

	if (["/trade", "/trade/"].includes(pathname)) {
		return <Navigate to={"/trade/crypto/BTCUSDT"} />;
	}

	return (
		<Layout customClass={styles.tradeWrapper} isFooterHide>
			<TradeHeader />
			<div className={styles.tradeMain}>
				<div className={styles.tradeInfo}>
					<div className={styles.tradeTop}>
						<TradeQuotation tradeType={tradeType} />
						<TradeChart tradeType={tradeType} />
					</div>
					<TradePosition tradeType={tradeType} />
				</div>
				{tradeType === "futures" ? <DerivTrade /> : <LeverTrade tradeType={tradeType} />}
			</div>
		</Layout>
	);
}
