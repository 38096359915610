/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

// compoents
import Layout from "@/view/components/Layout";
import { CooperationIcon } from "@/view/components/Svg";
// store
import Store from "@/store";
// assets
import bg1 from "@assets/cooperation/proxy/bg01.png";
import bg2 from "@assets/cooperation/proxy/bg02.png";
import bg3 from "@assets/cooperation/proxy/bg03.png";

// style
import styles from "./index.module.scss";

const Proxy = (props) => {
	return (
		<Layout customClass={styles.proxyWrapper}>
			<section className={styles.bannerBox}>
				<img src={bg1} alt="" className={styles.img1} />
				<div className={styles.titleBox}>
					<div className={styles.title}>与可信赖的市场引领者同行</div>
					<a href={"https://wj.qq.com/s2/8297582/2ff4/"} target={"_blank"} className={styles.btn}>
						申请代理
					</a>
				</div>
			</section>
			<section className={styles.section}>
				<h5>
					<CooperationIcon img="proxy/whycooperate" />
				</h5>
				<p>提升您的盈利潜力，助您更加成功</p>
				<CooperationIcon img="proxy/cooperation" />
			</section>
			<section className={styles.section}>
				<h5>带给您客户的好处</h5>
				<p>
					<CooperationIcon img="proxy/kehuhaochu" />
				</p>
				<CooperationIcon img="proxy/description" />
			</section>
			<section className={`${styles.bannerBox} ${styles.box2}`}>
				<img src={bg2} className={styles.img2} alt="" />
				<div className={styles.titleBox2}>
					<h5>如何成为代理</h5>
					<p>成为代理即可享受丰厚佣金回报</p>
				</div>
				<div className={styles.stepBox}>
					<div className={styles.step}>
						<div>
							<CooperationIcon img="proxy/1" />
							<b>提交表格</b>
						</div>
						<div>
							<CooperationIcon img="proxy/2" />
							<b>专员跟进</b>
						</div>
						<div>
							<CooperationIcon img="proxy/3" />
							<b>成为代理</b>
						</div>
					</div>
					<a href={"https://wj.qq.com/s2/8297582/2ff4/"} target={"_blank"} className={styles.btn}>
						申请代理
					</a>
					{Store.User.isLogin && (
						<Link to="/account/inviteFriends" state={2} className={styles.checkList}>
							查看佣金记录
						</Link>
					)}
				</div>
			</section>
			<section className={styles.connect}>
				<img src={bg3} alt="" />
				<div className={styles.info}>
					<h5>联系我们 了解更多</h5>
					<p>您的邮件一般会在24小时内得到回复，如事宜紧急，请联系在线客服</p>
					<b>
						<CooperationIcon img="proxy/email" /> <span>jyt@jytmex.com</span>
					</b>
				</div>
			</section>
		</Layout>
	);
};
export default observer(Proxy);
