import { completeNum, formatDate, getPlatform } from "../utils";
export const COMMODITY_ZONE_HSHARE = "hstock";
const future = {
  "CL": {
      timezone: -6,
      dayLight: true,
      trade: {
          0: [["17:00", "23:59"]],
          1: [["17:00", "23:59"], ["00:00", "15:55"]],
          2: [["17:00", "23:59"], ["00:00", "15:55"]],
          3: [["17:00", "23:59"], ["00:00", "15:55"]],
          4: [["17:00", "23:59"], ["00:00", "15:55"]],
          5: [["00:00", "15:55"]]
      }
  },
  "HG": {
      timezone: -6,
      dayLight: true,
      trade: {
          0: [["17:00", "23:59"]],
          1: [["17:00", "23:59"], ["00:00", "15:55"]],
          2: [["17:00", "23:59"], ["00:00", "15:55"]],
          3: [["17:00", "23:59"], ["00:00", "15:55"]],
          4: [["17:00", "23:59"], ["00:00", "15:55"]],
          5: [["00:00", "15:55"]]
      }
  },
  "SI": {
      timezone: -6,
      dayLight: true,
      trade: {
          0: [["17:00", "23:59"]],
          1: [["17:00", "23:59"], ["00:00", "15:55"]],
          2: [["17:00", "23:59"], ["00:00", "15:55"]],
          3: [["17:00", "23:59"], ["00:00", "15:55"]],
          4: [["17:00", "23:59"], ["00:00", "15:55"]],
          5: [["00:00", "15:55"]]
      }
  },
  "GC": {
      timezone: -6,
      dayLight: true,
      trade: {
          0: [["17:00", "23:59"]],
          1: [["17:00", "23:59"], ["00:00", "15:55"]],
          2: [["17:00", "23:59"], ["00:00", "15:55"]],
          3: [["17:00", "23:59"], ["00:00", "15:55"]],
          4: [["17:00", "23:59"], ["00:00", "15:55"]],
          5: [["00:00", "15:55"]]
      }
  },
  "NG": {
      timezone: -6,
      dayLight: true,
      trade: {
          0: [["17:00", "23:59"]],
          1: [["17:00", "23:59"], ["00:00", "15:55"]],
          2: [["17:00", "23:59"], ["00:00", "15:55"]],
          3: [["17:00", "23:59"], ["00:00", "15:55"]],
          4: [["17:00", "23:59"], ["00:00", "15:55"]],
          5: [["00:00", "15:55"]]
      }
  },
  "CN": {
      timezone: 8,
      trade: {
          1: [["09:00", "16:25"], ["17:00", "23:59"]],
          2: [["00:00", "05:10"], ["09:00", "16:25"], ["17:00", "23:59"]],
          3: [["00:00", "05:10"], ["09:00", "16:25"], ["17:00", "23:59"]],
          4: [["00:00", "05:10"], ["09:00", "16:25"], ["17:00", "23:59"]],
          5: [["00:00", "05:10"], ["09:00", "16:25"], ["17:00", "23:59"]],
          // 6: [["00:00", "05:10"]],
      }
  },
  "YM": {
      timezone: -5,
      dayLight: true,
      trade: {
          0: [["18:00", "23:59"]],
          1: [["00:00", "16:14"], ["16:31", "17:00"], ["18:00", "23:59"]],
          2: [["00:00", "16:14"], ["16:31", "17:00"], ["18:00", "23:59"]],
          3: [["00:00", "16:14"], ["16:31", "17:00"], ["18:00", "23:59"]],
          4: [["00:00", "16:14"], ["16:31", "17:00"], ["18:00", "23:59"]],
          5: [["00:00", "16:14"], ["16:31", "17:00"]]
      }
  },
  "NQ": {
      timezone: -5,
      dayLight: true,
      trade: {
          0: [["18:00", "23:59"]],
          1: [["00:00", "16:14"], ["16:31", "17:00"], ["18:00", "23:59"]],
          2: [["00:00", "16:14"], ["16:31", "17:00"], ["18:00", "23:59"]],
          3: [["00:00", "16:14"], ["16:31", "17:00"], ["18:00", "23:59"]],
          4: [["00:00", "16:14"], ["16:31", "17:00"], ["18:00", "23:59"]],
          5: [["00:00", "16:14"], ["16:31", "17:00"]]
      }
  },
  "HSI": {
      timezone: 8,
      trade: {
          1: [["09:15", "12:00"], ["13:00", "16:30"], ["17:15", "23:59"]],
          2: [["00:00", "03:00"], ["09:15", "12:00"], ["13:00", "16:30"], ["17:15", "23:59"]],
          3: [["00:00", "03:00"], ["09:15", "12:00"], ["13:00", "16:30"], ["17:15", "23:59"]],
          4: [["00:00", "03:00"], ["09:15", "12:00"], ["13:00", "16:30"], ["17:15", "23:59"]],
          5: [["00:00", "03:00"], ["09:15", "12:00"], ["13:00", "16:30"], ["17:15", "23:59"]],
          // 6: [["00:00", "03:00"]],
      }
  },
  "NK": {
      timezone: 9,
      trade: {
          1: [["08:30", "12:59"], ["13:00", "15:30"], ["15:55", "23:59"]],
          2: [["00:00", "05:45"], ["08:30", "12:59"], ["13:00", "15:30"], ["15:55", "23:59"]],
          3: [["00:00", "05:45"], ["08:30", "12:59"], ["13:00", "15:30"], ["15:55", "23:59"]],
          4: [["00:00", "05:45"], ["08:30", "12:59"], ["13:00", "15:30"], ["15:55", "23:59"]],
          5: [["00:00", "05:45"], ["08:30", "12:59"], ["13:00", "15:30"], ["15:55", "23:59"]],
          // 6: [["00:00", "05:45"],],
      }
  },
  "DAX": {
      timezone: 1,
      dayLight: true,
      trade: {
          1: [["08:00", "21:55"]],
          2: [["08:00", "21:55"]],
          3: [["08:00", "21:55"]],
          4: [["08:00", "21:55"]],
          5: [["08:00", "21:55"]],
      }
  },
  "AUDUSD": {
      timezone: 8,
      trade: {
          1: [["00:00", "05:55"], ["06:00", "23:59"]],
          2: [["00:00", "05:55"], ["06:00", "23:59"]],
          3: [["00:00", "05:55"], ["06:00", "23:59"]],
          4: [["00:00", "05:55"], ["06:00", "23:59"]],
          5: [["00:00", "05:55"], ["06:00", "23:59"]]
      }
  },
  "EURUSD": {
      timezone: 8,
      trade: {
          1: [["00:00", "05:55"], ["06:00", "23:59"]],
          2: [["00:00", "05:55"], ["06:00", "23:59"]],
          3: [["00:00", "05:55"], ["06:00", "23:59"]],
          4: [["00:00", "05:55"], ["06:00", "23:59"]],
          5: [["00:00", "05:55"], ["06:00", "23:59"]]
      }
  },
  "GBPUSD": {
      timezone: 8,
      trade: {
          1: [["00:00", "05:55"], ["06:00", "23:59"]],
          2: [["00:00", "05:55"], ["06:00", "23:59"]],
          3: [["00:00", "05:55"], ["06:00", "23:59"]],
          4: [["00:00", "05:55"], ["06:00", "23:59"]],
          5: [["00:00", "05:55"], ["06:00", "23:59"]]
      }
  },
  "USDCAD": {
      timezone: 8,
      trade: {
          1: [["00:00", "05:55"], ["06:00", "23:59"]],
          2: [["00:00", "05:55"], ["06:00", "23:59"]],
          3: [["00:00", "05:55"], ["06:00", "23:59"]],
          4: [["00:00", "05:55"], ["06:00", "23:59"]],
          5: [["00:00", "05:55"], ["06:00", "23:59"]]
      }
  },
  "USDJPY": {
      timezone: 8,
      trade: {
          1: [["00:00", "05:55"], ["06:00", "23:59"]],
          2: [["00:00", "05:55"], ["06:00", "23:59"]],
          3: [["00:00", "05:55"], ["06:00", "23:59"]],
          4: [["00:00", "05:55"], ["06:00", "23:59"]],
          5: [["00:00", "05:55"], ["06:00", "23:59"]]
      }
  },
  "USDCHF": {
      timezone: 8,
      trade: {
          1: [["00:00", "05:55"], ["06:00", "23:59"]],
          2: [["00:00", "05:55"], ["06:00", "23:59"]],
          3: [["00:00", "05:55"], ["06:00", "23:59"]],
          4: [["00:00", "05:55"], ["06:00", "23:59"]],
          5: [["00:00", "05:55"], ["06:00", "23:59"]]
      }
  },
  "NZDUSD": {
      timezone: 8,
      trade: {
          1: [["00:00", "05:55"], ["06:00", "23:59"]],
          2: [["00:00", "05:55"], ["06:00", "23:59"]],
          3: [["00:00", "05:55"], ["06:00", "23:59"]],
          4: [["00:00", "05:55"], ["06:00", "23:59"]],
          5: [["00:00", "05:55"], ["06:00", "23:59"]]
      }
  },
  "ZF": {
      timezone: 8,
      trade: {
          1: [["00:00", "04:55"], ["06:00", "23:59"]],
          2: [["00:00", "04:55"], ["06:00", "23:59"]],
          3: [["00:00", "04:55"], ["06:00", "23:59"]],
          4: [["00:00", "04:55"], ["06:00", "23:59"]],
          5: [["00:00", "04:55"], ["06:00", "23:59"]]
      }
  },
  "UZN": {
      timezone: 8,
      trade: {
          1: [["00:00", "04:55"], ["06:00", "23:59"]],
          2: [["00:00", "04:55"], ["06:00", "23:59"]],
          3: [["00:00", "04:55"], ["06:00", "23:59"]],
          4: [["00:00", "04:55"], ["06:00", "23:59"]],
          5: [["00:00", "04:55"], ["06:00", "23:59"]]
      }
  },
  "ZB": {
      timezone: 8,
      trade: {
          1: [["00:00", "04:55"], ["06:00", "23:59"]],
          2: [["00:00", "04:55"], ["06:00", "23:59"]],
          3: [["00:00", "04:55"], ["06:00", "23:59"]],
          4: [["00:00", "04:55"], ["06:00", "23:59"]],
          5: [["00:00", "04:55"], ["06:00", "23:59"]]
      }
  },
  "GBM": {
      timezone: 8,
      trade: {
          1: [["00:00", "03:55"], ["08:15", "23:59"]],
          2: [["00:00", "03:55"], ["08:15", "23:59"]],
          3: [["00:00", "03:55"], ["08:15", "23:59"]],
          4: [["00:00", "03:55"], ["08:15", "23:59"]],
          5: [["00:00", "03:55"], ["08:15", "23:59"]]
      }
  },
  "GBL": {
      timezone: 8,
      trade: {
          1: [["00:00", "03:55"], ["08:15", "23:59"]],
          2: [["00:00", "03:55"], ["08:15", "23:59"]],
          3: [["00:00", "03:55"], ["08:15", "23:59"]],
          4: [["00:00", "03:55"], ["08:15", "23:59"]],
          5: [["00:00", "03:55"], ["08:15", "23:59"]]
      }
  },
  "GBX": {
      timezone: 8,
      trade: {
          1: [["00:00", "03:55"], ["08:15", "23:59"]],
          2: [["00:00", "03:55"], ["08:15", "23:59"]],
          3: [["00:00", "03:55"], ["08:15", "23:59"]],
          4: [["00:00", "03:55"], ["08:15", "23:59"]],
          5: [["00:00", "03:55"], ["08:15", "23:59"]]
      }
  },
};

const ashare = {
  timezone: 8,
  trade: {
      1: [["09:30", "12:00"], ["13:00", "15:50"]],
      2: [["09:30", "12:00"], ["13:00", "15:50"]],
      3: [["09:30", "12:00"], ["13:00", "15:50"]],
      4: [["09:30", "12:00"], ["13:00", "15:50"]],
      5: [["09:30", "12:00"], ["13:00", "15:50"]],
  }
}


export default {
  _openTime: {},
  _tradeTime: {},
  _total: [],
  _all: [],
  isTrading(contract) {
      if (!contract)
          return false;

      if (this._isHoliday(contract)) {
          return false;
      }

      let now = new Date();
      if (now.getDay() === 0) {
          return false;
      }

      let nowStr = formatDate("h:i", {date: now});

      if (now.getDay() === 6) {
          let nnClose = removeSecond(contract.niteCloseTime);
          if (nnClose < "12:00" && nowStr < nnClose) {
              return false;
          }
      }

      if (!this._tradeTime[contract.code]) {
          this._tradeTime[contract.code] = this._getAllPoint_old(contract, true);
      }

      return this._tradeTime[contract.code].indexOf(nowStr) !== -1;

  },
  isOpening(contract) {
      if (!contract)
          return false;

          const id = contract.code;
      
      if (!!contract.coins && !future[id] && contract.zone !== COMMODITY_ZONE_HSHARE)
          return true;

      if (this._isHoliday(contract)) {
          return false;
      }

      let detail;

      if(contract.zone === COMMODITY_ZONE_HSHARE){
          detail = ashare
      }else{
          detail = future[id];
      }

      if (detail === undefined) {
          return this._backup(contract)
      }

      const now = new Date();
      let nowStr = formatDate("h:i", {date: now});
      const day = this._timezone_getDay_old();
      if (day === 0) {
          return false;
      }

      if (day === 6) {
          let nnClose = removeSecond(contract.niteCloseTime);
          nnClose = this._timezone_old(nnClose);
          if (nnClose > this._timezone_old("12:00") || nowStr > nnClose) {
              return false;
          }
      }

      if (!this._openTime[id]) {
          this._openTime[id] = {};
      }
      if (!this._openTime[id][day]) {
          this._openTime[id][day] = this._getAllPoint_old(contract, false);
      }
      return this._openTime[id][day].indexOf(nowStr) !== -1;
  },

  _backup(contract) {

      let now = new Date();
      if (this._timezone_getDay_old() === 0) {
          return false;
      }

      let nowStr = formatDate("h:i", {date: now});

      if (this._timezone_getDay_old() === 6) {
          let nnClose = removeSecond(contract.niteCloseTime);
          nnClose = this._timezone_old(nnClose);
          if (nnClose > this._timezone_old("12:00") || nowStr > nnClose) {
              return false;
          }
      }

      let amStart = removeSecond(contract.amOpenTime);
      let pmStart = removeSecond(contract.pmOpenTime);
      amStart = this._timezone_old(amStart);
      pmStart = this._timezone_old(pmStart);

      if (this._timezone_getDay_old() === 1) {
          if (nowStr < amStart)
              return false;
          if (amStart === this._timezone_old("00:00") && nowStr < pmStart)
              return false;
      }

      if (!this._openTime[contract.code]) {
          this._openTime[contract.code] = this._getAllPoint_old(contract, false);
      }

      return this._openTime[contract.code].indexOf(nowStr) !== -1;
  },

  _getAllPoint(time) {
      let total = [], all = [];

      let hour, minute, timeStr = "";
      if (this._total.length === 0) {
          for (let i = 0; i < 1440; i++) {
              hour = 0;
              minute = i % 60;
              if (i >= 60) {
                  hour = (i - minute).div(60);
              }
              timeStr = `${completeNum(hour)}:${completeNum(minute)}`;
              total.push(timeStr);
          }
          this._total = [].concat(total)
      } else {
          total = [].concat(this._total)
      }

      time.forEach((e) => {
          const start = total.indexOf(e[0]);
          const end = total.indexOf(e[1]);
          all = all.concat(total.slice(start, end + 1))
      })

      return all.unique();
  },

  _getAllPoint_old(contract, isTrade) {
      let amOpen, amClose, pmOpen, pmClose, nnOpen, nnClose, total = [], all = [];
      if (isTrade) {
          amOpen = removeSecond(contract.amTradeTime);
          amClose = removeSecond(contract.amClearingTime);
          pmOpen = removeSecond(contract.pmTradeTime);
          pmClose = removeSecond(contract.pmClearingTime);
          nnOpen = removeSecond(contract.niteTradeTime);
          nnClose = removeSecond(contract.niteClearingTime);
      } else {
          amOpen = removeSecond(contract.amOpenTime);
          amClose = removeSecond(contract.amCloseTime);
          pmOpen = removeSecond(contract.pmOpenTime);
          pmClose = removeSecond(contract.pmCloseTime);
          nnOpen = removeSecond(contract.niteOpenTime);
          nnClose = removeSecond(contract.niteCloseTime);
      }
      amOpen = this._timezone_old(amOpen);
      amClose = this._timezone_old(amClose);
      pmOpen = this._timezone_old(pmOpen);
      pmClose = this._timezone_old(pmClose);
      nnOpen = this._timezone_old(nnOpen);
      nnClose = this._timezone_old(nnClose);

      let start, end, hour, minute, timeStr = "";
      if (this._total.length === 0) {
          for (let i = 0; i < 1440; i++) {
              hour = 0;
              minute = i % 60;
              if (i >= 60) {
                  hour = (i - minute).div(60);
              }
              timeStr = `${completeNum(hour)}:${completeNum(minute)}`;
              total.push(timeStr);
          }
          this._total = [].concat(total)
      } else {
          total = [].concat(this._total)
      }

      if (!!amOpen && !!amClose) {
          start = total.indexOf(amOpen);
          end = total.indexOf(amClose);
          if (start > end) {
              all = all.concat(total.slice(start, total.length));
              all = all.concat(total.slice(0, end + 1));
          } else {
              all = all.concat(total.slice(start, end + 1));
          }
      }
      if (!!pmOpen && !!pmClose) {
          start = total.indexOf(pmOpen);
          end = total.indexOf(pmClose)
          if (start > end) {
              all = all.concat(total.slice(start, total.length));
              all = all.concat(total.slice(0, end + 1));
          } else {
              all = all.concat(total.slice(start, end + 1));
          }
      }
      if (!!nnOpen && !!nnClose) {
          start = total.indexOf(nnOpen);
          end = total.indexOf(nnClose);
          if (start > end) {
              all = all.concat(total.slice(start, total.length));
              all = all.concat(total.slice(0, end + 1));
          } else {
              all = all.concat(total.slice(start, end + 1));
          }
      }
      return all.unique();
  },

  _timezone_old(time) {
      if (!time) return time;
      const jet = (480 + new Date().getTimezoneOffset()) / 60;
      if (jet === 0) return time;
      let part = null;
      if (time.indexOf(":") !== -1) {
          time = time.split(":");
          part = time[1];
          time = time[0];
      }
      time -= jet;
      if (time < 0) time += 24;
      if (time > 23) time -= 24;
      if (part !== null) time = `${time < 10 ? "0" : ""}${time}:${part}`;
      return time;
  },

  _timezone_getDay_old() {
      const n = new Date();
      let day = n.getDay();
      const timezone = 8;
      const offset = n.getTimezoneOffset() / 60 * -1;
      const jet = timezone - offset;
      if (jet === 0) return day;
      if (n.getHours() + jet < 0) day -= 1;
      if (n.getHours() + jet > 23) day += 1;
      if (day > 6) day = 0;
      if (day < 0) day = 6;
      return day;
  },

  _timezone(d, n) {
      let timezone = d.timezone;
      const dayLight = d.dayLight;
      if (!this._isDaylight() && dayLight) {
          timezone++;
      }
      const offset = n.getTimezoneOffset() / 60 * -1;
      const jet = timezone - offset;
      if (jet === 0) return n;
      n.setHours(n.getHours() + jet);
      return n;
  },

  _timezone_getDay(d, n) {
      let day = n.getDay();
      let timezone = d.timezone;
      const dayLight = d.dayLight;
      if (!this._isDaylight() && dayLight) {
          timezone++;
      }
      const offset = n.getTimezoneOffset() / 60 * -1;
      const jet = timezone - offset;
      if (jet === 0) return day;
      if (n.getHours() + jet < 0) day -= 1;
      if (n.getHours() + jet > 23) day += 1;
      if (day > 6) day = 0;
      if (day < 0) day = 6;
      return day;
  },

  _isDaylight() {
      const d1 = new Date(2009, 0, 1);
      const d2 = new Date(2009, 6, 1);
      return d1.getTimezoneOffset() !== d2.getTimezoneOffset()
  },

  _isHoliday(contract) {
      let result = null, holiday = contract.holiday, data = new Date();
      let timeScope, arr, start, end;
      if (!!holiday) {
          holiday = holiday.split(";");
          result = holiday.find(function (e) {
              timeScope = e.trim();
              if (timeScope !== "") {
                  arr = timeScope.split(",");
                  start = new Date(timeProcess(arr[0].trim()));
                  end = new Date(timeProcess(arr[1].trim()));
                  return data.getTime() > start.getTime() && data.getTime() < end.getTime()
              }
              return false;
          });
      }
      if (result === undefined)
          result = false;

      if (result === null)
          result = false;

      if (result !== false)
          result = result.trim().split(",").shift();

      return result;
  }
}

function timeProcess(str) {
  if (getPlatform() === "ios") {
      return str.replace(new RegExp("-", "gm"), "/")
  }
  return str
}

function removeSecond(str) {
  let val = str.valueOf();
  let l = val.split(":");
  let t = null;
  if (l.length === 3) {
      t = `${l[0]}:${l[1]}`;
  } else {
      t = val;
  }
  return t;
}

