import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate, Redirect } from "react-router-dom";
import { notification, message } from "antd";
import { TEST } from "@/utils/test";
// store
import Store from "@/store";
// utils
import { isEmpty, formatDate, mobileMask, emailMask } from "@/utils";
import { statusText } from "@/utils/common";
// components
import SimpleInput from "@/view/components/SimpleInput";
import SimpleSelect from "@/view/components/SimpleSelect2";
import { customModal } from "@/view/components/Modal";
import WithdrawPwd from "@/view/account/userCenter/withdrawPwd";
import ForgotWithdraw from "@/view/account/userCenter/forgotWithdraw";
// lib
import EVENT from "@/library/api";
// style
import styles from "./index.module.scss";

const Withdraw = () => {
  const navigate = useNavigate();
  const { money: balance } = Store.User.assets;
  const { pw_w } = Store.User.accountInfo;
  const [bankList, setBankList] = useState([]);
  const [activeBank, setActiveBank] = useState({});
  const [money, setMoney] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [fundsList, setFundsList] = useState([]);

  useEffect(() => {
    EVENT.Account._keepUpdateDetail().then(({ user }) => {
      if (!user.name) {
        message.warning("请先实名认证");
        return navigate("/account/userCenter");
      }
      if (user.bankCardCount === 0) {
        message.warning("请先绑定提款账户");
        return navigate("/account/userCenter");
      }
      Store.User.updateAssets();
      getWithdrawRecord();
      getAllBankCard();
    });
  }, []);

  // useEffect(() => {
  //   // if (!name) {
  //   //   message.warning("请先实名认证");
  //   //   return navigate("/account/userCenter");
  //   // }
  //   if (bankCardCount === 0) {
  //     message.warning("请先绑定银行卡");
  //     return navigate("/account/userCenter");
  //   }
  //   Store.User.updateAssets();
  //   getWithdrawRecord();
  //   getAllBankCard();
  // }, [Store.User.accountInfo]);

  const getAllBankCard = () => {
    EVENT.Account.getAllBankCard()
      .then(({ bankCards }) => {
        bankCards.forEach((item) => {
          let { bank, cardNumber } = item;
          item.bankWithNum =
            bank + "(" + getLastNumbers(bank, cardNumber) + ")";
        });
        console.log(bankCards, bankCards[0]);
        setBankList(bankCards);
        setActiveBank(bankCards[0]);
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };

  // 银行卡后四位
  const getLastNumbers = (name, number) => {
    if (number !== "") {
      return name === "支付宝"
        ? getContentMask(number)
        : number.substring(number.length - 4);
    } else {
      return "";
    }
  };
  // 支付宝号码隐藏
  const getContentMask = (value) => {
    if (TEST.PHONE.test(value)) return mobileMask(value);
    return emailMask(value);
  };

  const getWithdrawRecord = () => {
    EVENT.History.depositWithdraw({ type: 200, currencyType: 0 })
      .then(({ data }) => {
        setFundsList(data.slice(0, 5));
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };
  const isValid = useMemo(
    () => !isEmpty(activeBank) && money && passwordValid,
    [activeBank, money, passwordValid]
  );
  const withdraw = () => {
    if (!money) {
      return message.warning("请输入金额");
    }
    if (!isValid) {
      return;
    }
    if (!pw_w) {
      return message.warning("请先设置提款密码");
    }
    if (money < 100) {
      return message.warning("单笔最低提款100元");
    }
    EVENT.Account.withdraw({
      money,
      password,
      bankCard: activeBank.id,
    })
      .then(() => {
        notification.success({
          message: "提款成功",
          onClose: () => {
            Store.Wallet.updateWallet();
          },
        });
        navigate("/account/userCenter");
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };
  return (
    <div className={styles.withdraw}>
      <div className={styles.withdrawTitle}>
        <h5>提款</h5>
      </div>
      <div className={styles.withdrawMain}>
        <div className={styles.switchBtn}>
          <div>法币提现</div>
        </div>
        <div className={styles.withdrawInfo}>
          <div>
            {!isEmpty(activeBank) && (
              <SimpleSelect
                label={"提款账户"}
                link={"提款账户管理"}
                goTo={"/account/bankcard"}
                options={bankList}
                onChange={(val) => {
                  console.log(val);
                  setActiveBank(
                    bankList.find((item) => item.bankWithNum === val)
                  );
                }}
                value={activeBank.bankWithNum}
                type={"bank"}
              />
            )}
            <SimpleInput
              label={"提现金额"}
              link={
                <span>
                  可用余额:<b>{balance}</b>CNY
                </span>
              }
              placeholder={"请输入提款金额"}
              type={"number"}
              value={money}
              onChange={(val) => {
                if (val > balance) {
                  setMoney(parseInt(balance));
                } else if (val === 0) {
                  setMoney("");
                } else {
                  setMoney(val);
                }
              }}
            />
            <SimpleInput
              label={"资金密码"}
              placeholder={"请输入提款密码"}
              type={"password"}
              notice={"password"}
              format={"SET_PASSWORD"}
              value={password}
              onChange={(e, format) => {
                setPassword(e);
                setPasswordValid(format);
              }}
            />
            <div className={styles.forgot}>
              <div
                onClick={() => {
                  customModal(
                    pw_w ? (
                      <ForgotWithdraw accountInfo={Store.User.accountInfo} />
                    ) : (
                      <WithdrawPwd
                        accountInfo={Store.User.accountInfo}
                        navigate={navigate}
                      />
                    )
                  );
                }}
              >
                {pw_w ? "忘记提款密码" : "设置提款密码"}
              </div>
            </div>
            <div
              className={`confirm_btn ${styles.confirmBtn} ${
                !isValid ? "error" : ""
              }`}
              onClick={withdraw}
            >
              提款
            </div>
          </div>
          <div className={styles.tips}>
            <div>
              <h6>温馨提示</h6>
              <p>* 除周六外 100元-299元提款，需联系客服申请</p>
              <p>* 单笔最低提款100元，最高无上限，每日限提2次</p>
              <p>* 处理提款时间为：每日9:00-21:00，到账时间：以实际银行为准</p>
              <p>* 如充值后末交易提款，则需收取5%手续费，手续费收取15元起</p>
              <p>* 操作过程中如遇任何疑问，请联系客服</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.withdrawRecord}>
        <div className={styles.record}>
          <h5>近期提款记录</h5>
          <Link to="/account/fundsDetail" state={4}>
            查看更多 》
          </Link>
        </div>
        <ul>
          <li className={styles.header}>
            <div>时间</div>
            <div>提款账户</div>
            <div>数量</div>
            <div>ID</div>
            <div className={styles.status}>操作/状态</div>
          </li>
          {fundsList.map((item) => (
            <li key={item.id}>
              <div>{formatDate("m-d h:i:s", { date: item.createTime })}</div>
              <div>
                {item.chain && item.chain === "支付宝"
                  ? getContentMask(item.bankCard)
                  : item.chain +
                    " (" +
                    item.bankCard.substring(item.bankCard.length - 4) +
                    ")"}
              </div>
              <div>{item.money}</div>
              <div>{item.id}</div>
              <div className={styles.status}>{statusText(item.status)}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default observer(Withdraw);
