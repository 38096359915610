import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";

// lib
import { RESTRICT } from "@/library/standard";
import { safeValue } from "@/library/safety";
import { TEST } from "@/library/test";
// utils
import { postList } from "@/utils/common";
import { getPlatform } from "@/utils";
// components
import { UserIcon } from "@/view/components/Svg";
import usePrevious from "@/view/hooks/usePrevious";

// style
import styles from "./index.module.scss";

const noticeAlertMap = {
	tel: "手机格式错误",
	email: "邮箱格式错误",
	password: "6-16位由字母、数字和符号组成的密码，不能为纯数字或字母",
	msgCode: "验证码格式不正确",
	cn_name: "姓名格式不正确",
	ID: "身份证号不正确",
	withdrawpassword: "6-16位由字母、数字组成的密码，不能为纯数字或字母",
};

// email
const EmailList = ({ val = "", updateVal }) => {
	if (!val.includes("@")) {
		return (
			<ul className={styles.emailAddress}>
				{postList.map((key, index) => (
					<li key={index} onClick={() => updateVal(`${val}@${key}`)}>
						{val}@{key}
					</li>
				))}
			</ul>
		);
	} else {
		const [v, e] = val.split("@");
		let list = postList;
		if (!!e) {
			list = postList.filter((o) => o.indexOf(e) !== -1);
		}
		return (
			<ul className={styles.emailAddress}>
				{list.map((key, index) => (
					<li key={index} onClick={() => updateVal(`${v}@${key}`)}>
						{v}@{key}
					</li>
				))}
			</ul>
		);
	}
};

export default function Input(props) {
	let {
		label,
		placeholder,
		type,
		link,
		onLinkClick,
		goTo,
		maxLength,
		value,
		free,
		onChange,
		onFocus,
		onBlur,
		notice,
		force,
		min,
		empty,
		digit,
		disable,
		initialValue,
		customClass = "",
	} = props;
	const [isShow, setIsShow] = useState(false);
	const [inputVal, setInputVal] = useState("");
	const [isError, setIsError] = useState(false);
	const [isFocus, setIsFocus] = useState(false);
	const timeoutRef = useRef();
	const timerRef = useRef();
	const prevFocus = usePrevious(isFocus);
	const prevValue = usePrevious(value);
	useEffect(() => {
		return () => {
			clearTimeout(timeoutRef.current);
			timerRef.current = null;
		};
	}, []);
	useEffect(() => {
		if (!value && prevValue) {
			setInputVal("");
		}
	}, [value, prevValue]);

	const typeExact = useMemo(() => {
		if ((type === "number" && getPlatform() === "pc") || type === "email") {
			return "text";
		} else if (type === "password" && isShow) {
			return "text";
		} else {
			return type;
		}
	}, [type, isShow]);
	const inputLen = useMemo(() => {
		if (!!maxLength) {
			return maxLength;
		}
		switch (type) {
			case "verify":
			case "verifyCode":
				return 6;
			case "tel":
				return 11;
			case "nickname":
			case "password":
				return 16;
			case "id":
				return 18;
			case "card":
				return 19;
			case "email":
				return 50;
			default:
				return 10;
		}
	}, [type, maxLength]);
	const val = useMemo(() => (value ? value : inputVal), [inputVal, value]);

	const handleChange = (e) => {
		const { simple } = props;
		let t, pass;
		if (type === "number") {
			t = RESTRICT.float(e.currentTarget.value, digit);
		} else {
			t = e.currentTarget.value;
		}
		// if (min && t < min) {
		// 	debounce(!!t);
		// }
		if (simple) {
			const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？% _ ]");
			if (pattern.test(t)) {
				return;
			}
		}
		setInputVal(t);
		if (onChange && (!pass || free)) {
			const result = test(t);
			if (type === "number" && t !== 0 && t < 0.000001) {
				let len = t.toString().split("-")[1];
				if (len !== undefined) {
					t = t.toFixed(len);
				}
			}
			onChange(t, result);
		}
	};
	const handleInput = (e) => {
		if (type === "number") {
			e.currentTarget.value = e.currentTarget.value.replace(/[^\d.]/gi, "");
		}
		if (type === "tel" || type === "verify" || type === "verifyCode") {
			e.currentTarget.value = e.currentTarget.value.replace(/[^\d]/g, "");
		}
		if (type === "password") {
			e.currentTarget.value = e.currentTarget.value.replace(/[^\w\.!@#$%^&*?\/]/gi, "");
		}
		if (type === "nickname" || type === "email") {
			e.currentTarget.value = e.currentTarget.value.replace(/\s+/g, "");
		}
	};

	const test = useCallback(
		(v) => {
			const { format, compare } = props;
			let result = false;
			if (typeof format === "string" && TEST[format] !== undefined) {
				result = TEST[format].test(v);
			} else if (typeof format === "function") {
				result = format(v);
			}
			if (type === "password" && safeValue(compare, true) && v !== compare) {
				result = false;
			}
			return result;
		},
		[props, type]
	);
	// TODO:
	const debounce = (run = true) => {
		clearTimeout(timeoutRef.current);
		if (!run) return;
		timeoutRef.current = setTimeout(() => {
			// let val = this.limit(this.state.val);
			// if (val !== this.state.val) {
			// 	this.setState({ val });
			// 	if (this.props.onChange) {
			// 		this.props.onChange(val);
			// 	}
			// }
		}, 2000);
	};
	const verify = useCallback(() => {
		let value = inputVal;
		if (props.value && free) {
			value = props.value;
		}
		if (!value) {
			return setIsError(false);
		}
		const { format } = props;
		if (format) {
			let result;
			if (typeof format === "string" && TEST[format] !== undefined) {
				result = !TEST[format].test(value);
			} else if (typeof format === "function") {
				result = !format(value);
			}
			// if (type === "password" && safeValue(val, true) && value !== val) {
			// 	this.setState({ compare: true });
			// } else {
			// 	this.setState({ compare: false });
			// }
			setIsError(result);
		}
	}, [free, inputVal, props]);

	useEffect(() => {
		if (prevFocus && !isFocus) {
			verify();
		}
	}, [prevFocus, isFocus, verify]);
	useEffect(() => {
		if (!inputVal) {
			setIsError(false);
		}
		if ((type === "password" || type === "nickname") && force && inputVal.length > 3) {
			verify();
		}
	}, [type, force, inputVal, verify]);
	useEffect(() => {
		if (safeValue(initialValue, true)) {
			if (onChange) {
				const result = test(initialValue);
				onChange(initialValue, result);
			}
		}
	}, [initialValue, onChange, test]);

	return (
		<div className={`${styles.inputWrapper} ${customClass}`}>
			<div className={styles.title}>
				<label>{label}</label>
				{!!link && (
					<div
						className={styles.link}
						onClick={() => {
							if (goTo) {
								window.location.href = goTo;
							} else {
								onLinkClick();
							}
						}}
					>
						{link}
					</div>
				)}
			</div>
			<div className={styles.inputBox}>
				<input
					type={typeExact}
					placeholder={placeholder}
					value={val}
					maxLength={inputLen}
					onChange={(e) => {
						handleChange(e);
					}}
					onInput={(e) => {
						handleInput(e);
					}}
					onFocus={() => {
						setIsFocus(true);
						if (onFocus) onFocus();
					}}
					onBlur={() => {
						if (onBlur) onBlur();
						setTimeout(() => {
							setIsFocus(false);
						}, 100);
					}}
					disabled={disable}
				/>
				{type === "password" && (
					<div className={styles.eye} onClick={() => setIsShow((prevState) => !prevState)}>
						<UserIcon img={isShow ? "openEyes" : "closeEyes"} />
					</div>
				)}
				{type === "email" && isFocus && inputVal && (
					<EmailList
						val={inputVal}
						updateVal={(v) => {
							setInputVal(v);
							if (onChange) {
								const result = test(v);
								onChange(v, result);
							}
						}}
					/>
				)}
			</div>
			{isError && <span className={styles.error}>{noticeAlertMap[notice]}</span>}
		</div>
	);
}
