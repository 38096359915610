/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { Link } from "react-router-dom";

// lib
import { RESTRICT } from "@/library/standard";
import EVENT from "@/library/api";
// util
import { isEmpty, formatDate } from "@/utils";
// compoents
import Layout from "@/view/components/Layout";
import Copy from "@/view/components/Copy";
// store
import Store from "@/store";
// assets
import bg from "@assets/cooperation/invite/bg.png";
import step1 from "@assets/cooperation/invite/1.png";
import step2 from "@assets/cooperation/invite/2.png";
import step3 from "@assets/cooperation/invite/3.png";
import level1 from "@assets/cooperation/invite/level01.png";
import level2 from "@assets/cooperation/invite/level02.png";
import level3 from "@assets/cooperation/invite/level03.png";
import level4 from "@assets/cooperation/invite/level04.png";
import level5 from "@assets/cooperation/invite/level05.png";
import funds from "@assets/common/funds_light.png";

// style
import styles from "./index.module.scss";

const Invite = (props) => {
	const [data, setData] = useState([]);
	const [info, setInfo] = useState({});

	const [subCount, setSubCount] = useState(0);

	useEffect(() => {
		if (Store.User.isLogin) {
			getInfo();
		}
	}, [Store.User.isLogin]);

	const refer = useMemo(() => Store.User.accountInfo?.refer || "", []);
	const commRatio = useMemo(() => Store.User.accountInfo?.commRatio || 0, []);
	const getInfo = async () => {
		try {
			const commissionRes = await EVENT.Account.updateCommission({ page: 1, biz: 1, rows: 10 });
			if (!isEmpty(commissionRes.data)) {
				setData(commissionRes.data);
			}
			const promotionRes = await EVENT.Account.updatePromotionInfo();
			if (!isEmpty(promotionRes.union)) {
				setInfo(promotionRes.union);
			}
			const agentStatRes = await EVENT.Account.updateAgentStat("CNY");
			setSubCount(agentStatRes.data.subCount);
		} catch ({ message }) {
			notification.error({ message, duration: 2 });
		}
	};
	const getLevel = (ratio) => {
		let o = ratio * 100;
		if (o !== 0) {
			if (o >= 50) {
				return "钻石";
			}
			if (o >= 30 && o <= 49) {
				return "铂金";
			}
			if (o >= 25 && o <= 29) {
				return "黄金";
			}
			if (o >= 20 && o <= 24) {
				return "白银";
			}
			if (o >= 5 && o <= 19) {
				return "青铜";
			}
		}
	};
	const getLevelImg = (ratio) => {
		let o = ratio * 100;
		if (o !== 0) {
			if (o >= 50) {
				return level5;
			}
			if (o >= 30 && o <= 49) {
				return level4;
			}
			if (o >= 25 && o <= 29) {
				return level3;
			}
			if (o >= 20 && o <= 24) {
				return level2;
			}
			if (o >= 5 && o <= 19) {
				return level1;
			}
		}
	};

	return (
		<Layout customClass={styles.inviteWrapper}>
			<section className={styles.bannerBox}>
				<img src={bg} alt="" />
				<div>
					<h5>邀请好友共同赚钱</h5>
					<p>
						最高可获
						<b>50%</b>
						交易手续费返佣
					</p>
				</div>
			</section>
			<main>
				<div className={styles.section}>
					<h6>如何0成本致富</h6>
					<div className={styles.stepBox}>
						<div>
							<img src={step1} alt="" />
							<b>生成专属推荐链接</b>
							<p>设置返现比例，生成专属链接</p>
						</div>
						<div>
							<img src={step2} alt="" />
							<b>邀请好友</b>
							<p>分享推荐链接或二维码给您的好友</p>
						</div>
						<div>
							<img src={step3} alt="" />
							<b>共同致富</b>
							<p>当您的好友完成交易，最高可获得50%实时返佣</p>
						</div>
					</div>
				</div>
				{Store.User.isLogin && (
					<>
						<div className={styles.inviteBox}>
							<div className={styles.left}>
								<div className={styles.title}>
									<div>立即邀请</div>
									<p>复制您专属的邀请码或邀请链接给朋友，也可将邀请二维码截屏分享或面对面分享给朋友</p>
								</div>
								<div className={styles.inviteLink}>
									<label>您的专属邀请码：</label>
									<div className={styles.codeBox}>
										<div className={styles.link}>{refer}</div>
										<Copy title="复制邀请码" className={styles.copy} text={refer} />
									</div>
								</div>
								<div className={styles.inviteLink}>
									<label>您的邀请链接:</label>
									<div className={styles.codeBox}>
										<div className={styles.link}>
											{window.location.origin}/?ru={refer}
										</div>
										<div />
									</div>
								</div>
								<Copy title="复制链接" className={styles.copyLink} text={window.location.origin + "/?ru=" + refer} />
							</div>
						</div>
						<div className={styles.proxyBox}>
							<div className={styles.title}>您邀请好友的佣金收入</div>
							<div className={styles.info}>
								<div>
									<label>当前等级</label>
									<div>
										<img src={getLevelImg(commRatio)} alt="" />
										{getLevel(commRatio)}
									</div>
								</div>
								<div>
									<label>基础返佣比例</label>
									<div>{(commRatio * 100).toFixed(0)}%</div>
								</div>
								<div>
									<label>邀请好友数</label>
									<div>{subCount}人</div>
								</div>
								<div>
									<label>累计返佣</label>
									<div>{RESTRICT.float(info.unionTotal).toFixed(2)}元</div>
								</div>
							</div>
						</div>
						<div className={styles.inviteTrade}>
							<div className={styles.title}>
								<h5>您的返佣记录</h5>
								<Link to="/account/inviteFriends" state={2}>
									查看更多记录
								</Link>
							</div>
							<ol>
								<li className={styles.header}>
									<div>序号</div>
									<div>邀请用户</div>
									<div>交易手续费(元)</div>
									<div>佣金收入(元)</div>
									<div>返佣时间</div>
								</li>
								{!isEmpty(data) ? (
									data.map((item, key) => (
										<li key={key}>
											<div>{key + 1}</div>
											<div>{item.subName}</div>
											<div>{RESTRICT.float(item.charge, 2)}</div>
											<div>{RESTRICT.float(item.money, 2)}</div>
											<div>{formatDate("m-d h:i:s", { date: item.createTime })}</div>
										</li>
									))
								) : (
									<div className={styles.empty}>
										<div>
											<img src={funds} alt="" />
											<b>暂无记录</b>
										</div>
									</div>
								)}
							</ol>
						</div>
					</>
				)}
				<div className={styles.levelBox}>
					<div className={styles.title}>
						<div className={styles.left}>
							<div>邀请返佣高达50%</div>
							<p>完善高额的佣金体系，助您不断提升</p>
						</div>
						<div className={styles.right}>
							<a href={"https://wj.qq.com/s2/8297582/2ff4/"} target={"_blank"} className={styles.btn}>
								申请代理
							</a>
						</div>
					</div>
					<div className={styles.level}>
						<div>
							<b>青铜</b>
							<img src={level1} alt="" />
							<span>5%</span>
							<p>永久</p>
							<p>下级交易人数：1人+</p>
						</div>
						<div>
							<b>白银</b>
							<img src={level2} alt="" />
							<span>20%</span>
							<p>永久</p>
							<p>下级交易人数：10人+</p>
						</div>
						<div>
							<b>黄金</b>
							<img src={level3} alt="" />
							<span>25%</span>
							<p>永久</p>
							<p>下级交易人数：30人+</p>
						</div>
						<div>
							<b>铂金</b>
							<img src={level4} alt="" />
							<span>30%</span>
							<p>永久</p>
							<p>下级交易人数：80人+</p>
						</div>
						<div>
							<b>钻石</b>
							<img src={level5} alt="" />
							<span>50%</span>
							<p>永久</p>
							<p>下级交易人数：150人+</p>
						</div>
					</div>
				</div>
			</main>
		</Layout>
	);
};
export default observer(Invite);
