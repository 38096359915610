import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
// core
import CX from "@/core";
// store
import Store from "@/store";
// components
import PositionOrders from "./positionOrders";
import PendingOrders from "./pendingOrders";
import SettleOrders from "./settleOrders";
import { customModal } from "@/view/components/Modal";
//utils
import { arrayToStr } from "@/utils";
// lib
import { RESTRICT } from "@/library/standard";
// style
import styles from "../index.module.scss";
import { ReactComponent as Close } from "@assets/common/x.svg";

const Position = ({ tradeType }) => {
	const [activeIndex, setActiveIndex] = useState(1);
	const isSimulate = Store.Quote.simulate;
	const { limitList = [], list = [] } = Store.Position;
	// 各品類持倉和委託列表
	const tradeList = useMemo(() => {
		switch (tradeType) {
			case "crypto":
				return {
					position: list.filter((item) => item.commodityZone === "crypto"),
					pending: limitList.filter((item) => item.commodityZone === "crypto"),
				};
			case "futures":
				return {
					position: list.filter((item) => item.commodityZone === "deriv"),
					pending: limitList.filter((item) => item.commodityZone === "deriv"),
				};
			case "foreignE":
				return {
					position: list.filter((item) => item.commodityZone === "forex"),
					pending: limitList.filter((item) => item.commodityZone === "forex"),
				};
			case "stock":
				return {
					position: list.filter((item) => item.commodityZone === "hstock"),
					pending: limitList.filter((item) => item.commodityZone === "hstock"),
				};
			default:
				return {
					position: list,
					pending: limitList,
				};
		}
	}, [list, limitList, tradeType]);
	// 訂單列表元件判斷
	const orderContent = useMemo(() => {
		switch (activeIndex) {
			case 1:
				return <PositionOrders list={tradeList.position} tradeType={tradeType} />;
			case 2:
				return <PendingOrders list={tradeList.pending} tradeType={tradeType} />;
			case 3:
				return <SettleOrders tradeType={tradeType} />;
			default:
				return "";
		}
	}, [tradeList, tradeType, activeIndex]);
	// 各品類盈虧計算
	const profitLoss = useMemo(() => {
		switch (tradeType) {
			case "crypto":
				return list.filter((item) => item.commodityZone === "crypto").reduce((a, c) => a + c.income, 0);
			case "futures":
				return list.filter((item) => item.commodityZone === "deriv").reduce((a, c) => a + c.income, 0);
			case "foreignE":
				return list.filter((item) => item.commodityZone === "forex").reduce((a, c) => a + c.income, 0);
			case "stock":
				return list.filter((item) => item.commodityZone === "hstock").reduce((a, c) => a + c.income, 0);
			default:
				return 0;
		}
	}, [tradeType, list]);
	// 一鍵平倉
	const sellAll = async () => {
		if (tradeList.position.length === 0) {
			return;
		}
		if (CX.PositionClose.switch === "true") {
			customModal(<ConfirmModal confirm={submit} />);
			return;
		} else {
			submit();
		}
	};

	const submit = async () => {
		// 只取該品類的訂單id
		const idList = tradeList.position.map((item) => item.id).join(",");
		Store.Position.closePosition(idList)
			.then(({ successNumber, failNumber }) => {
				notification.success({ message: `订单已平仓，成功${successNumber}单， 失败${failNumber}单` });
				Store.Position.start(false);
				Store.User.updateAssets();
			})
			.catch(({ message }) => notification.error({ message }));
	};
	return (
		<div className={styles.position}>
			<div className={styles.positionHead}>
				<div className={styles.nav}>
					<div className={activeIndex === 1 ? styles.active : ""} onClick={() => setActiveIndex(1)}>
						{isSimulate ? "模拟持仓" : "当前持仓"}({tradeList.position.length})
					</div>
					{!isSimulate && (
						<div className={activeIndex === 2 ? styles.active : ""} onClick={() => setActiveIndex(2)}>
							当前委托({tradeList.pending.length})
						</div>
					)}
					<div className={activeIndex === 3 ? styles.active : ""} onClick={() => setActiveIndex(3)}>
						交易记录
					</div>
				</div>
				<div className={styles.sellAll}>
					<div className={styles.profit}>
						<label>盈亏</label>
						<b className={profitLoss > 0 ? "red" : "green"}>{RESTRICT.float(profitLoss?.toFixed(2), 2)}</b>
						<i>元</i>
					</div>
					<div className={arrayToStr([styles.sell])} onClick={sellAll}>
						一键平仓
					</div>
				</div>
			</div>
			{orderContent}
		</div>
	);
};

const ConfirmModal = ({ close, confirm }) => (
	<div className={styles.orderModal}>
		<div className={styles.close} onClick={close}>
			<Close />
		</div>
		<div className={styles.header}>
			<span className={styles.title}>提示</span>
		</div>
		<div className={styles.content}>
			<h2>是否一键平仓?</h2>
			<div className={styles.btnBox}>
				<div onClick={close}>取消</div>
				<div
					className={styles.confirm}
					onClick={() => {
						confirm();
						close();
					}}
				>
					确定
				</div>
			</div>
		</div>
	</div>
);

export default observer(Position);
