import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Tabs, message } from "antd";
import { useNavigate } from "react-router-dom";

// utils
import { oldGroupData, goodsImg } from "@/utils/common";
import { isEmpty, arrayToStr } from "@/utils";
// store
import Store from "@/store";
// components
import { TradeIcon } from "@/view/components/Svg";
// assets
import { ReactComponent as ZixuanIcon } from "@assets/trade/zixuanS.svg";
import { ReactComponent as ZhuliushubiIcon } from "@assets/trade/zhuliushubiS.svg";
import { ReactComponent as ChuanxinshubiIcon } from "@assets/trade/chuanxinshubiS.svg";
import { ReactComponent as NengyuanjinshuIcon } from "@assets/trade/nengyuanjinshuS.svg";
import { ReactComponent as GuzhiIcon } from "@assets/trade/guzhiS.svg";
import { ReactComponent as NongchanpinIcon } from "@assets/trade/nongchanpinS.svg";
import favorBlack from "@assets/trade/favor_black.png";
import favorIcon from "@assets/trade/favor.svg";
import favorSelectIcon from "@assets/trade/favor_select.svg";

// style
import styles from "../index.module.scss";

const getQuotationList = (info) => {
	return isEmpty(info) ? [] : info.list;
};

// 數字貨幣及商品期貨行情
const TabQuotationList = observer(({ tradeType }) => {
	useEffect(() => {
		if (Store.User.isLogin) {
			Store.User.getFavor();
		}
	}, []);
	const [tabList] = useState([
		{
			type: "crypto",
			tabs: [
				{ name: "自选", key: "favor", label: <ZixuanIcon /> },
				{ name: "主流区", key: "crypto", label: <ZhuliushubiIcon /> },
				{ name: "创新区", key: "cryptoNew", label: <ChuanxinshubiIcon /> },
			],
		},
		{
			type: "futures",
			tabs: [
				{ name: "自选", key: "favor", label: <ZixuanIcon /> },
				{ name: "能源", key: "energy", label: <NengyuanjinshuIcon /> },
				{ name: "股指", key: "stock", label: <GuzhiIcon /> },
				{ name: "农业", key: "farm", label: <NongchanpinIcon /> },
			],
		},
	]);
	const tabInfo = useMemo(() => tabList.find((item) => item.type === tradeType), [tabList, tradeType]);
	return (
		<div className="trade-tab">
			{!isEmpty(tabInfo) && (
				<Tabs
					defaultActiveKey={1}
					items={tabInfo.tabs.map((item, key) => {
						let data = [];
						// 非自選設定
						if (key !== 0) {
							if (tabInfo.type === "crypto") {
								data =
									item.key === "crypto"
										? getQuotationList(Store.Brief.group.find((o) => o.name === item.name))
										: getQuotationList(Store.Brief.homeDisplay);
							} else {
								data = getQuotationList(oldGroupData.find((o) => o.name === item.name));
							}
						} else {
							if (tabInfo.type === "crypto") {
								const cryptoList = [
									...getQuotationList(Store.Brief.group.find((o) => o.name === "主流区")),
									...getQuotationList(Store.Brief.homeDisplay),
								];
								data = Store.User.favor.filter((id) => cryptoList.includes(id));
							} else {
								const futuresList = [
									...getQuotationList(oldGroupData.find((o) => o.name === "能源")),
									...getQuotationList(oldGroupData.find((o) => o.name === "股指")),
									...getQuotationList(oldGroupData.find((o) => o.name === "农业")),
								];
								data = Store.User.favor.filter((id) => futuresList.includes(id));
							}
						}
						return {
							key,
							label: item.label,
							children: (
								<QuotationList hasFavor={Store.User.isLogin ? true : false} list={data} tradeType={tradeType} />
							),
						};
					})}
				/>
			)}
		</div>
	);
});

// 港股及外匯
const NoneQuotationList = observer(({ tradeType }) => {
	const list = useMemo(
		() => getQuotationList(oldGroupData.find((o) => o.name === (tradeType === "stock" ? "股票" : "外汇"))),
		[tradeType]
	);
	return <QuotationList hasFavor={false} list={list} tradeType={tradeType} />;
});

const QuotationList = observer(({ list = [], hasFavor = false, tradeType }) => {
	const navigate = useNavigate();
	const [sortType, setSortType] = useState("");
	const [sortStatus, setSortStatus] = useState(0);
	const { total } = Store.Brief;

	const sortBy = (type) => {
		if (sortType !== type) {
			setSortStatus(1);
			setSortType(type);
			return;
		}
		setSortType(type);
		if (sortStatus === 0) {
			setSortStatus(1);
		}
		if (sortStatus === 1) return setSortStatus(-1);
		if (sortStatus === -1) {
			setSortStatus(0);
			setSortType("");
		}
	};
	const changeQuote = (id) => {
		navigate(`/trade/${tradeType}/${id}`);
	};
	const toggleFavor = (id) => {
		if (!Store.User.isLogin) {
			return;
		}
		if (Store.User.isFavor(id)) {
			Store.User.setFavor(id).then((e) => message.info("取消成功", 1));
		} else {
			Store.User.setFavor(id).then((e) => message.info("添加成功", 1));
		}
	};

	return (
		<div className={arrayToStr([styles.quoteBox, !hasFavor ? styles.foreignBox : ""])}>
			<div className={styles.header}>
				<div>商品名称</div>
				<div className={styles.middle}>
					{" "}
					<b>最新价</b>
					<span className={styles.sort} onClick={() => sortBy("price")}>
						<i
							className={arrayToStr([
								styles.up,
								sortStatus === 0 ? "" : sortStatus === -1 && sortType === "price" ? styles.active : "",
							])}
						/>
						<i
							className={arrayToStr([
								styles.down,
								sortStatus === 0 ? "" : sortStatus === 1 && sortType === "price" ? styles.active : "",
							])}
						/>
					</span>
				</div>
				<div className={styles.middle}>
					<b>涨跌幅</b>
					<span className={styles.sort} onClick={() => sortBy("rate")}>
						<i
							className={arrayToStr([
								styles.up,
								sortStatus === 0 ? "" : sortStatus === -1 && sortType === "rate" ? styles.active : "",
							])}
						/>
						<i
							className={arrayToStr([
								styles.down,
								sortStatus === 0 ? "" : sortStatus === 1 && sortType === "rate" ? styles.active : "",
							])}
						/>
					</span>
				</div>
				{hasFavor && <div className={styles.empty} />}
			</div>
			<div className={arrayToStr([styles.list])}>
				{isEmpty(list) ? (
					<div className={styles.empty}>
						<div>
							<img src={favorBlack} alt="" />
							<b>暂无自选</b>
						</div>
					</div>
				) : (
					list
						.filter((key) => !!total[key])
						.sort((a, b) => {
							if (sortType === "price") {
								return (total[b].price - total[a].price) * sortStatus;
							}
							if (sortType === "rate") {
								return (Number(total[b].rate.replace("%", "")) - Number(total[a].rate.replace("%", ""))) * sortStatus;
							}
							return Number(total[a].rate.replace("%", "")) - Number(total[b].rate.replace("%", ""));
						})
						.sort((a, b) => {
							if (total[a].isOpen && !total[b].isOpen) {
								return -1;
							} else if (!total[a].isOpen && total[b].isOpen) {
								return 1;
							} else {
								return 0;
							}
						})
						.map((key) => {
							const item = total[key];
							let n = item.id.indexOf("USDT") === -1 ? "futures" : "crypto";

							return (
								<div className={styles.item} key={key}>
									<div className={styles.leftInfo} onClick={() => changeQuote(item.id)}>
										<div className={arrayToStr([styles.name, !item.isOpen ? "rest" : ""])}>
											<TradeIcon img={`goods/${goodsImg[item.id]}`} />
											{n === "futures" ? item.name : item.id.replace("USDT", "/USDT")}
										</div>
										<div className={item.isOpen ? (item.isUp ? "red" : "green") : "rest"}>
											{item.isOpen ? item.price : "--"}
										</div>
										<div className={item.isOpen ? (item.isUp ? "red" : "green") : "rest"}>
											{item.isOpen ? item.rate : "--"}
										</div>
									</div>
									{hasFavor && (
										<div className={styles.favor}>
											<img
												src={Store.User.isLogin && Store.User.isFavor(item.id) ? favorSelectIcon : favorIcon}
												alt=""
												onClick={() => toggleFavor(item.id)}
											/>
										</div>
									)}
								</div>
							);
						})
				)}
			</div>
		</div>
	);
});

const Quotation = ({ tradeType }) => {
	const hasTabs = useMemo(() => ["crypto", "futures"].includes(tradeType), [tradeType]);
	return (
		<div className={styles.quotes}>
			<div className={styles.quotesInfo}>
				<div className={styles.quotesNav}>
					{hasTabs ? <TabQuotationList tradeType={tradeType} /> : <NoneQuotationList tradeType={tradeType} />}
				</div>
			</div>
		</div>
	);
};

export default Quotation;
