import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { notification, message } from "antd";

// lib
import EVENT from "@/library/api";
// components
import SimpleInput from "@/view/components/SimpleInput";
// style
import styles from "../index.module.scss";

export default function TwoStep(props) {
	const navigate = useNavigate();
	const { token } = props;
	const [password, setPassword] = useState("");
	const [confirmPwd, setConfirmPwd] = useState("");
	const [formatVerify, setFormatVerify] = useState({ password: false, confirmPassword: false });
	const isError = useMemo(() => !formatVerify.password || !formatVerify.confirmPassword, [formatVerify]);
	const submit = () => {
		if (isError) {
			return;
		}
		if (password !== confirmPwd) {
			return message.warning("两次输入密码不一致");
		}
		EVENT.Account.retrieveLoginPass({
			newPass: password,
			cfmPass: confirmPwd,
			token,
		})
			.then(() => {
				notification.success({
					message: "密码重置成功,请重新登录",
					duration: 1,
					onClose: () => {
						navigate("/common/login");
					},
				});
			})
			.catch((message) => notification.error({ message }));
	};
	return (
		<div className={styles.form}>
			<SimpleInput
				label="新密码"
				placeholder="请输入新密码"
				type={"password"}
				format={"SET_WITHDRAWPASSWORD"}
				notice={"withdrawpassword"}
				value={password}
				simple={true}
				force={true}
				onChange={(password, format) => {
					if (format !== formatVerify.password) {
						setFormatVerify((props) => ({ ...props, password: format }));
					}
					setPassword(password);
				}}
			/>
			<SimpleInput
				label="确认密码"
				placeholder="请再次输入新密码"
				type={"password"}
				format={"SET_WITHDRAWPASSWORD"}
				notice={"withdrawpassword"}
				value={confirmPwd}
				simple={true}
				force={true}
				onChange={(password, format) => {
					if (format !== formatVerify.confirmPassword) {
						setFormatVerify((props) => ({ ...props, confirmPassword: format }));
					}
					setConfirmPwd(password);
				}}
			/>
			<div className={`${styles.confirmBtn} ${isError ? styles.error : ""}`} onClick={submit}>
				确定
			</div>
		</div>
	);
}
