import React, { useEffect, useState, useMemo, useRef } from "react";
import { message, notification } from "antd";
// utils
import { mobileMask, arrayToStr } from "@/utils";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import Layout from "./modal";
import SimpleInput from "@/view/components/SimpleInput";
import ImgValid from "@/view/components/ImgValid";
import { imgValidModal } from "@/view/components/Modal";

// style
import styles from "./index.module.scss";

const OneStep = ({ phone, showCode, countText, changeStep, type }) => {
	const [codeInfo, setCodeInfo] = useState({ code: "", format: false });
	const next = () => {
		if (!codeInfo.format) {
			return;
		}
		EVENT.Account.verifySMS(phone, codeInfo.code, type)
			.then(() => changeStep())
			.catch(({ message }) => notification.error({ message }));
	};

	return (
		<>
			<SimpleInput label="手机号码" value={mobileMask(phone)} disable={true} />
			<div className={styles.sendCode}>
				<SimpleInput
					customClass={styles.simpleInput}
					label="短信验证码"
					placeholder="请输入手机验证码"
					type={"verifyCode"}
					format={"GLOBAL_CAPTCHA"}
					notice={"msgCode"}
					value={codeInfo.code}
					onChange={(code, format) => {
						setCodeInfo({ code, format });
					}}
				/>
				<div className={styles.getCode} onClick={showCode}>
					{countText}
				</div>
			</div>
			<div className={arrayToStr(["confirm_btn", styles.confirmBtn, !codeInfo.format ? "error" : ""])} onClick={next}>
				下一步
			</div>
		</>
	);
};
const TwoStep = ({ phone, showCode, countText, getNewPhone, type, close }) => {
	const [account, setAccount] = useState("");
	const [msgCode, setMsgCode] = useState("");
	const [formatVerify, setFormatVerify] = useState({ phone: false, code: false });
	// 輸入欄位驗證
	const isValid = useMemo(() => {
		return Object.values(formatVerify).every((value) => !!value);
	}, [formatVerify]);

	const submit = () => {
		if (!isValid) {
			return;
		}
		EVENT.Account.verifySMS(account, msgCode, type)
			.then(() => changePhone())
			.catch(({ message }) => notification.error({ message }));
	};
	const changePhone = () => {
		EVENT.Account.updatePhone(phone, account)
			.then(() => {
				Store.User.updateDetail();
				notification.success({
					message: "手机号修改成功！",
				});
				close();
			})
			.catch(({ message }) => notification.error({ message }));
	};
	return (
		<>
			<SimpleInput
				label="新手机号码"
				placeholder="请输入新手机号码"
				type={"tel"}
				format={"PHONE"}
				notice={"tel"}
				maxLength={11}
				value={account}
				onChange={(phone, format) => {
					if (format !== formatVerify.phone) {
						setFormatVerify((props) => ({ ...props, phone: format }));
					}
					setAccount(phone);
					getNewPhone(phone);
				}}
			/>
			<div className={styles.sendCode}>
				<SimpleInput
					customClass={styles.simpleInput}
					label="验证码"
					placeholder="请输入验证码"
					type={"verifyCode"}
					format={"GLOBAL_CAPTCHA"}
					notice={"msgCode"}
					value={msgCode}
					onChange={(code, format) => {
						if (format !== formatVerify.code) {
							setFormatVerify((props) => ({ ...props, code: format }));
						}
						setMsgCode(code);
					}}
				/>
				<div
					className={styles.getCode}
					onClick={() => {
						if (!formatVerify.phone) {
							return message.warning("手机号码格式错误");
						}
						showCode();
					}}
				>
					{countText}
				</div>
			</div>
			<div className={arrayToStr(["confirm_btn", styles.confirmBtn, !isValid ? "error" : ""])} onClick={submit}>
				确认
			</div>
		</>
	);
};

export default function ChangePhone({ close, accountInfo }) {
	const { phone } = accountInfo;
	const [next, setNext] = useState(false);
	const [account, setAccount] = useState(phone);
	const [countText, setCountText] = useState("获取验证码");
	const [countTime, setCountTime] = useState(null);
	const [isSend, setIsSend] = useState(false);
	const timer = useRef();

	useEffect(() => {
		return () => {
			clearTimeout();
		};
	}, [account]);
	useEffect(() => {
		if (countTime === null) {
			return;
		}
		if (countTime === 0) {
			message.warning("输入超时，请重新发送验证码!");
			resetTimer();
		} else {
			timer.current = setTimeout(() => {
				setCountTime((time) => time - 1);
				setCountText(`${countTime}秒后重发`);
				setIsSend(true);
			}, 1000);
		}
	}, [countTime]);
	const editType = useMemo(() => (next ? "BIND_PHONE" : "CHANGE_PHONE"), [next]);

	const showCode = () => {
		if (isSend) return;
		imgValidModal(
			<ImgValid
				account={account}
				countText={countText}
				sendCodeStatus={() => setCountTime(90)}
				type={editType}
				isMask={false}
			/>
		);
	};

	const resetTimer = () => {
		setCountText("获取验证码");
		setIsSend(false);
		setCountTime(null);
		clearTimeout(timer.current);
	};

	const gotoNext = () => {
		resetTimer();
		setNext(true);
	};

	const getNewPhone = (account) => setAccount(account);

	return (
		<Layout close={close} title="修改手机">
			{!next ? (
				<OneStep phone={phone} showCode={showCode} countText={countText} type={editType} changeStep={gotoNext} />
			) : (
				<TwoStep
					phone={phone}
					showCode={showCode}
					countText={countText}
					type={editType}
					getNewPhone={getNewPhone}
					close={close}
				/>
			)}
		</Layout>
	);
}
