import React from "react";
// compoents
import Header from "@/view/components/Header";
import Footer from "@/view/components/Footer";
// style
import styles from "./index.module.scss";

export default function Layout(props) {
	const { children, customClass, isFooterHide } = props;
	return (
		<div className={`${styles.layoutWrapper} ${customClass || ""}`}>
			<Header />
			{children}
			{!isFooterHide && <Footer />}
		</div>
	);
}
