/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { notification, message } from "antd";

// store
import Store from "@/store";
// lib
import EVENT from "@/library/api";
import { safeValue } from "@/library/safety";
import { getCookie } from "@/utils";
// components
import SimpleInput from "@/view/components/SimpleInput";
import { CommonIcon } from "@/view/components/Svg";
import Zendesk from "@/view/components/zendesk";
import ImgValid from "@/view/components/ImgValid";

// assets
import { ReactComponent as CheckSvg } from "@/assets/common/check.svg";

// style
import styles from "../index.module.scss";

// 手機表單
const MobileForm = (props) => {
	const { formInfo, setFormInfo } = props;
	const [formatVerify, setFormatVerify] = useState({ phone: false, password: false, code: false });
	const [codeShow, setCodeShow] = useState(false);
	const [countTime, setCountTime] = useState(null);
	const [isSend, setIsSend] = useState(false);
	const [countText, setCountText] = useState("获取验证码");
	const timer = useRef();

	useEffect(() => {
		const o = Store.User.ru || getCookie("ru");
		if (o) {
			setFormInfo((props) => ({ ...props, inviteCode: o }));
		}
	}, []);
	useEffect(() => {
		if (countTime === null) {
			return;
		}
		if (countTime === 0) {
			message.warning("验证码错误或已过期");
			setCountText("获取验证码");
			setIsSend(false);
			setCountTime(null);
			clearTimeout(timer.current);
		} else {
			timer.current = setTimeout(() => {
				setCountTime((time) => time - 1);
				setCountText(`${countTime}秒后重发`);
				setIsSend(true);
			}, 1000);
		}
	}, [countTime]);
	const showCode = () => {
		if (!formInfo.phone || formInfo.phone.length < 8) return message.warning("账号格式不正确");
		if (isSend) return;
		setCodeShow(true);
	};

	return (
		<div className={styles.form}>
			<SimpleInput
				label="手机号码"
				placeholder="请输入手机号码"
				type={"tel"}
				format={"PHONE"}
				notice={"tel"}
				value={formInfo.phone}
				onChange={(phone, format) => {
					if (format !== formatVerify.phone) {
						setFormatVerify((props) => ({ ...props, phone: format }));
					}
					const isFormatValid = format && formatVerify.password && formatVerify.code;
					const correct =
						safeValue(phone, true) &&
						safeValue(formInfo.password, true) &&
						safeValue(formInfo.msgCode, true) &&
						isFormatValid;
					setFormInfo((props) => ({ ...props, phone, error: !correct }));
				}}
			/>
			<SimpleInput
				label="登录密码"
				placeholder="请输入登录密码"
				type={"password"}
				format={"SET_WITHDRAWPASSWORD"}
				notice={"withdrawpassword"}
				value={formInfo.password}
				simple={true}
				force={true}
				onChange={(password, format) => {
					if (format !== formatVerify.password) {
						setFormatVerify((props) => ({ ...props, password: format }));
					}
					const isFormatValid = format && formatVerify.phone && formatVerify.code;
					const correct =
						safeValue(password, true) &&
						safeValue(formInfo.phone, true) &&
						safeValue(formInfo.msgCode, true) &&
						isFormatValid;
					setFormInfo((props) => ({ ...props, password, error: !correct }));
				}}
			/>
			<div className={styles.sendCode}>
				<SimpleInput
					customClass={styles.simpleInput}
					label="短信验证码"
					placeholder="请输入手机验证码"
					type={"verifyCode"}
					format={"GLOBAL_CAPTCHA"}
					notice={"msgCode"}
					value={formInfo.msgCode}
					onChange={(code, format) => {
						if (format !== formatVerify.code) {
							setFormatVerify((props) => ({ ...props, code: format }));
						}
						const isFormatValid = format && formatVerify.phone && formatVerify.password;
						const correct =
							safeValue(code, true) &&
							safeValue(formInfo.phone, true) &&
							safeValue(formInfo.password, true) &&
							isFormatValid;
						setFormInfo((props) => ({ ...props, msgCode: code, error: !correct }));
					}}
				/>
				<div className={styles.getCode} onClick={showCode}>
					{countText}
				</div>
			</div>
			<SimpleInput
				label="邀请码（选填）"
				placeholder={"请输入邀请码"}
				type={"text"}
				free={false}
				value={formInfo?.inviteCode}
				onChange={(e) => setFormInfo((props) => ({ ...props, inviteCode: e }))}
			/>
			{codeShow && (
				<ImgValid
					type="REGISTER"
					account={formInfo.phone}
					sendCodeStatus={() => setCountTime(90)}
					hide={() => setCodeShow(false)}
				/>
			)}
		</div>
	);
};
// 郵箱表單
const EmailForm = (props) => {
	const { formInfo, setFormInfo } = props;
	const [formatVerify, setFormatVerify] = useState({ phone: false, password: false, code: false });
	const [codeShow, setCodeShow] = useState(false);
	const [countTime, setCountTime] = useState(null);
	const [isSend, setIsSend] = useState(false);
	const [countText, setCountText] = useState("获取验证码");
	const timer = useRef();

	useEffect(() => {
		const o = Store.User.ru || getCookie("ru");
		if (o) {
			setFormInfo((props) => ({ ...props, inviteCode: o }));
		}
	}, []);

	useEffect(() => {
		if (countTime === null) {
			return;
		}
		if (countTime === 0) {
			message.warning("验证码错误或已过期");
			setCountText("获取验证码");
			setIsSend(false);
			setCountTime(null);
			clearTimeout(timer.current);
		} else {
			timer.current = setTimeout(() => {
				setCountTime((time) => time - 1);
				setCountText(`${countTime}秒后重发`);
				setIsSend(true);
			}, 1000);
		}
	}, [countTime]);
	const showCode = () => {
		if (!formInfo.email || formInfo.email.indexOf("@") === -1) return message.warning("账号格式不正确");
		if (isSend) return;
		setCodeShow(true);
	};

	return (
		<div className={styles.form}>
			<SimpleInput
				label="电子邮箱"
				placeholder="请输入邮箱地址"
				type={"email"}
				format={"EMAIL"}
				notice={"email"}
				value={formInfo.email}
				onChange={(email, format) => {
					if (format !== formatVerify.email) {
						setFormatVerify((props) => ({ ...props, email: format }));
					}
					const isFormatValid = format && formatVerify.password && formatVerify.code;
					const correct =
						safeValue(email, true) &&
						safeValue(formInfo.password, true) &&
						safeValue(formInfo.msgCode, true) &&
						isFormatValid;
					setFormInfo((props) => ({ ...props, email, error: !correct }));
				}}
			/>
			<SimpleInput
				label="登录密码"
				placeholder="请输入登录密码"
				type={"password"}
				format={"SET_WITHDRAWPASSWORD"}
				notice={"withdrawpassword"}
				value={formInfo.password}
				simple={true}
				force={true}
				onChange={(password, format) => {
					if (format !== formatVerify.password) {
						setFormatVerify((props) => ({ ...props, password: format }));
					}
					const isFormatValid = format && formatVerify.email && formatVerify.code;
					const correct =
						safeValue(password, true) &&
						safeValue(formInfo.email, true) &&
						safeValue(formInfo.msgCode, true) &&
						isFormatValid;
					setFormInfo((props) => ({ ...props, password, error: !correct }));
				}}
			/>
			<div className={styles.sendCode}>
				<SimpleInput
					customClass={styles.simpleInput}
					label="邮箱验证码"
					placeholder="请输入邮箱验证码"
					type={"verifyCode"}
					format={"GLOBAL_CAPTCHA"}
					notice={"msgCode"}
					value={formInfo.msgCode}
					onChange={(code, format) => {
						if (format !== formatVerify.code) {
							setFormatVerify((props) => ({ ...props, code: format }));
						}
						const isFormatValid = format && formatVerify.email && formatVerify.password;
						const correct =
							safeValue(code, true) &&
							safeValue(formInfo.email, true) &&
							safeValue(formInfo.password, true) &&
							isFormatValid;
						setFormInfo((props) => ({ ...props, msgCode: code, error: !correct }));
					}}
				/>
				<div className={styles.getCode} onClick={showCode}>
					{countText}
				</div>
			</div>
			<SimpleInput
				label="邀请码（选填）"
				placeholder={"请输入邀请码"}
				type={"text"}
				free={false}
				onChange={(e) => setFormInfo((props) => ({ ...props, inviteCode: e }))}
			/>
			{codeShow && (
				<ImgValid
					type="REGISTER"
					account={formInfo.email}
					sendCodeStatus={() => setCountTime(90)}
					hide={() => setCodeShow(false)}
				/>
			)}
		</div>
	);
};

export default function Register() {
	const navigate = useNavigate();
	const [registerInfo, setRegisterInfo] = useState({
		type: "MOBILE",
		phone: "",
		email: "",
		password: "",
		msgCode: "",
		inviteCode: "",
		error: true,
		check: false,
	});
	const account = useMemo(() => registerInfo[registerInfo.type === "MOBILE" ? "phone" : "email"], [registerInfo]);
	const switchRegisterType = (type) => {
		setRegisterInfo((props) => ({ ...props, type, phone: "", email: "", password: "", msgCode: "", error: true }));
	};
	const handleVerify = () => {
		const { error, check } = registerInfo;
		if (error) {
			return;
		}
		if (!check) {
			return message.warning("请勾选并阅读《服务条款》");
		}
		EVENT.Account[registerInfo.type === "MOBILE" ? "verifySMS" : "verifyEmail"](
			account,
			registerInfo.msgCode,
			"REGISTER"
		)
			.then(() => register())
			.catch((e) => notification.error({ message: e.message, duration: 2 }));
	};

	const register = () => {
		const data = {
			account,
			password: registerInfo.password,
			countryCode: "",
			ru: registerInfo.inviteCode,
		};
		EVENT.Account.register(data)
			.then(() => {
				EVENT.Account.login({ account, password: registerInfo.password }).then(({ user, message }) => {
					Store.User.loginCallback(user);
					navigate("/");
					notification.success({
						message: "注册成功",
					});
				});
			})
			.catch((e) => notification.error({ message: e.message, duration: 2 }));
	};

	return (
		<div className={styles.formWrapper}>
			<div className={styles.switchBtn}>
				<div
					className={registerInfo.type === "MOBILE" ? styles.active : ""}
					onClick={() => switchRegisterType("MOBILE")}
				>
					手机注册
				</div>
				<div
					className={registerInfo.type !== "MOBILE" ? styles.active : ""}
					onClick={() => switchRegisterType("EMAIL")}
				>
					邮箱注册
				</div>
			</div>
			{registerInfo.type === "MOBILE" ? (
				<MobileForm formInfo={registerInfo} setFormInfo={setRegisterInfo} />
			) : (
				<EmailForm formInfo={registerInfo} setFormInfo={setRegisterInfo} />
			)}
			<div className={styles.linkBox}>
				<div className={styles.checkContent}>
					<div
						className={`${styles.checkBox} ${!registerInfo.check ? styles.noCheck : ""}`}
						onClick={() => setRegisterInfo((props) => ({ ...props, check: !props.check }))}
					>
						{registerInfo.check && <CheckSvg />}
					</div>
					我已阅读并同意<Zendesk href={"/articles/360059372693"}>服务条款</Zendesk>
				</div>
				<a href={"/aboutUs#qualification"}>
					<CommonIcon img="USA" />
					美国金融监管机构权威认证
				</a>
			</div>
			<div className={`${styles.confirmBtn} ${registerInfo.error ? styles.error : ""}`} onClick={() => handleVerify()}>
				注册
			</div>
			<div className={styles.switchType}>
				<p>
					已有账户？<Link to={"/common/login"}>立即登录</Link>
				</p>
			</div>
		</div>
	);
}
