import React, { useState } from "react";
import { notification } from "antd";

// utils
import { arrayToStr } from "@/utils";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import Layout from "./modal";
import SimpleInput from "@/view/components/SimpleInput";
// style
import styles from "./index.module.scss";

export default function EditNickName({ close }) {
	const [nickName, setNickName] = useState("");
	const submit = () => {
		if (!nickName) {
			return;
		}
		EVENT.Account.updateNickname(nickName)
			.then(() => {
				Store.User.updateDetail();
				notification.success({ message: "昵称修改成功" });
				close();
			})
			.catch(({ message }) => notification.error({ message }));
	};
	return (
		<Layout close={close} title="修改昵称">
			<SimpleInput
				type="nickname"
				value={nickName}
				label="昵称"
				placeholder="请输入昵称"
				onChange={(name) => setNickName(name)}
			/>
			<div className={arrayToStr(["confirm_btn", styles.confirmBtn, !nickName ? "error" : ""])} onClick={submit}>
				确认
			</div>
		</Layout>
	);
}
