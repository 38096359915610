import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Switch, message, Slider, Checkbox, notification } from "antd";

//utils
import { arrayToStr } from "@/utils";
// lib
import { RESTRICT } from "@/library/standard";
// store
import Store from "@/store";
// components
import Rule from "./rule";
import Setting from "./setting";
import { TradeIcon } from "@/view/components/Svg";
import { customModal } from "@/view/components/Modal";
//assets
import { ReactComponent as SettingIcon } from "@assets/trade/setting.svg";
import { ReactComponent as Close } from "@assets/common/x.svg";

// style
import styles from "../index.module.scss";

const ConfirmModal = ({ close, isBuy, tradeInfo, order }) => (
	<div className={styles.orderModal}>
		<div className={styles.close} onClick={close}>
			<Close />
		</div>
		<div className={styles.header}>
			<span className={styles.title}>订单详情</span>
		</div>
		<div className={styles.content}>
			<div>
				<label>方向：</label>
				<b className={isBuy ? "red" : "green"}>{isBuy ? "买涨" : "买跌"}</b>
			</div>
			<div>
				<label>保证金：</label>
				<b>{tradeInfo.margin}元</b>
			</div>
			<div>
				<label>手续费：</label>
				<b>{RESTRICT.float(tradeInfo.deductCharge)}元</b>
			</div>
			<div>
				<label>礼金抵扣：</label>
				<b>{tradeInfo.marginPrize}元</b>
			</div>
			<div>
				<label>积分抵扣：</label>
				<b>{tradeInfo.useEagle && tradeInfo.usableEagle ? tradeInfo.deductMoney.toFixed(2) : 0}元</b>
			</div>
			<div>
				<label>总交易费用：</label>
				<b className={styles.total}>{!!tradeInfo.total && RESTRICT.float(tradeInfo.total, 2)}元</b>
			</div>
			<div className={styles.btnBox}>
				<div onClick={close}>取消</div>
				<div
					className={styles.confirm}
					onClick={() => {
						order();
						close();
					}}
				>
					确定
				</div>
			</div>
		</div>
	</div>
);

const LeverTrade = ({ tradeType }) => {
	const location = useLocation();
	const isSimulate = Store.Quote.simulate;
	const wallet = Store.Wallet.getCurrency("CNY");
	const { quote = {} } = Store.Quote || {};
	const {
		priceDigit,
		orderType,
		margin,
		marginPrize,
		deductCharge,
		total,
		useEagle,
		usableEagle,
		deductEagle,
		deductMoney,
		marginArea,
	} = Store.Futures.tradeData;
	const { orderSetting } = Store.Futures;
	const { accountInfo } = Store.User;
	const isLogin = Store.User.isLogin;

	const tradeInfo = Store.Futures;
	const [activeMargin, setActiveMargin] = useState("");
	const [marginList] = useState([25, 50, 75, 100]);
	const [activeStopLoss, setActiveStopLoss] = useState("");
	const [stopLossList] = useState([30, 50, 70, 90]);
	const [activeStopProfit, setActiveStopProfit] = useState("");
	const [stopProfitList] = useState([50, 100, 150, 200]);
	const [focusOn, setFocusOn] = useState("");
	const quoteId = quote?.id || "--";
	const quotePrice = quote?.price || "--";
	useEffect(() => {
		if (quoteId !== "--" && isLogin && accountInfo) {
			Store.Futures.switchOrderType(0);
			Store.Futures.setTargetPrice(quotePrice);
		}
	}, [quoteId, isLogin, accountInfo]);
	useEffect(() => {
		if (quote.id !== "--" && orderSetting.overnight !== undefined) {
			Store.Futures.switchDefer(orderSetting.overnight);
		}
	}, [quote.id, orderSetting]);

	const updatePrice = (isAdd) => {
		let price = Store.Futures.targetPrice || Store.Futures.price;
		if (!isAdd) {
			const val = Number(price)
				.sub(RESTRICT.float(Math.pow(0.1, priceDigit), priceDigit))
				.toFixed(priceDigit);
			// const val = Number(price).sub(Math.pow(0.1, priceDigit).toFixed(priceDigit));
			Store.Futures.setTargetPrice(val);
		} else {
			const val = Number(price)
				.add(RESTRICT.float(Math.pow(0.1, priceDigit), priceDigit))
				.toFixed(priceDigit);
			// const val = Number(price).add(Math.pow(0.1, priceDigit).toFixed(priceDigit));
			Store.Futures.setTargetPrice(val);
		}
	};

	const setMargin = (val, key) => {
		const { game, money } = wallet;
		setActiveMargin(key);
		const m = RESTRICT.float(!isSimulate ? money.mul(val.div(100)) : game.mul(val.div(100)), 0);
		if (m < marginArea[0]) return Store.Futures.switchMarginFree(marginArea[0]);
		if (m > marginArea[1]) return Store.Futures.switchMarginFree(marginArea[1]);
		Store.Futures.switchMarginFree(m);
	};

	const confirmOrder = (isBuy) => {
		if (!margin) {
			return;
		}
		// 若止盈比例為默認且 future store 的 stopProfitRate 跟 orderSetting stopProfitRate 不一致時做更新下單的 stopProfitRate
		if (!activeStopProfit && Store.Futures.stopProfitRate !== Store.Futures.orderSetting.tp) {
			Store.Futures.switchStopProfitRate(Store.Futures.orderSetting.tp.mul(100));
		}
		// 若止損比例為默認且 future store 的 stopLossRate 跟 orderSetting stopLossRate 不一致時做更新下單的 stopLossRate
		if (!activeStopLoss && Store.Futures.stopLossRate !== Store.Futures.orderSetting.sl) {
			Store.Futures.switchStopLossRate(Store.Futures.orderSetting.sl.mul(100));
		}
		if (Store.Futures.orderSetting.confirmPlace) {
			customModal(<ConfirmModal isBuy={isBuy} tradeInfo={Store.Futures.tradeData} order={() => order(isBuy)} />);
		} else {
			order(isBuy);
		}
	};
	const order = (isBuy) => {
		Store.Futures.order(isBuy)
			.then(() => {
				notification.success({ message: "委托提交成功" });
				Store.User.updateDetail();
				Store.Position.startLimit(Store.Position.keepUpdateLimit ? false : true);
				Store.Position.start(Store.Position.keepUpdate ? false : true);
				Store.User.updateAssets();
			})
			.catch((error) => {
				message.warning(<div dangerouslySetInnerHTML={{ __html: error.message }} />);
			});
	};

	const marks = useMemo(() => {
		if (tradeInfo.leverShowList) {
			const markInfo = {};
			tradeInfo.leverShowList.forEach((e) => {
				markInfo[e] = `${e}X`;
			});
			return markInfo;
		}
		return {};
	}, [tradeInfo.leverShowList]);

	return (
		<div className={styles.tradeLeverOrder}>
			<div className={styles.orderBox}>
				<div className={styles.navBox}>
					<div className={styles.title}>
						<div className={orderType === 0 ? styles.active : ""} onClick={() => Store.Futures.switchOrderType(0)}>
							市价
						</div>
						{!isSimulate && (
							<div className={orderType === 1 ? styles.active : ""} onClick={() => Store.Futures.switchOrderType(1)}>
								限价
							</div>
						)}
					</div>
				</div>
				<div className={styles.orderInfo}>
					<div className={styles.leverBox}>
						<div className={styles.leverTitle}>
							<label>
								杠杆倍率：<b>{tradeInfo.lever}X</b>
							</label>
							<div
								onClick={() => {
									customModal(<Setting />);
								}}
							>
								<i>交易设置</i>
								<SettingIcon />
							</div>
						</div>
						<div>
							<Slider
								marks={marks}
								step={1}
								defaultValue={tradeInfo.leverList[0]}
								min={tradeInfo.leverList[0]}
								max={tradeInfo.leverList[1]}
								value={tradeInfo.lever}
								onChange={(e) => Store.Futures.switchLever(e)}
								draggableTrack={true}
							/>
						</div>
					</div>
					{tradeInfo.orderType === 1 && !isSimulate && (
						<div className={styles.profitBox}>
							<label>限价</label>
							<div>
								<div className={arrayToStr([styles.leverInputBox, focusOn === "price" ? styles.focus : ""])}>
									<input
										type="text"
										placeholder={tradeInfo.price}
										onFocus={() => setFocusOn("price")}
										onBlur={(e) => {
											setFocusOn("");
											let value = e.target.value;
											if (value === "" || value === 0 || value === "0.") {
												Store.Futures.setTargetPrice(Store.Futures.price);
											}
										}}
										value={Store.Futures.targetPrice || ""}
										onChange={(e) => {
											let value = e.target.value;
											if (value.indexOf("-") !== -1) return;
											if (value.indexOf(".") !== -1) {
												if (value.split(".")[0].length > 6) return;
												if (value.split(".")[1].length > priceDigit) return;
											} else {
												if (value.split(".")[0].length > 6) return;
											}
											Store.Futures.setTargetPrice(value);
										}}
									/>
									<div className={styles.ctrlBox}>
										<div className={styles.btn} onClick={() => updatePrice(true)}>
											<TradeIcon img="add" />
										</div>
										<div className={styles.btn} onClick={() => updatePrice(false)}>
											<TradeIcon img="loss" />
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className={styles.profitBox}>
						<label>保证金</label>
						<div>
							<div className={arrayToStr([styles.leverInputBox, focusOn === "margin" ? styles.focus : ""])}>
								<input
									type="number"
									placeholder={`${marginArea && marginArea[0] ? marginArea[0] : 0} ~ ${
										marginArea && marginArea[1] ? marginArea[1] : 0
									}`}
									value={margin || ""}
									onFocus={() => setFocusOn("margin")}
									onBlur={(e) => {
										setFocusOn("");
										let value = e.target.value;
										if (value) {
											if (value < marginArea[0]) {
												value = marginArea[0];
												Store.Futures.switchMarginFree(value);
											}
										}
									}}
									onChange={(e) => {
										let value = e.target.value;
										if (value) {
											value = value
												.replace(/[^0-9]/g, "")
												.replace(/\D/g, "")
												.replace(/\b(0+)/gi, "");
											if (value > marginArea[1]) {
												value = marginArea[1];
											}
										}
										if (value !== activeMargin) {
											setActiveMargin("");
										}
										Store.Futures.switchMarginFree(value);
									}}
								/>
								<span className={styles.unit}>CNY</span>
							</div>
							<div className={styles.marginBox}>
								{marginList.map((val, index) => (
									<div
										key={val}
										className={index === activeMargin ? styles.active : ""}
										onClick={() => setMargin(val, index)}
									>
										{val !== 100 ? `${val}%` : "ALL"}
									</div>
								))}
							</div>
						</div>
					</div>
					<div className={styles.chooseBox}>
						<label>止损比例</label>
						<div className={styles.proportion}>
							{stopLossList.map((key, index) => (
								<div
									key={index}
									className={index === activeStopLoss ? styles.active : ""}
									onClick={() => {
										setActiveStopLoss(index);
										Store.Futures.switchStopLossRate(key);
									}}
								>
									{key}%
								</div>
							))}
							<div
								className={activeStopLoss === "" ? styles.active : ""}
								onClick={() => {
									setActiveStopLoss("");
									Store.Futures.switchStopLossRate(Store.Futures.orderSetting.sl.mul(100));
								}}
							>
								默认
							</div>
						</div>
					</div>
					<div className={styles.chooseBox}>
						<label>止盈比例</label>
						<div className={styles.proportion}>
							{stopProfitList.map((key, index) => (
								<div
									key={index}
									className={index === activeStopProfit ? styles.active : ""}
									onClick={() => {
										setActiveStopProfit(index);
										Store.Futures.switchStopProfitRate(key);
									}}
								>
									{key}%
								</div>
							))}
							<div
								className={activeStopProfit === "" ? styles.active : ""}
								onClick={() => {
									setActiveStopProfit("");
									Store.Futures.switchStopProfitRate(Store.Futures.orderSetting.tp.mul(100));
								}}
							>
								默认
							</div>
						</div>
					</div>
					<div className={styles.total}>
						<div>
							<label>手续费</label>
							<div className={"green"}>
								{deductCharge ? RESTRICT.float(deductCharge, 2) : 0}
								<span>元</span>
							</div>
						</div>
						<div>
							<label>礼金抵扣</label>
							<div>{marginPrize}元</div>
						</div>
						<div>
							<label>保证金</label>
							<div>{margin}元</div>
						</div>
						<div>
							<label>总交易费用</label>
							<div>{!!total && RESTRICT.float(total, 2)}元</div>
						</div>
					</div>
					{!isSimulate && (
						<>
							<div className={styles.line}>
								<label>持仓过夜</label>
								<Checkbox checked={tradeInfo.defer} onChange={(e) => Store.Futures.switchDefer(e.target.checked)} />
							</div>
							<div className={styles.line}>
								<label>积分抵扣</label>
								<div className={styles.volume}>
									{useEagle && usableEagle && (
										<div className={styles.deduct}>
											消耗<span className={"green"}>{deductEagle?.toFixed(0)}</span>积分，抵扣
											<span className={"green"}>¥{deductMoney?.toFixed(2)}</span>
										</div>
									)}
									<Switch
										className="trade-switch"
										checked={useEagle && usableEagle}
										onChange={() => {
											if (!useEagle && !usableEagle) return message.warning("积分不足，无法抵扣");
											Store.Futures.switchEagle(!useEagle);
										}}
									/>
								</div>
							</div>
						</>
					)}
					<div className={styles.btnBox}>
						<div className={arrayToStr([styles.btn, styles.redBG])} onClick={() => confirmOrder(true)}>
							<span>买涨</span>
							<b>{quote.buyPrice}</b>
						</div>
						<div className={arrayToStr([styles.btn, styles.greenBG])} onClick={() => confirmOrder(false)}>
							<span>买跌</span>
							<b>{quote.sellPrice}</b>
						</div>
					</div>
				</div>
				{!Store.User.isLogin && (
					<div className={styles.orderCover}>
						<div>
							<TradeIcon img="orderTips" />
							<p>参与实际市场前请登录您的交易账户,</p>
							<p>这里的交易数据都是实时的。</p>
							<Link to={"/common/login"} state={{ from: location.pathname }}>
								登录账户
							</Link>
							<Link to={"/common/register"} state={{ from: location.pathname }}>
								注册账户
							</Link>
						</div>
					</div>
				)}
			</div>
			<Rule orderType={orderType} tradeType={tradeType} />
		</div>
	);
};

export default observer(LeverTrade);
