import { makeAutoObservable } from "mobx";
//todo 记录后台配置品牌信息
class Brand {
	/**
	 * 品牌代码
	 */
	code = "";
	/**
	 * 是否是国内版
	 */
	local = false;
	/**
	 * 是否有效
	 */
	valid = false;
	/**
	 * 品牌默认货币
	 */
	defaultCurrency = null;
	/**
	 * 品牌支持的货币
	 */
	supportCurrency = null;
	/**
	 * 是否行为认证
	 */
	geetest = false;
	/**
	 * 是否首充认证
	 */
	nameAuth = false;
	/**
	 * 是否递延
	 */
	defer = false;
	/**
	 * 活动回收天数，即超过N天未交易的用户，将收回注册赠送资金
	 */
	activityDays = 0;
	/**
	 * 提款时间开始于每周几（1~7） 后端命名就不能用 Start,End? 只会1,2
	 */
	withdrawWeek1 = 1;
	/**
	 * 提款时间结束于每周几（1~7）
	 */
	withdrawWeek2 = 2;
	/**
	 * 提款时段开始 0-24
	 */
	withdrawHour1 = 0;
	/**
	 * 提款时段结束 0-24
	 */
	withdrawHour2 = 0;
	/**
	 * 提款可撤销间隔时间（分钟）
	 */
	withdrawInterval = 5;
	/**
	 * 币币兑换手续费比例
	 */
	exchangeRatio = 0;
	/**
	 * 积分抵扣手续费比例
	 */
	eagleTrade = 0;
	/**
	 * 积分抵扣递延费比例
	 */
	eagleDefer = 0;
	/**
	 * 礼金抵扣保证金比例
	 */
	prizeTrade = 0;
	/**
	 * 红包抵扣手续费比例
	 */
	luckyTrade = 0;
	/**
	 * ios下载地址
	 */
	iosUrl = "";
	/**
	 * 安卓下载地址
	 */
	androidUrl = "";
	/**
	 * 客服地址
	 */
	cusServiceUrl = "";

	constructor() {
		makeAutoObservable(this);
	}

	setData(data) {
		Object.assign(this, data);
	}
}

export default new Brand();
