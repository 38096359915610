/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination, notification } from "antd";
import dayjs from "dayjs";

// utils
import { isEmpty, arrayToStr } from "@/utils";
// store
import Store from "@/store";
// style
import styles from "./index.module.scss";
const PAGE_SIZE = 8;
function itemRender(current, type, originalElement) {
	if (type === "prev") {
		return <a>上一页</a>;
	}
	if (type === "next") {
		return <a>下一页</a>;
	}
	return originalElement;
}

export default function AnnounceNotice({ list }) {
	const [noticeList, setNotice] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(0);
	useEffect(() => {
		if (!isEmpty(list)) {
			setTotal(list.length);
			setNotice(
				list
					.reduce((total, next, i) => {
						const page = Math.floor(i / PAGE_SIZE) + 1;
						const nowPage = total[page] ?? [];
						total[page] = [...nowPage, next];
						return total;
					}, [])
					.filter((item) => item)
			);
		}
	}, [list]);

	return (
		<div className={styles.announceNotice}>
			<h1 className={styles.title}>动态公告</h1>
			<ul className={styles.list}>
				{!isEmpty(noticeList) &&
					noticeList[page].map((item) => {
						const time = dayjs(item.time);
						return (
							<li key={item.id} className={styles.item}>
								<div className={styles.year}>
									<h2>{time.year()}</h2>
								</div>
								<div className={styles.month}>
									<h2>{time.month() + 1}</h2>
								</div>
								<div className={styles.date}>
									<h5>{time.format("YYYY.MM.DD")}</h5>
									<Link to={`/announcements/${item.id}`}>{item.title}</Link>
									<hr />
								</div>
							</li>
						);
					})}
			</ul>
			<div className={arrayToStr([styles.pagination, "announce-pagination"])}>
				<a className={styles.firstPage} onClick={() => setPage(0)}>
					首页
				</a>
				<Pagination
					current={page + 1}
					total={total}
					onChange={(v) => setPage(v - 1)}
					itemRender={itemRender}
					defaultPageSize={PAGE_SIZE}
					showSizeChanger={false}
				/>
				<div className={styles.totalPage}>{`共${noticeList.length}页`}</div>
			</div>
		</div>
	);
}
