import Launcher from "./launcher";
import Theme from "./theme";
import PositionClose from "./PositionCloseSet";

export default class Core {
  #launcher = null;
  #theme = null;
  #positionClose = null;

  constructor() {
    this.#launcher = new Launcher();
    this.#theme = new Theme();
    this.#positionClose = new PositionClose();
  }

  get Launcher() {
    return this.#launcher;
  }

  get Theme() {
    return this.#theme;
  }

  get PositionClose() {
    return this.#positionClose;
  }
}
