import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Switch, message, notification } from "antd";

//utils
import { arrayToStr, isEmpty } from "@/utils";
// lib
import { RESTRICT } from "@/library/standard";
// store
import Store from "@/store";
// components
import Rule from "./rule";
import { TradeIcon } from "@/view/components/Svg";
import Popover from "@/view/components/Popover";
import { customModal } from "@/view/components/Modal";
import { ReactComponent as Close } from "@assets/common/x.svg";

// style
import styles from "../index.module.scss";

const ConfirmModal = ({ close, isBuy, tradeInfo, order }) => (
	<div className={styles.orderModal}>
		<div className={styles.close} onClick={close}>
			<Close />
		</div>
		<div className={styles.header}>
			<span className={styles.title}>订单详情</span>
		</div>
		<div className={styles.content}>
			<div>
				<label>方向：</label>
				<b className={isBuy ? "red" : "green"}>{isBuy ? "买涨" : "买跌"}</b>
			</div>
			<div>
				<label>保证金：</label>
				<b>{tradeInfo.margin}元</b>
			</div>
			<div>
				<label>手续费：</label>
				<b>{RESTRICT.float(tradeInfo.deductCharge)}元</b>
			</div>
			<div>
				<label>礼金抵扣：</label>
				<b>{tradeInfo.marginPrize}元</b>
			</div>
			<div>
				<label>积分抵扣：</label>
				<b>{tradeInfo.useEagle && tradeInfo.usableEagle ? RESTRICT.float(tradeInfo.deductMoney, 2) : 0}元</b>
			</div>
			<div>
				<label>总交易费用：</label>
				<b className={styles.total}>{!!tradeInfo.total && RESTRICT.float(tradeInfo.total, 2)}元</b>
			</div>
			<div className={styles.btnBox}>
				<div onClick={close}>取消</div>
				<div
					className={styles.confirm}
					onClick={() => {
						order();
						close();
					}}
				>
					确定
				</div>
			</div>
		</div>
	</div>
);

const DerivTrade = () => {
	const location = useLocation();
	const isSimulate = Store.Quote.simulate;
	const { quote = {} } = Store.Quote || {};
	const {
		volumeIndex,
		priceDigit,
		volumeList,
		orderType,
		moneyType,
		moneyTypeList,
		targetPrice,
		stopLoss,
		select,
		stopProfitList,
		margin,
		marginPrize,
		deductCharge,
		total,
		useEagle,
		usableEagle,
		deductEagle,
		deductMoney,
	} = Store.Futures.tradeData;
	const [pendingPrice, setPendingPrice] = useState("");
	const [isFocus, setIsFocus] = useState(false);
	useEffect(() => {
		if (quote.id !== "--" && Store.User.isLogin) {
			Store.Futures.switchOrderType(0);
		}
	}, [quote.id, Store.User.isLogin]);
	useEffect(() => {
		setPendingPrice("");
		if (quote.id !== "--" && Store.User.isLogin && Store.User.accountInfo) {
			Store.Futures.setTargetPrice(quote.price);
		}
	}, [quote.id, Store.User.isLogin, Store.User.accountInfo]);

	const updatePrice = (isAdd) => {
		let price = Store.Futures.targetPrice || Store.Futures.price;
		if (!isAdd) {
			const val = Number(price)
				.sub(RESTRICT.float(Math.pow(0.1, priceDigit), priceDigit))
				.toFixed(priceDigit);
			Store.Futures.setTargetPrice(val);
			setPendingPrice(val);
		} else {
			const val = Number(price)
				.add(RESTRICT.float(Math.pow(0.1, priceDigit), priceDigit))
				.toFixed(priceDigit);
			Store.Futures.setTargetPrice(val);
			setPendingPrice(val);
		}
	};

	const confirmOrder = (isBuy) => {
		if (Store.Futures.orderSetting.confirmPlace) {
			customModal(<ConfirmModal isBuy={isBuy} tradeInfo={Store.Futures.tradeData} order={() => order(isBuy)} />);
		} else {
			order(isBuy);
		}
	};
	const order = (isBuy) => {
		Store.Futures.order(isBuy)
			.then(() => {
				notification.success({ message: "委托提交成功" });
				Store.User.updateDetail();
				Store.Position.startLimit(Store.Position.keepUpdateLimit ? false : true);
				Store.Position.start(Store.Position.keepUpdate ? false : true);
				Store.User.updateAssets();
			})
			.catch((error) => {
				message.warning(<div dangerouslySetInnerHTML={{ __html: error.message }} />);
			});
	};

	return (
		<div className={styles.tradeOrder}>
			<div className={styles.orderBox}>
				<div className={styles.navBox}>
					<div className={styles.title}>
						<div className={orderType === 0 ? styles.active : ""} onClick={() => Store.Futures.switchOrderType(0)}>
							市价
						</div>
						{!isSimulate && (
							<div className={orderType === 1 ? styles.active : ""} onClick={() => Store.Futures.switchOrderType(1)}>
								限价
							</div>
						)}
					</div>
				</div>
				<div className={styles.orderInfo}>
					<div className={styles.dynamic}>
						<div className={styles.dynamicInfo}>
							<div>
								<div>
									<span>买盘</span>
									<b>{quote.buyPrice}</b>
								</div>
								<div>{quote.buyVolume}</div>
							</div>
							<div>
								<div>
									<span>卖盘</span>
									<b>{quote.sellPrice}</b>
								</div>
								<div>{quote.sellVolume}</div>
							</div>
						</div>
						<div className={styles.volumeInfo}>
							<div className={styles.buy} style={{ width: `${quote.buyWidth * 100}%` }} />
							<div className={styles.sell} style={{ width: `${quote.sellWidth * 100}%` }} />
						</div>
					</div>
					<div className={arrayToStr([styles.line, styles.one])}>
						<label>交易模式</label>
						<div className={styles.volume}>
							{isSimulate ? (
								<div className={styles.active}>标准</div>
							) : (
								!isEmpty(moneyTypeList) &&
								moneyTypeList.map((item, key) => (
									<div
										key={key}
										className={moneyType === item ? styles.active : ""}
										onClick={() => Store.Futures.switchMoneyType(item)}
									>
										{item === 0 ? "标准" : "迷你"}
									</div>
								))
							)}
						</div>
					</div>
					{orderType === 1 && (
						<div className={styles.line}>
							<label>限价金额</label>
							<div className={styles.volume}>
								<div className={arrayToStr([styles.inputBox, isFocus ? styles.focus : ""])}>
									<input
										type="text"
										placeholder={targetPrice}
										value={pendingPrice}
										onFocus={() => setIsFocus(true)}
										onBlur={(e) => {
											setIsFocus(false);
											let value = e.target.value;
											if (value === "" || value === 0 || value === "0.") {
												Store.Futures.setTargetPrice(Store.Futures.price);
												setPendingPrice(targetPrice);
											}
										}}
										onChange={(e) => {
											let value = e.target.value;
											if (value.indexOf("-") !== -1) return;
											if (value.indexOf(".") !== -1) {
												if (value.split(".")[0].length > 6) return;
												if (value.split(".")[1].length > priceDigit) return;
											} else {
												if (value.split(".")[0].length > 6) return;
											}
											Store.Futures.setTargetPrice(value);
											setPendingPrice(value);
										}}
									/>
									<div className={styles.ctrlBox}>
										<div className={styles.btn} onClick={() => updatePrice(false)}>
											<TradeIcon img="loss" />
										</div>
										<div className={styles.btn} onClick={() => updatePrice(true)}>
											<TradeIcon img="add" />
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className={arrayToStr([styles.line, styles.two])}>
						<label>手数</label>
						<div className={styles.volume}>
							{!isEmpty(volumeList) &&
								volumeList.map((val, index) => (
									<div
										key={index}
										className={volumeIndex === index ? styles.active : ""}
										onClick={() => Store.Futures.switchVolume(index)}
									>
										{val}
									</div>
								))}
						</div>
					</div>
					<div className={arrayToStr([styles.line, styles.two])}>
						<label>保证金(元)</label>
						<div className={arrayToStr([styles.volume, styles.stopLoss])}>
							{!isEmpty(stopLoss) &&
								stopLoss.map((val, index) => (
									<div
										key={index}
										className={select === index ? styles.active : ""}
										onClick={() => Store.Futures.switchStopLoss(index)}
									>
										{Math.abs(val)}
									</div>
								))}
						</div>
					</div>
					<div className={styles.line}>
						<label>
							止盈
							<Popover content={<span>下单后可在持仓修改止盈止损</span>} placement="right" className={styles.hint}>
								<i>
									<TradeIcon img="help" />
								</i>
							</Popover>
						</label>

						<div className={styles.stopProfit}>
							{margin * (!!stopProfitList && stopProfitList[0])}
							<span>元</span>
						</div>
					</div>
					<div className={styles.total}>
						<div>
							<label>手续费</label>
							<div>
								{deductCharge}
								<span>元</span>
							</div>
						</div>
						<div>
							<label>礼金抵扣</label>
							<div>{isSimulate ? 0 : marginPrize}元</div>
						</div>
						<div>
							<label>保证金</label>
							<div>{margin}元</div>
						</div>
						<div>
							<label>总交易费用</label>
							<div>{!!total && RESTRICT.float(total, 2)}元</div>
						</div>
					</div>
					{!isSimulate && (
						<div className={styles.line}>
							<label>积分抵扣</label>
							<div className={styles.volume}>
								{useEagle && usableEagle && (
									<div className={styles.deduct}>
										消耗<span className={"green"}>{deductEagle.toFixed(0)}</span>积分，抵扣
										<span className={"green"}>¥{deductMoney.toFixed(2)}</span>
									</div>
								)}
								<Switch
									className="trade-switch"
									checked={useEagle && usableEagle}
									onChange={() => {
										if (!useEagle && !usableEagle) return message.warning("积分不足，无法抵扣");
										Store.Futures.switchEagle(!useEagle);
									}}
								/>
							</div>
						</div>
					)}
					<div className={styles.btnBox}>
						<div className={arrayToStr([styles.btn, styles.redBG])} onClick={() => confirmOrder(true)}>
							<span>买涨</span>
							<b>{quote.buyPrice}</b>
						</div>
						<div className={arrayToStr([styles.btn, styles.greenBG])} onClick={() => confirmOrder(false)}>
							<span>买跌</span>
							<b>{quote.sellPrice}</b>
						</div>
					</div>
				</div>
				{!Store.User.isLogin && (
					<div className={styles.orderCover}>
						<div>
							<TradeIcon img="orderTips" />
							<p>参与实际市场前请登录您的交易账户,</p>
							<p>这里的交易数据都是实时的。</p>
							<Link to={"/common/login"} state={{ from: location.pathname }}>
								登录账户
							</Link>
							<Link to={"/common/register"} state={{ from: location.pathname }}>
								注册账户
							</Link>
						</div>
					</div>
				)}
			</div>
			<Rule orderType={orderType} tradeType="deriv" />
		</div>
	);
};

export default observer(DerivTrade);
