import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Slider, notification } from "antd";

// lib
import { RESTRICT } from "@/library/standard";
//utils
import { arrayToStr } from "@/utils";
import { goodsImg } from "@/utils/common";
// store
import Store from "@/store";
import { TradeIcon } from "@/view/components/Svg";
// assets
import { ReactComponent as Close } from "@assets/common/x.svg";
// style
import styles from "../index.module.scss";

export const DerivSPSL = observer(({ close, id }) => {
	const { list = [] } = Store.Position;
	const stopLossStepMin = Store.FuturesSPSL.stopLossStepMin;
	useEffect(() => {
		if (Store.FuturesSPSL.SPSL.stopLossIndex < stopLossStepMin) {
			Store.FuturesSPSL.setStopLoss(stopLossStepMin <= 0 ? stopLossStepMin + 1 : stopLossStepMin);
		}
	}, [stopLossStepMin]);
	const orderInfo = useMemo(() => list.find((item) => item.id === id), [list, id]);

	if (Store.FuturesSPSL.SPSL === null) return;

	const {
		name,
		contractCode,
		stopProfit,
		stopProfitPrice,
		stopProfitStep,
		stopProfitIndex,
		stopLoss,
		stopLossPrice,
		stopLossStep,
		stopLossIndex,
	} = Store.FuturesSPSL.SPSL;

	const updateProfit = (isAdd) => {
		if (isAdd) {
			Store.FuturesSPSL.setStopProfit(stopProfitIndex + 1);
		} else {
			Store.FuturesSPSL.setStopProfit(stopProfitIndex - 1);
		}
	};
	const updateLoss = (isAdd) => {
		if (isAdd) {
			Store.FuturesSPSL.setStopLoss(stopLossIndex + 1);
		} else {
			if (stopLossIndex - 1 === stopLossStepMin) return;
			Store.FuturesSPSL.setStopLoss(stopLossIndex - 1);
		}
	};
	// 取消或關閉
	const handleClose = () => {
		Store.FuturesSPSL.clearUpdate();
		Store.FuturesSPSL.clearSPSL();
		close();
	};
	// 設置止盈止損
	const submit = () => {
		Store.FuturesSPSL.setSPSL()
			.then(() => {
				notification.success({ message: "设置止盈止损成功" });
				Store.Position.start(false);
				handleClose();
			})
			.catch(({ message }) => {
				notification.error({ message });
				handleClose();
			});
	};

	return (
		<div className={styles.spslModal}>
			<div className={styles.close} onClick={close}>
				<Close />
			</div>
			<div className={styles.header}>
				<span className={styles.title}>交易设置</span>
			</div>
			<div className={styles.oneLine}>
				<div>
					{name}({contractCode})
				</div>
				<b className={orderInfo.income > 0 ? "red" : "green"}>
					{RESTRICT.float(orderInfo.income, 2)}
					<span> 元</span>
				</b>
			</div>
			<div className={styles.sliderBox}>
				<div className={styles.sliderTitle}>
					<div>止盈金额</div>
					<div>
						<b className={"red"}>{stopProfit}</b>元({stopProfitPrice})
					</div>
				</div>
				<div className={styles.controller}>
					<div className={styles.btn} onClick={() => updateProfit(false)}>
						<TradeIcon img="loss" />
					</div>
					<Slider
						className={"trade-setting stopProfit"}
						min={1}
						max={stopProfitStep}
						value={stopProfitIndex}
						onChange={(val) => Store.FuturesSPSL.setStopProfit(val)}
					/>
					<div className={styles.btn} onClick={() => updateProfit(true)}>
						<TradeIcon img="add" />
					</div>
				</div>
			</div>
			<div className={styles.sliderBox}>
				<div className={styles.sliderTitle}>
					<div>止损金额</div>
					<div>
						<b className={"green"}>{stopLoss}</b>元({stopLossPrice})
					</div>
				</div>
				<div className={styles.controller}>
					<div className={styles.btn} onClick={() => updateLoss(false)}>
						<TradeIcon img="loss" />
					</div>
					<Slider
						className={"trade-setting stopLoss"}
						min={stopLossStepMin < 0 ? stopLossStepMin + 1 : stopLossStepMin}
						step={1}
						max={stopLossStep}
						value={stopLossIndex < stopLossStepMin ? stopLossStepMin : stopLossIndex}
						onChange={(val) => Store.FuturesSPSL.setStopLoss(val)}
					/>
					<div className={styles.btn} onClick={() => updateLoss(true)}>
						<TradeIcon img="add" />
					</div>
				</div>
			</div>
			<div className={styles.btnBox}>
				<div onClick={handleClose}>取消</div>
				<div className={styles.confirm} onClick={submit}>
					确定
				</div>
			</div>
		</div>
	);
});

export const SPSL = observer(({ close, id }) => {
	const { list = [] } = Store.Position;
	const {
		commodityCode,
		isBuy,
		stopProfit,
		stopProfitMax = 0,
		stopProfitMin = 0,
		stopProfitPrice,
		stopProfitPriceMin,
		stopProfitPriceMax,
		stopLoss,
		stopLossMax = 0,
		stopLossPrice,
		stopLossPriceMax,
		stopLossPriceMin,
		margin,
		priceDigit,
	} = Store.FuturesSPSL.SPSL;
	const { stopLossMin } = Store.FuturesSPSL;
	const [switchType, setSwitchType] = useState(0);
	const [profit, setProfit] = useState(stopProfit);
	const [loss, setLoss] = useState(stopLoss);

	const [profitPrice, setProfitPrice] = useState(stopProfitPrice);
	const [lossPrice, setLossPrice] = useState(stopLossPrice);

	useEffect(() => {
		Store.FuturesSPSL.setCryptoStopProfit(profit);
	}, [profit]);

	useEffect(() => {
		Store.FuturesSPSL.setCryptoStopLoss(loss);
	}, [loss]);
	useEffect(() => {
		Store.FuturesSPSL.setCryptoStopProfitPrice(profitPrice);
	}, [profitPrice]);

	useEffect(() => {
		Store.FuturesSPSL.setCryptoStopLossPrice(lossPrice);
	}, [lossPrice]);
	const min = useMemo(() => 1 / Math.pow(10, priceDigit), [priceDigit]);
	const orderInfo = useMemo(() => list.find((item) => item.id === id), [list, id]);
	if (Object.keys(Store.FuturesSPSL.SPSL).length === 0) return;

	// 取消或關閉
	const handleClose = () => {
		Store.FuturesSPSL.clearUpdate();
		Store.FuturesSPSL.clearSPSL();
		close();
	};
	// 止盈金額輸入
	const validStopProfit = (e) => {
		let val = e.target.value;
		if (isNaN(val)) return;
		if (val.indexOf(".") !== -1) {
			if (val.split(".")[1].length > 2) return;
		}
		setProfit(val);
	};
	// 止損金額輸入
	const validStopLoss = (e) => {
		let val = e.target.value;
		if (val.indexOf(".") !== -1) {
			if (val.split(".")[1].length > 2) return;
		}
		if (stopLossMin.toFixed(1) > 0) {
			if (+val > +stopLossMin) {
				val = stopLossMin.toFixed(1);
			}
		}
		setLoss(val);
	};
	// 止盈金額加減按鈕
	const updateProfit = (isAdd) => {
		if (isAdd) {
			if (+profit + 1 > stopProfitMax.mul(margin)) return setProfit(stopProfitMax.mul(margin));
			if (+profit + 1 < stopProfitMin.mul(margin)) return setProfit(stopProfitMin.mul(margin));
			setProfit(+profit + 1);
		} else {
			if (+profit - 1 < stopProfitMin.mul(margin)) return setProfit(stopProfitMin.mul(margin));
			setProfit(+profit - 1);
		}
	};
	// 止損金額加減按鈕
	const updateLoss = (isAdd) => {
		if (isAdd) {
			if (+loss - 1 < stopLossMax.mul(margin)) {
				return setLoss(stopLossMax.mul(margin));
			}
			setLoss(+loss - 1);
		} else {
			if (+loss + 1 > +stopLossMin) {
				return setLoss(stopLossMin.toFixed(0));
			}
			setLoss(+loss + 1);
		}
	};
	// 止盈價格輸入
	const validProfitPrice = (e) => {
		let val = e.target.value;
		if (isNaN(val)) return;
		if (+val > +stopProfitPriceMax) return setProfitPrice(stopProfitPriceMax);
		if (val.indexOf(".") !== -1) {
			if (val.split(".")[1].length > priceDigit) {
				return;
			}

			if (val.split(".")[0].length > 5) return;
		} else {
			if (val.split(".")[0].length > 5) return;
		}
		setProfitPrice(val);
	};
	// 止損價格輸入
	const validLossPrice = (e) => {
		let val = e.target.value;
		if (isNaN(val)) return;
		if (isBuy) {
			if (+val > +stopLossPriceMin) return setLossPrice(stopLossPriceMin);
		} else {
			if (+val > +stopLossPriceMax) return setLossPrice(stopLossPriceMax);
		}
		if (val.indexOf(".") !== -1) {
			if (val.split(".")[0].length > 5) return;
			if (val.split(".")[1].length > +priceDigit) return;
		} else {
			if (val.length > 5) return;
		}
		setLossPrice(val);
	};
	// 止盈價格加減按鈕
	const updateProfitPrice = (isAdd) => {
		if (isAdd) {
			const addPrice = +stopProfitPrice.add(min).toFixed(priceDigit);
			if (isBuy) {
				if (addPrice >= +stopProfitPriceMax) return setProfitPrice(stopProfitPriceMax);
				if (addPrice <= +stopProfitPriceMin) return setProfitPrice(stopProfitPriceMin);
			} else {
				if (addPrice <= +stopProfitPriceMin) return setProfitPrice(stopProfitPriceMin);
			}
			if (addPrice > +stopProfitPriceMax) return;
			setProfitPrice(addPrice);
		} else {
			const subPrice = +stopProfitPrice.sub(min).toFixed(priceDigit);
			if (subPrice < +stopProfitPriceMin) return;
			setProfitPrice(subPrice);
		}
	};
	// 止損價格加減按鈕
	const updateLossPrice = (isAdd) => {
		if (isAdd) {
			const addPrice = +stopLossPrice.add(min).toFixed(priceDigit);
			if (isBuy) {
				if (addPrice <= stopLossPriceMax) return setLossPrice(stopLossPriceMax);
				if (addPrice >= +stopLossPriceMin) return setLossPrice(stopLossPriceMin);
			} else {
				if (addPrice <= +stopLossPriceMin) return setLossPrice(stopLossPriceMin);
				if (addPrice >= +stopLossPriceMax) return setLossPrice(stopLossPriceMax);
			}
			setLossPrice(addPrice);
		} else {
			const subPrice = +stopLossPrice.sub(min).toFixed(priceDigit);
			if (isBuy) {
				if (subPrice <= +stopLossPriceMax) return setLossPrice(stopLossPriceMax);
			} else {
				if (subPrice <= +stopLossPriceMin) return setLossPrice(stopLossPriceMin);
			}
			setLossPrice(subPrice);
		}
	};

	const submit = () => {
		if (+stopProfit < +stopProfitMin.mul(margin)) {
			Store.FuturesSPSL.setCryptoStopProfit(stopProfitMin.mul(margin));
		}
		if (+stopLoss > +stopLossMin) {
			Store.FuturesSPSL.setCryptoStopLoss(+stopLossMin);
		}
		if (+stopLoss < +stopLossMax.mul(margin)) {
			Store.FuturesSPSL.setCryptoStopLoss(+stopLossMax.mul(margin));
		}

		Store.FuturesSPSL.setSPSL()
			.then(() => {
				notification.success({ message: "设置止盈止损成功" });
				Store.Position.start(false);
				handleClose();
			})
			.catch(({ message }) => {
				notification.error({ message });
				handleClose();
			});
	};

	return (
		<div className={styles.spslModal}>
			<div className={styles.close} onClick={handleClose}>
				<Close />
			</div>
			<div className={arrayToStr([styles.oneLine, styles.switchBtn])}>
				<div className={switchType === 0 ? styles.active : ""} onClick={() => setSwitchType(0)}>
					按金额
				</div>
				<div className={switchType === 1 ? styles.active : ""} onClick={() => setSwitchType(1)}>
					按价格
				</div>
			</div>
			<div className={styles.oneLine}>
				<div>
					<TradeIcon img={`goods/${goodsImg[commodityCode]}`} />
					{commodityCode.includes("USDT") ? commodityCode.replace("USDT", "/USDT") : commodityCode}
				</div>
				<b className={orderInfo.income > 0 ? "red" : "green"}>
					{RESTRICT.float(orderInfo.income, 2)}
					<span> 元</span>
				</b>
			</div>
			<div className={styles.twoLine}>
				<label>预计盈利</label>
				<div className={styles.inputBox}>
					<input
						type="text"
						min={switchType === 0 ? stopProfitMin : stopProfitPriceMin}
						max={switchType === 0 ? stopProfitMax : stopProfitPriceMax}
						placeholder={
							switchType === 0
								? `${Number(stopProfitMin).mul(margin)} ~ ${Number(stopProfitMax).mul(margin)}`
								: `${stopProfitPriceMin} ~ ${stopProfitPriceMax}`
						}
						value={switchType === 0 ? stopProfit : stopProfitPrice}
						onChange={switchType === 0 ? validStopProfit : validProfitPrice}
						onBlur={(e) => {
							const value = e.target.value;

							if (switchType === 0) {
								if (+value <= stopProfitMin.mul(margin)) {
									setProfit(stopProfitMin.mul(margin));
								}
								if (isNaN(value)) {
									setProfit(stopProfitMin.mul(margin));
								}
							} else {
								if (isBuy) {
									if (+value >= +stopProfitPriceMax) return setProfitPrice(stopProfitPriceMax);
									if (+value <= +stopProfitPriceMin) return setProfitPrice(stopProfitPriceMin);
								} else {
									if (+value <= +stopProfitPriceMin) return setProfitPrice(stopProfitPriceMin);
								}
							}
						}}
					/>
					<div className={styles.controller}>
						<div
							className={styles.btn}
							onClick={() => (switchType === 0 ? updateProfit(true) : updateProfitPrice(true))}
						>
							<TradeIcon img="add" />
						</div>
						<div
							className={styles.btn}
							onClick={() => (switchType === 0 ? updateProfit(false) : updateProfitPrice(false))}
						>
							<TradeIcon img="loss" />
						</div>
					</div>
				</div>
				<div>
					<div>
						<span>止盈价：</span>
						<b>{switchType === 0 ? stopProfitPrice : stopProfit}</b>
					</div>
					<div>
						<span>盈利百分比：</span>
						<b>{stopProfit.div(margin).mul(100).toFixed(2)}%</b>
					</div>
				</div>
			</div>
			<div className={styles.twoLine}>
				<label>预计亏损</label>
				<div className={styles.inputBox}>
					<input
						type="text"
						min={switchType === 0 ? +stopLossMax?.mul(margin) : isBuy ? stopLossPriceMax : stopLossPriceMin}
						max={switchType === 0 ? +stopLossMin?.mul(margin) : isBuy ? stopLossPriceMin : stopLossPriceMax}
						placeholder={
							switchType === 0
								? `${stopLossMin.toFixed(0)} ~ ${+stopLossMax.mul(margin).toFixed(0)}`
								: isBuy
								? `${stopLossPriceMax} ~ ${stopLossPriceMin}`
								: `${stopLossPriceMin} ~ ${stopLossPriceMax}`
						}
						value={switchType === 0 ? (Math.abs(loss) === stopLoss ? loss : stopLoss) : stopLossPrice}
						onChange={switchType === 0 ? validStopLoss : validLossPrice}
						onBlur={(e) => {
							const value = e.target.value;
							if (switchType === 0) {
								if (+value > +stopLossMin) {
									setLoss(stopLossMin);
								}
								if (value === "" || +value < +stopLossMax.mul(margin)) {
									setLoss(+stopLossMax.mul(margin));
								}
								if (isNaN(value)) {
									setLoss(stopLossMin);
								}
							} else {
								if (isBuy) {
									if (+value <= +stopLossPriceMax) return setLossPrice(stopLossPriceMax);
									if (+value >= +stopLossPriceMin) return setLossPrice(stopLossPriceMin);
								} else {
									if (+value <= +stopLossPriceMin) return setLossPrice(stopLossPriceMin);
								}
							}
						}}
					/>
					<div className={styles.controller}>
						<div className={styles.btn} onClick={() => (switchType === 0 ? updateLoss(true) : updateLossPrice(true))}>
							<TradeIcon img="add" />
						</div>
						<div className={styles.btn} onClick={() => (switchType === 0 ? updateLoss(false) : updateLossPrice(false))}>
							<TradeIcon img="loss" />
						</div>
					</div>
				</div>
				<div>
					<div>
						<span>止损价：</span>
						<b>{switchType === 0 ? stopLossPrice : Math.abs(loss) === stopLoss ? loss : stopLoss}</b>
					</div>
					<div>
						<span>亏损百分比：</span>
						<b>{Math.abs(stopLoss).div(margin).mul(100).toFixed(2)}%</b>
					</div>
				</div>
			</div>
			<div className={styles.btnBox}>
				<div onClick={handleClose}>取消</div>
				<div className={styles.confirm} onClick={submit}>
					确定
				</div>
			</div>
		</div>
	);
});
