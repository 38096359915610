import { makeAutoObservable, runInAction } from "mobx";
import EVENT from "@/library/api";
import Brand from "../core/brand";
import { RESTRICT } from "../library/standard";
import Store from ".";
import Storage from "../library/storage";

export class WalletStore {
	allWallet = [];
	totalAssets;
	totalSpotAssets;
	walletData = {}; //对象形式
	walletList = []; //数组形式
	walletRateList = [];
	currencyList = [];
	_loop = null;

	constructor() {
		makeAutoObservable(this);
	}

	//todo 获取币种列表
	updateCurrencyList() {
		EVENT.Assets.getCurrencyList().then(({ data }) => (this.currencyList = data));
	}

	//todo 获取账户现货钱包
	async updateWallet(status = false) {
		if (!Store.User.isLogin) return;
		let val = await Storage.getItem("_wallet_");
		if (val !== null && status) {
			runInAction(() => (this.walletList = JSON.parse(val)));
		} else {
			const { data } = await EVENT.Assets.getMyAssets();
			runInAction(() => {
				this.walletList = data;
			});
			if (data) {
				Storage.setItem("_wallet_", JSON.stringify(data), 3600000).then(() => console.info("钱包信息缓存成功"));
			}
		}
		if (!this._loop) {
			this.updateWalletRates();
		}
	}

	//todo 持续刷新资产信息
	startUpdate() {
		this._loop = setInterval(() => this.updateWallet(false), 5000);
	}

	//todo 结束刷新资产信息
	endUpdate() {
		clearInterval(this._loop);
		this._loop = null;
	}

	//todo 获取钱包币种汇率
	async updateWalletRates() {
		let total = 0,
			spotTotal = 0;
		const { data, list } = await EVENT.Assets.getCryptoRates(Brand.supportCurrency);
		this.walletData = data;
		this.walletRateList = list;
		this.walletList.forEach((item) => {
			item.rate = this.walletData[item.currency];
			item.total = RESTRICT.float(+item.money.mul(+this.walletData[item.currency]), 2);
			if (item.total !== 0) {
				total += +item.total; //账户总资产
				if (item.currency !== "CNY") {
					spotTotal += +item.total; //现货总资产
				}
			}
			this.totalAssets = RESTRICT.float(total, 2);
			this.totalSpotAssets = RESTRICT.float(spotTotal, 2);
		});
	}

	getCurrency(currency) {
		if (currency === "") return console.warn("大锅，没传要查的钱包名字");
		if (this.walletList.length > 0) {
			return this.walletList.find((o) => o.currency === currency);
		} else {
			return { money: 0 };
		}
	}
}
