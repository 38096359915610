import service from "../network/axios";

//todo 获取banner + notice;
const getCarousel = async () => {
	const o = { action: "carousel" };
	const result = await service.get("/api/index.htm", { params: o });
	return result;
};

//todo 获取商品新闻;
const getCommodityNews = async (type, date, device, page = 1, size = 10) => {
	const o = {
		type,
		date,
	};
	if (device === "pc") {
		o.size = size;
		o.page = page;
		o.date = date;
	}
	const result = await service.get("/api/news/newsList.htm", {
		params: o,
	});
	return result;
};

/**
 * 获取币圈新闻
 * @param {*} type 1-贵金属 6-石油 12-外汇 19-数字货币 22-科技 24-地缘局势 27-美股 28-港股 29-A股 35-经济 36-期货
 * @returns
 */
const getTypeNews = (type) => {
	return new Promise(async (resolve, reject) => {
		try {
			await fetch(`https://classify-ws.jin10.com:5142/flash?classify=[${type}]`)
				.then((response) => response.json())
				.then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					}
					reject(res.message);
				});
		} catch (e) {
			reject(e);
		}
	});
};

/**
 * 留言板
 */
const getMessage = async () => {
	const o = {
		action: "more",
		size: 50,
		_: new Date(),
	};
	const result = await service.get("/api/home/kefu.htm", { params: o });
	return result;
};

/**
 * 获取日历新闻
 * @param {string} date 时间戳
 */
const getCalendar = async (date) => {
	const o = { date };
	const result = await service.get("/api/news/calendar.htm", { params: o });
	return result;
};

/**
 * 获取新闻详情
 * @param {string} id  新闻ID
 */
const getDetail = async (id) => {
	const o = { id };
	const result = service.get("/api/news/newsDetail", { params: o });
	return result;
};

const getWealth = async () => {
	const o = { type: 2 };
	const result = service.get("/api/discover/index.htm", { params: o });
	return result;
};

export default {
	getCarousel,
	getCommodityNews,
	getTypeNews,
	getMessage,
	getCalendar,
	getDetail,
	getWealth,
};
