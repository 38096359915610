import React, { useState, useMemo, useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker, message, Pagination, notification, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
// lib
import EVENT from "@/library/api";
// utils
import { arrayToStr, isEmpty, formatDate, splitAry } from "@/utils";
import { statusText, goodsImg } from "@/utils/common";
// components
import { TradeIcon } from "@/view/components/Svg";
// assets
import funds from "@assets/common/funds_light.png";
// style
import styles from "./index.module.scss";

const { Option } = Select;

const CryptoDetail = () => {
  const [currency, setCurrency] = useState("");
  const [payment, setPayment] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterType, setFilterType] = useState("");
  const [cryptoInfo, setCryptoInfo] = useState({ list: [], total: 0 });
  const [page, setPage] = useState(1);
  useEffect(() => {
    updateRechargeRecord({});
    getCryptoList();
    setCurrency("USDT");
  }, []);
  const disabledStartDate = useMemo(() => {
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  }, [startDate, endDate]);
  const disabledEndDate = useMemo(() => {
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  }, [startDate, endDate]);

  const getCryptoList = () => {
    EVENT.Payment.getDepositCurrency()
      .then(({ data }) => {
        setPayment(data);
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };

  const quickSearch = (type) => {
    let today = new Date();
    let tdd = String(today.getDate()).padStart(2, "0");
    let tmm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let tyyyy = today.getFullYear();

    let startDate = new Date();
    let sdd, smm, syyyy;
    switch (type) {
      case "DAY":
        startDate.setDate(startDate.getDate());
        sdd = String(startDate.getDate()).padStart(2, "0");
        smm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        syyyy = startDate.getFullYear();
        break;
      case "WEEK":
        const day = startDate.getDay() || 7;
        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + 0 - day
        );
        sdd = String(startDate.getDate() + 1).padStart(2, "0");
        smm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        syyyy = startDate.getFullYear();
        break;
      case "MONTH":
        sdd = "1";
        smm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        syyyy = startDate.getFullYear();
        break;
      default:
        break;
    }

    startDate = `${syyyy}-${smm}-${sdd} ${"00"}:${"00"}:${"00"}`;
    let endDate = `${tyyyy}-${tmm}-${tdd} ${23}:${59}:${59}`;

    let tempStart = Date.parse(startDate);
    tempStart = dayjs(new Date(tempStart));
    let tempEnd = Date.parse(endDate);
    tempEnd = dayjs(new Date(tempEnd));
    updateRechargeRecord({ page: 1, startDate, endDate, currency });
    setFilterType(type);
    setStartDate(tempStart);
    setEndDate(tempEnd);
    if (page > 1) {
      setPage(1);
    }
  };

  const search = () => {
    if (!startDate) return message.warning("请选择起始时间");
    const start = !!startDate && startDate.format("YYYY-MM-DD HH:mm:ss");
    const end = !!endDate && endDate.format("YYYY-MM-DD HH:mm:ss");
    updateRechargeRecord({ page: 1, startDate: start, endDate: end, currency });
    if (filterType) {
      setFilterType("");
    }
    if (page > 1) {
      setPage(1);
    }
  };
  const updateRechargeRecord = (params) => {
    const {
      startDate,
      endDate,
      page = 999,
      currency,
      currencyType = 0,
      transfer = false,
      type = 100,
    } = params;
    EVENT.History.depositWithdraw({
      page,
      rows: 10,
      createTimeGe: startDate,
      createTimeLe: endDate,
      transfer,
      currency,
      currencyType,
      type,
    })
      .then(({ data }) => {
        let o = [];
        if (!isEmpty(data)) {
          o = data.filter((item) => !!item.address);
        }
        setCryptoInfo({ list: splitAry(o, 10), total: o.length });
      })
      .catch(({ message }) => notification.error({ message }));
  };

  const onChangePage = (page) => {
    if (page - 1 >= cryptoInfo.list.length) return;
    setPage(Number(page));
    // updateRechargeRecord({ page, startDate, endDate, currency });
  };

  return (
    <div className={styles.detailContent}>
      <div className={styles.title}>
        <div className={styles.dateBox}>
          <div className={styles.subTitle}>币种</div>
          <Select
            className={"select_box"}
            value={currency}
            onChange={(v) => setCurrency(v)}
          >
            {payment.map((key) => (
              <Option key={key} value={key}>
                <div className="option">
                  <TradeIcon
                    img={`goods/${goodsImg[key]}`}
                    svgClass={styles.code}
                  />
                  <b>{key}</b>
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.dateBox}>
          <div className={styles.subTitle}>日期</div>
          <div className={styles.dateWrapper}>
            <DatePicker
              style={{ width: 140 }}
              locale={locale}
              disabledDate={disabledStartDate}
              format={"YYYY-MM-DD"}
              value={startDate}
              onChange={(val) => setStartDate(val)}
              placeholder="开始日期"
            />
            <span className={styles.line}> - </span>
            <DatePicker
              style={{ width: 140 }}
              locale={locale}
              disabledDate={disabledEndDate}
              format={"YYYY-MM-DD"}
              value={endDate}
              onChange={(val) => setEndDate(val)}
              placeholder="结束日期"
            />
          </div>
        </div>
        <div className={styles.searchButtonGroup}>
          <div className={styles.quickSearchBox}>
            <div
              onClick={() => quickSearch("DAY")}
              className={arrayToStr([
                styles.quickSearch,
                filterType === "DAY" ? styles.active : "",
              ])}
            >
              本日
            </div>
          </div>
          <div className={styles.quickSearchBox}>
            <div
              onClick={() => quickSearch("WEEK")}
              className={arrayToStr([
                styles.quickSearch,
                filterType === "WEEK" ? styles.active : "",
              ])}
            >
              本周
            </div>
          </div>
          <div className={styles.quickSearchBox}>
            <div
              onClick={() => quickSearch("MONTH")}
              className={arrayToStr([
                styles.quickSearch,
                filterType === "MONTH" ? styles.active : "",
              ])}
            >
              本月
            </div>
          </div>
          <div className={styles.quickSearchBox}>
            <div onClick={search} className={styles.searchButton}>
              搜索
            </div>
          </div>
        </div>
      </div>
      <ol>
        <li className={styles.header}>
          <div>时间</div>
          <div>充值币种</div>
          <div>金额(元)</div>
          <div className={styles.longer}>充币地址</div>
          <div>ID</div>
          <div>状态</div>
        </li>
        {!isEmpty(cryptoInfo.list) ? (
          cryptoInfo.list[page - 1].map((item, key) => (
            <li key={key}>
              <div>{formatDate("m-d h:i:s", { date: item.time })}</div>
              <div>{item.srcCurrency}</div>
              <div>{item.money}</div>
              <div className={styles.longer}>{item.address}</div>
              <div>{item.id}</div>
              <div>{statusText(item.status)}</div>
            </li>
          ))
        ) : (
          <div className={styles.empty}>
            <div>
              <img src={funds} alt="" />
              <b>暂无记录</b>
            </div>
          </div>
        )}
      </ol>
      <div className="pagination">
        <Pagination
          onChange={onChangePage}
          total={cryptoInfo.total}
          defaultPageSize={10}
          current={page}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default CryptoDetail;
