import React, { useState, useMemo } from "react";
// import { toJS } from "mobx";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
// core
import Store from "@/store";
// utils
import { isEmpty } from "@/utils";
import { goodsImg } from "@/utils/common";

// components
import { HomeIcon, TradeIcon } from "@/view/components/Svg";
// style
import styles from "../home.module.scss";
// assets

const Quote = () => {
	const [navList] = useState([
		{
			key: 0,
			img: ["shuzihuobiS2", "shuzihuobiWS2"],
			jump: "crypto",
			codes: ["BTCUSDT", "ETHUSDT", "XRPUSDT", "LTCUSDT"],
		},
		{ key: 1, img: ["jingxuangangguS2", "jingxuangangguWS2"], jump: "stock", codes: ["9988", "9618", "1810", "3690"] },
		{ key: 2, img: ["guzhiqihuoS2", "guzhiqihuoWS2"], jump: "futures", codes: ["HSI", "DAX", "NQ", "IF"], tab: 2 },
		{ key: 3, img: ["shangpinqihuoS2", "shangpinqihuoWS2"], jump: "futures", codes: ["CL", "GC", "HG", "HSI"], tab: 1 },
		{
			key: 4,
			img: ["waihuizaiquanS2", "waihuizaiquanWS2"],
			jump: "foreignE",
			codes: ["EURUSD", "USDCAD", "AUDUSD", "USDJPY"],
		},
	]);
	const [activeKey, setActiveKey] = useState(0);

	const activeNav = useMemo(() => navList.find((item) => item.key === activeKey), [navList, activeKey]);

	return (
		<section className={styles.hotQuoteWrapper}>
			<div className={styles.main}>
				<h2 className={styles.title}>热门实时行情</h2>
				<div className={styles.titleNav}>
					{navList.map((item) => (
						<div key={item.key} className={styles.item} onClick={() => setActiveKey(item.key)}>
							<HomeIcon
								img={item.img[item.key === activeKey ? 0 : 1]}
								style={{ width: item.key === activeKey ? 120 : 60, height: 34 }}
							/>
						</div>
					))}
				</div>
				<List list={activeNav.codes} jump={activeNav.jump} code={activeNav.codes[0]} tab={activeNav?.tab} />
			</div>
		</section>
	);
};

const List = observer(({ list, jump, code, tab }) => {
	const { total } = Store.Brief;
	return (
		<>
			<div className={styles.hotList}>
				{!isEmpty(total) &&
					list
						.filter((key) => !!total[key])
						.map((key) => {
							const item = total[key];
							return (
								<div key={key} className={styles.goodsInfo}>
									<TradeIcon img={`goods/${goodsImg[item.id]}`} svgClass={styles.code} />
									<div className={styles.name}>{item.name}</div>
									<div className={styles.contract}>
										{jump === "stock" ? (item.id.length === 3 ? "00" : "0") : ""}
										{item.id}
									</div>
									<div className={styles.price}>
										<div>
											<b className={item.isUp ? "red" : "green"}>{item.price}</b>
											<HomeIcon img={item.isUp ? "up" : "down"} svgClass={styles.updown} />
										</div>
									</div>
									{item.isOpen ? (
										<div className={`${styles.rate} ${item.isUp ? "red" : "green"}`}>
											<div>{item.gap}</div>
											<div>{item.rate}</div>
										</div>
									) : (
										<div className={`${styles.rate} ${styles.rest}`}>
											<div>休市</div>
										</div>
									)}
									<div className={`${styles.goTrade} ${item.isOpen ? "" : styles.rest}`}>
										<Link to={`/trade/${jump}/${item.id}`} state={tab}>
											立即交易
										</Link>
									</div>
								</div>
							);
						})}
			</div>
			<div className={styles.more}>
				<Link to={`/trade/${jump}/${code}`} state={tab}>
					查看更多行情
				</Link>
			</div>
		</>
	);
});
export default Quote;
