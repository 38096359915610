import Decimal from "decimal.js";

export const RESTRICT = {
  number(v) {
    if (v === "") return 0;
    const c = Math.trunc(v * 100) / 100;
    if (v.indexOf(".") !== -1 && v.split(".")[1].length > 2) {
      return c.toString();
    } else {
      return c;
    }
  },
  float_back(v, digit = 2) {
    if (typeof v === "number") v = v.toString();
    if (v === "" || !v) return 0;
    let c;
    v = v.replace(/^\./g, "");
    v = v.replace(/\.{2,}/g, ".");
    v = v.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");

    if (v.indexOf(".") !== -1 && v.split(".")[1].length > 1) {
      if (Number(v) < 0.000001 && Number(v) > 0) return Number(v);
      c = Math.trunc(Number(v).mul(Math.pow(10, digit))).div(
        Math.pow(10, digit)
      );
    } else {
      c = v;
    }
    return Number(c);
  },
  float(v, digit = 2) {
    if (v === "" || !v) return 0;
    v = new Decimal(v);
    if (v < 0.000001 && v > 0) return v;
    return +Decimal.trunc(v.mul(Decimal.pow(10, digit))).div(
      Decimal.pow(10, digit)
    );
  },
  roundFloat(v, digit = 2) {
    // if(v.indexOf)
    if (typeof v === "number") v = v.toString();
    if (v === "") return 0;
    let c;
    // v = v.replace(/[^\d.]/g,"");
    v = v.replace(/^\./g, "");
    v = v.replace(/\.{2,}/g, ".");
    v = v.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    // v = v.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');

    if (v.indexOf(".") !== -1 && v.split(".")[1].length > 2) {
      if (Number(v) < 0.000001 && Number(v) > 0) return Number(v);
      c = Math.round(Number(v).mul(Math.pow(10, digit))).div(
        Math.pow(10, digit)
      );
    } else {
      c = v;
    }
    return Number(c);
  },
};
