import Storage from "@/library/storage";

//todo 合约配置缓存
export default class Cache {
  //产品key
  #key = null;
  #initial = false;
  #hasCache = false;
  #config = {};
  #queue = [];
  #lastUpdateTimeForTrade = 0;
  #lastUpdateTimeForContract = 0;
  #charge = {};

  constructor(key) {
    this.#key = key;
    Storage.getItem(this.#key)
      .then((data) => {
        if (data !== null) {
          this.#config = JSON.parse(data);
          this.#hasCache = true;
        }
        while (this.#queue.length > 0) {
          let resolve = this.#queue.pop();
          resolve(this.#hasCache);
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  }

  write(data) {
    Storage.setItem(this.#key, JSON.stringify(data), 3600000).catch((err) => {
      console.warn(err);
      setTimeout(() => this.write(data), 5000);
    });
  }
  //todo 向外传递产品id
  get id() {
    return this.#key;
  }

  get cache() {
    return new Promise((resolve) => {
      this.#initial ? resolve(this.#hasCache) : this.#queue.push(resolve);
    });
  }

  get lastUpdateTimeForTrade() {
    return this.#lastUpdateTimeForTrade;
  }

  get lastUpdateTimeForContract() {
    return this.#lastUpdateTimeForContract;
  }

  set charge(charge) {
    Object.assign(this.#charge, charge);
    this.#lastUpdateTimeForTrade = Date.now();
  }

  get charge() {
    return this.#charge;
  }

  //todo 修改合约配置
  set config(config) {
    config.lastUpdateTimeForContract = Date.now();
    Object.assign(this.#config, config);
    Storage.setItem(this.#key, JSON.stringify(config), 300000).catch((err) => {
      console.warn(err);
    });
  }

  //todo 传递合约配置
  get config() {
    return this.#config;
  }
}
