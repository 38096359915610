import React, { useState, useEffect } from "react";
import { notification } from "antd";
// lib
import EVENT from "@/library/api";
import { RESTRICT } from "@/library/standard";
// store
import Store from "@/store";
//utils
import { isEmpty, arrayToStr, formatDate } from "@/utils";
// components
import Empty from "./empty";
import Copy from "@/view/components/Copy";

// style
import styles from "../index.module.scss";

const DerivList = ({ list }) => (
	<>
		<div className={styles.header}>
			<i />
			<div className={styles.settleLong}>商品名称</div>
			<div>模式</div>
			<div>手数</div>
			<div>保证金</div>
			<div>开仓价</div>
			<div>平仓价</div>
			<div>盈亏(元)</div>
			<div className={styles.settleShort}>开仓时间</div>
			<div className={styles.settleShort}>平仓时间</div>
			<div className={arrayToStr([styles.settleLong, styles.id])}>订单号</div>
		</div>
		<div className={styles.listBox}>
			{!isEmpty(list) ? (
				list.map((item) => {
					const rate = item.income.div(item.margin).mul(100);
					return (
						<div key={item.id} className={styles.item}>
							<i className={item.isBuy ? styles.redB : styles.greenB} />
							<div className={styles.settleLong}>
								{item.commodity}({item.contCode})
							</div>
							<div>{item.moneyType === 0 ? "标准" : "迷你"}</div>
							<div>{item.volume}</div>
							<div>{item.margin}</div>
							<div>{item.opPrice}</div>
							<div>{item.cpPrice}</div>
							<div className={rate > 0 ? "red" : "green"}>{RESTRICT.float(item.income, 2)}</div>
							<div className={styles.settleShort}>{formatDate("m-d h:i:s", { date: item.time })}</div>
							<div className={styles.settleShort}>{formatDate("m-d h:i:s", { date: item.tradeTime })}</div>
							<div className={arrayToStr([styles.settleLong, styles.id])}>
								<p>{item.id}</p>
								<Copy text={item.id} className={styles.commonCopy} />
							</div>
						</div>
					);
				})
			) : (
				<Empty message="暂无交易记录" />
			)}
		</div>
	</>
);

const OtherList = ({ list }) => (
	<>
		<div className={styles.header}>
			<i />
			<div className={styles.settleLong}>商品名称</div>
			<div>杠杆</div>
			<div>保证金</div>
			<div>开仓价</div>
			<div>平仓价</div>
			<div>过夜天数</div>
			<div>盈亏(元)</div>
			<div className={styles.settleShort}>开仓时间</div>
			<div className={styles.settleShort}>平仓时间</div>
			<div className={arrayToStr([styles.settleLong, styles.id])}>订单号</div>
		</div>
		<div className={styles.listBox}>
			{!isEmpty(list) ? (
				list.map((item) => {
					const rate = item.income.div(item.margin).mul(100);
					return (
						<div key={item.id} className={styles.item}>
							<i className={item.isBuy ? styles.redB : styles.greenB} />
							<div className={styles.settleLong}>
								{item.commodity}({item.commodityCode})
							</div>
							<div>{`${item.lever}X`}</div>
							<div>{item.margin}</div>
							<div>{item.opPrice}</div>
							<div>{item.cpPrice}</div>
							<div>{item.deferDays}</div>
							<div className={rate > 0 ? "red" : "green"}>{RESTRICT.float(item.income, 2)}</div>
							<div className={styles.settleShort}>{formatDate("m-d h:i:s", { date: item.time })}</div>
							<div className={styles.settleShort}>{formatDate("m-d h:i:s", { date: item.tradeTime })}</div>
							<div className={arrayToStr([styles.settleLong, styles.id])}>
								<p>{item.id}</p>
								<Copy text={item.id} className={styles.commonCopy} />
							</div>
						</div>
					);
				})
			) : (
				<Empty message="暂无交易记录" />
			)}
		</div>
	</>
);

const Settlement = ({ tradeType }) => {
	const [list, setList] = useState([]);
	const isSimulate = Store.Quote.simulate;
	useEffect(() => {
		getHistory();
	}, [isSimulate, tradeType]);
	const getHistory = () => {
		EVENT.History.settleHistory()
			.then(({ data }) => {
				setList(
					data.filter((item) => {
						if (tradeType === "crypto") {
							return item.commodityZone === "crypto";
						} else if (tradeType === "futures") {
							return item.commodityZone === "deriv";
						} else if (tradeType === "foreignE") {
							return item.commodityZone === "forex";
						} else if (tradeType === "stock") {
							return item.commodityZone === "hstock";
						} else {
							return true;
						}
					})
				);
			})
			.catch((message) => notification.error({ message }));
	};

	return (
		<div className={arrayToStr([styles.positionList, styles.settleList])}>
			{tradeType === "futures" ? <DerivList list={list} /> : <OtherList list={list} />}
		</div>
	);
};

export default Settlement;
