import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// store
import Store from "@/store";
// lib
import EVENT from "@/library/api";
import { safeValue } from "@/library/safety";
// utils
import { mobileMask, emailMask, formatDate, arrayToStr } from "@/utils";
// compoents
import { UserIcon } from "@/view/components/Svg";
import { customModal } from "@/view/components/Modal";
import ChangeLoginPwd from "./changeLoginPwd";
import WithdrawPwd from "./withdrawPwd";
import BindPhone from "./bindPhone";
import BindEmail from "./bindEmail";
import ChangePhone from "./changePhone";
import ChangeEmail from "./changeEmail";
import CheckIn from "./checkIn";
import EditNickName from "./editNickName";

// style
import styles from "./index.module.scss";

const UserCenter = () => {
	const navigate = useNavigate();
	const [loginHistory, setLoginHistory] = useState([]);
	const { account, username, usernameNo, userId, phone, email, pw_w } = Store.User.accountInfo;
	useEffect(() => {
		EVENT.History.getLoginHistory().then(({ data }) => setLoginHistory(data));
	}, []);
	// 判断用户安全等级
	const lv = useMemo(() => {
		let lv = 1;
		if (safeValue(Store.User.accountInfo.phone, true) && safeValue(Store.User.accountInfo.email, true)) {
			lv += 1;
		}
		if (Store.User.accountInfo.pw_w > 0) {
			lv += 1;
		}
		if (Store.User.accountInfo.pw_w === 3) {
			lv += 1;
		}
		if (Store.User.accountInfo.pw_l === 3) {
			lv += 1;
		}
		return lv;
	}, [Store.User.accountInfo]);
	// 用户安全等级级别
	const level = useMemo(() => {
		if (lv <= 2) {
			return "低";
		} else if (lv > 2 && lv <= 4) {
			return "中";
		} else if (lv > 4) {
			return "高";
		}
	}, [lv]);

	const showCheckInModal = () => {
		customModal(<CheckIn />, false);
	};
	return (
		<div className={styles.userCenter}>
			<section className={styles.title}>
				<h5>个人中心</h5>
				<div className={styles.userBox}>
					<div className={styles.userInfo}>
						<UserIcon img="userIcon" />
						<div className={styles.info}>
							<div className={styles.name}>
								<b>{username}</b>
								{usernameNo === 0 && (
									<UserIcon
										img="edit"
										className={styles.editname}
										onClick={() => {
											customModal(<EditNickName />);
										}}
									/>
								)}
							</div>
							<div className={styles.id}>
								<div className={styles.phone}>{account === phone ? mobileMask(phone) : emailMask(email)}</div>
								<div>
									<label>UID:</label>
									{userId}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.loginInfo}>
						<span />
						<div>
							<p>
								上次登录时间:{" "}
								{formatDate("y-m-d h:i:s", {
									date: !!loginHistory[0] && loginHistory[0].time,
								})}
							</p>
							<p>IP: {!!loginHistory[0] && loginHistory[0].ip}</p>
							<Link to={"/account/loginHistory"}>查看登录历史记录</Link>
						</div>
					</div>
					<div className={styles.checkin} onClick={showCheckInModal}>
						<UserIcon img="checkin" />
						<span>签到</span>
					</div>
				</div>
			</section>
			<section className={styles.securityLevel}>
				<b>安全级别</b>
				<div className={styles.line}>
					<div className={lv <= 2 ? "red_bg" : lv <= 4 ? "yellow_bg" : "green_bg"} />
					<div className={lv <= 2 ? "" : lv > 2 && lv <= 4 ? "yellow_bg" : "green_bg"} />
					<div className={lv > 4 ? "green_bg" : ""} />
				</div>
				<div className={arrayToStr([styles.level, lv <= 2 ? "red_bg" : lv <= 4 ? "yellow_bg" : "green_bg"])}>
					{level}
				</div>
				<p>建议您启动全部安全设置，以保障账户及资金安全</p>
			</section>
			<section className={styles.nav}>
				<div className={styles.name}>
					<b>登录密码</b>
					<p>在登录个人账号时使用的密码</p>
				</div>
				<div
					className={styles.btn}
					onClick={() => {
						customModal(<ChangeLoginPwd accountInfo={Store.User.accountInfo} navigate={navigate} />);
					}}
				>
					修改
				</div>
			</section>
			<section className={styles.nav}>
				<div className={styles.name}>
					<b>资金密码</b>
					<p>用于申请提款时的安全认证</p>
				</div>
				<div
					className={styles.btn}
					onClick={() => {
						customModal(<WithdrawPwd accountInfo={Store.User.accountInfo} navigate={navigate} />);
					}}
				>
					{pw_w === 0 ? "设置" : "修改"}
				</div>
			</section>
			<section className={styles.nav}>
				<div className={styles.name}>
					<b>手机验证</b>
					<p>用于提现，修改密码，安全设置时用以收取验证短信</p>
				</div>
				<div
					className={styles.btn}
					onClick={() => {
						customModal(!phone ? <BindPhone /> : <ChangePhone accountInfo={Store.User.accountInfo} />);
					}}
				>
					{!phone ? "绑定" : "修改"}
				</div>
			</section>
			<section className={styles.nav}>
				<div className={styles.name}>
					<b>邮箱验证</b>
					<p>用于登录，找回密码，修改账户安全信息，提币等操作</p>
				</div>
				<div
					className={styles.btn}
					onClick={() => {
						customModal(!email ? <BindEmail /> : <ChangeEmail accountInfo={Store.User.accountInfo} />);
					}}
				>
					{!email ? "绑定" : "修改"}
				</div>
			</section>
		</div>
	);
};
export default observer(UserCenter);
