import { makeAutoObservable, runInAction } from "mobx";
import EVENT from "@/library/api";

export class News {
	banners = [];
	notices = [];
	initial = true;

	constructor() {
		makeAutoObservable(this);
	}

	getCarousel() {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await EVENT.News.getCarousel();
				runInAction(() => {
					this.banners = result.carousels;
					this.notices = result.notices;
				});
				this.initial = false;
				resolve(result);
			} catch (e) {
				console.log(e);
				reject(e);
			}
		});
	}
}
