/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Pagination, notification } from "antd";
import dayjs from "dayjs";

// utils
import { isEmpty, arrayToStr } from "@/utils";
// store
import Store from "@/store";
// compoents
import Layout from "@/view/components/Layout";
import AnnounceNotice from "./list";
import AnnounceDetail from "./detail";

// style
import styles from "./index.module.scss";

const Announcements = (props) => {
	const [noticeList, setNotice] = useState([]);
	useEffect(() => {
		getNotice();
	}, []);
	const getNotice = () => {
		Store.News.getCarousel()
			.then(({ notices }) => {
				setNotice(notices);
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	const params = useParams();
	const noticeInfo = useMemo(
		() => (params.id ? noticeList.find((item) => item.id === params.id) : {}),
		[params.id, noticeList]
	);
	const announceContent = useMemo(
		() => (isEmpty(params) ? <AnnounceNotice list={noticeList} /> : <AnnounceDetail item={noticeInfo} />),
		[params, noticeList, noticeInfo]
	);
	return (
		<Layout customClass={styles.announcementWrapper}>
			<main>
				<div className={styles.content}>{announceContent}</div>
			</main>
		</Layout>
	);
};
export default Announcements;
