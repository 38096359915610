import React from "react";
import { Select } from "antd";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

const { Option } = Select;

export default function SimpleSelect(props) {
  const {
    label,
    value,
    options,
    link,
    goTo,
    type,
    onChange,
    customClass,
    placeholder,
    disabled = false,
  } = props;
  return (
    <div className={`${styles.selectWrapper} ${customClass}`}>
      <div className={styles.title}>
        <label>{label}</label>
        {!!link && <Link to={`${goTo}`}>{link}</Link>}
      </div>
      <Select
        className={"simple_select_box"}
        value={value || null}
        onChange={(e) => onChange(e)}
        placeholder={placeholder}
        disabled={disabled}
      >
        {!!options &&
          options.map((item, key) => {
            return (
              <Option
                value={type === "bank" ? item.bank : item.value}
                key={key}
              >
                {!!item.icon && 1} {type === "bank" ? item.bank : item.value}
                {/* {item["bank"] === "支付宝"
                  ? `[${item.cardNumber.substr(item.cardNumber.length - 6)}]`
                  : `[${item.cardNumber.substr(item.cardNumber.length - 4)}]`} */}
                {type === "bank" &&
                  `[${item.cardNumber.substr(item.cardNumber.length - 4)}]`}
              </Option>
            );
          })}
      </Select>
    </div>
  );
}
