import React from "react";
import { NavLink, Link, Outlet, useLocation, Navigate } from "react-router-dom";
// compoents
import Layout from "@/view/components/Layout";
import { UserIcon } from "@/view/components/Svg";

// style
import styles from "./index.module.scss";

export default function Account() {
  const { pathname } = useLocation();
  if (["/account", "/account/"].includes(pathname)) {
    return <Navigate to={"/account/userCenter"} />;
  }
  return (
    <Layout customClass={styles.accountWrapper}>
      <div className={styles.box}>
        <main>
          <section className={styles.nav}>
            <div className={styles.title}>
              <UserIcon img="safety" />
              <b>账户安全</b>
            </div>
            <NavLink
              to={"/account/userCenter"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              个人中心
            </NavLink>
            <NavLink
              to={"/account/nameVerify"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              实名认证
            </NavLink>
            <NavLink
              to={"/account/loginHistory"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              账户活动
            </NavLink>
            <div className={styles.title}>
              <UserIcon img="asset" />
              <b>资金管理</b>
            </div>
            <NavLink
              to={"/account/myAsset"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              我的资产
            </NavLink>
            <NavLink
              to={"/account/recharge"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              充值
            </NavLink>
            <NavLink
              to={"/account/withdraw"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              提现
            </NavLink>
            <NavLink
              to={"/account/bankcard"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              银行卡
            </NavLink>
            <NavLink
              to={"/account/aliPay"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              支付宝
            </NavLink>
            <NavLink
              to={"/account/fundsDetail"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              资金明细
            </NavLink>
            <div className={styles.title}>
              <UserIcon img="partner" />
              <b>合伙人</b>
            </div>
            <NavLink
              to={"/account/inviteFriends"}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              邀请记录
            </NavLink>
            <Link to={"/cooperation/invite"}>邀请好友</Link>
          </section>
          <section className={styles.content}>
            <Outlet />
          </section>
        </main>
      </div>
    </Layout>
  );
}
