import React from "react";
import { Link, Navigate, useLocation, Outlet } from "react-router-dom";
// component
import { CommonIcon } from "@/view/components/Svg";
import CsAlert from "@/view/components/CsAlert";
// style
import styles from "./index.module.scss";

export default function Common() {
	const { pathname } = useLocation();
	if (["/common", "/common/"].includes(pathname)) {
		return <Navigate to={"/common/login"} />;
	}
	return (
		<div className={styles.commonWrapper}>
			<main>
				<Link to={"/"} className={styles.logo}>
					<CommonIcon img="logo" />
				</Link>
				<div className={styles.info}>
					<div className={styles.description}>
						<CommonIcon img="title" />
						<div>
							<CommonIcon img="registor" />
						</div>
					</div>
					<div className={styles.switchBox}>
						<div className={styles.common}>
							<Outlet />
						</div>
					</div>
				</div>
				<div className={styles.footer}>Copyright© 2003-2021 Cinda Capital Group. All Rights Reserved.</div>
				<CsAlert />
			</main>
		</div>
	);
}
