import Storage from "../library/storage";

//一键平仓开关
export default class OneClickClosing {
    #default = "true";
    #key = "_CX_OneClickClosing";
    #switch = null;

    constructor() {
        this.#switch = window.localStorage.getItem(this.#key) || this.#default;
    }

    setSwitch(val) {
       if (this.#switch === val) return;
        this.#switch = val;
        window.localStorage.setItem(this.#key, this.#switch);
    }

    get switch() {
        return this.#switch;
    }

}

