import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { getIdentity } from "@/utils";
import { notification, message } from "antd";
import EVENT from "@/library/api";

const ImgValid = (props) => {
	const { hide, account, type, sendCodeStatus, isMask = true } = props;
	const [hash, setHash] = useState(getIdentity(8));
	const [imgAddress, setAddress] = useState("");
	const [code, setCode] = useState("");

	useEffect(() => {
		setAddress(`/api/code/image.jpg?vHash=${hash}`);
	}, [hash]);

	const submit = () => {
		if (!account) return;
		if (code.length !== 4) return;
		EVENT.Account[account.indexOf("@") === -1 ? "sendSMS" : "sendEmail"]({
			type,
			account,
			code,
			hash,
		})
			.then(() => {
				message.success("验证码发送成功，请注意查收！");
				sendCodeStatus(true);
				if (hide) {
					hide();
				} else {
					props.close();
				}
			})
			.catch((e) => {
				setCode("");
				setHash(getIdentity(8));
				notification.error({ message: e.message, duration: 2 });
			});
	};
	const getCode = (e) => {
		const ele = e.target.value;
		if (ele.length > 4) {
			let o = ele.slice(0, 4);
			o = o.replace(/[^\d]/g, "");
			setCode(o);
		} else {
			let a = ele.replace(/[^\d]/g, "");
			setCode(a);
		}
	};
	return (
		<div className={`${styles.imgCover} ${isMask ? styles.isMask : ""}`}>
			<div className={styles.imgBox}>
				<p>获取验证码前，需先填写下面数字</p>
				<div className={styles.code}>
					<img className={styles.img} src={imgAddress} alt={"验证码"} onClick={() => setHash(getIdentity(8))} />
					<input type="tel" placeholder="请填写图片数字" maxLength={4} value={code} onInput={getCode} />
				</div>
				<div className={styles.btnBox}>
					<div
						onClick={() => {
							if (hide) {
								hide();
							} else {
								props.close();
							}
						}}
					>
						取消
					</div>
					<div onClick={() => submit()}>确定</div>
				</div>
			</div>
		</div>
	);
};

export default ImgValid;
