import React, { useState, useEffect } from "react";
import { Pagination, notification } from "antd";
// lib
import EVENT from "@/library/api";
// utils
import { isEmpty, formatDate, splitAry } from "@/utils";
// assets
import funds from "@assets/common/funds_light.png";
// style
import styles from "./index.module.scss";

const LuckyDetail = () => {
	const [luckyInfo, setLuckyInfo] = useState({ list: [], total: 0 });
	const [page, setPage] = useState(1);
	useEffect(() => {
		getRecord();
	}, []);
	const getRecord = () => {
		EVENT.History.getRecordHistory(4)
			.then(({ data }) => {
				setLuckyInfo({ list: splitAry(data, 10), total: data.length });
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	const onChangePage = (page) => {
		if (page - 1 >= luckyInfo.list.length) return;
		setPage(Number(page));
	};

	return (
		<div className={styles.detailContent}>
			<ol className={styles.eagleList}>
				<li className={styles.header}>
					<div>时间</div>
					<div>金额</div>
					<div>明细</div>
					<div>ID</div>
				</li>
				{!isEmpty(luckyInfo.list) ? (
					luckyInfo.list[page - 1].map((item, key) => (
						<li key={key}>
							<div>{formatDate("m-d-y h:i:s", { date: item.createTime })}</div>
							<div className={item.type === 300 ? styles.red : styles.green}>
								{item.type === 300 ? "+" : "-"}
								{Number(item.money).toFixed(2)}
							</div>
							<div>{item.explain}</div>
							<div>{item.id}</div>
						</li>
					))
				) : (
					<div className={styles.empty}>
						<div>
							<img src={funds} alt="" />
							<b>暂无记录</b>
						</div>
					</div>
				)}
			</ol>
			<div className="pagination">
				<Pagination
					onChange={onChangePage}
					total={luckyInfo.total}
					defaultPageSize={10}
					current={page}
					showSizeChanger={false}
				/>
			</div>
		</div>
	);
};

export default LuckyDetail;
