import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Carousel, notification } from "antd";
// lib
import EVENT from "@/library/api";
import { RESTRICT } from "@/library/standard";
// store
import Store from "@/store";
// compoents
import { TradeIcon } from "@/view/components/Svg";
// style
import styles from "../index.module.scss";
const TradeHeader = () => {
	const [noticeList, setNotice] = useState([]);
	const [accountAsset, setAccountAsset] = useState(Store.User.assets);
	const isSimulate = Store.Quote.simulate;
	const isLogin = Store.User.isLogin;
	const { assets, accountInfo } = Store.User;
	useEffect(() => {
		if (isLogin) {
			Store.User.updateAssets();
			Store.User.updateDetail();
		}
		getNotice();
	}, [isLogin]);
	useEffect(() => {
		// Store.User.updateAssets() 403 且accountInfo 有資料情況，將餘額資料使用 accountInfo 裡的餘額
		if (isLogin && !!accountInfo?.money) {
			setAccountAsset({
				money: assets.money || RESTRICT.float(accountInfo.money, 2),
				eagle: assets.eagle || RESTRICT.float(accountInfo.eagle, 2),
				prize: assets.prize || RESTRICT.float(accountInfo.prize, 2),
				game: assets.game || RESTRICT.float(accountInfo.game, 2),
			});
		}
	}, [isLogin, assets, accountInfo]);

	const getNotice = () => {
		Store.News.getCarousel()
			.then(({ notices }) => {
				setNotice(notices);
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	const changeSimulate = (type) => {
		if (!Store.User.isLogin) {
			return;
		}
		Store.Quote.switchSimulate(type);
		Store.Futures.commodityItem();
		Store.Position.startLimit(false);
		Store.Position.start(false);
	};
	const addCoin = () => {
		EVENT.Trade.addSimulateCoins()
			.then(() => {
				notification.success({ message: "模拟币添加成功" });
				Store.Wallet.updateWallet();
			})
			.catch(({ message }) => {
				notification.error({ message });
			});
	};
	return (
		<section className={styles.tradeHeader}>
			<div className={styles.left}>
				<TradeIcon img="notice" />
				<Carousel className={"notice_box"} dots={false} swiping={false} autoplay infinite>
					{noticeList.map((item, key) => {
						return (
							<p key={key}>
								<Link to={`/announcements/${item.id}`}>{item.title}</Link>
							</p>
						);
					})}
				</Carousel>
				<Link className={styles.more} to="/announcements">
					查看更多
				</Link>
			</div>
			<div className={styles.right}>
				{isSimulate ? (
					<div>
						<label>
							模拟币 <div onClick={addCoin}>加币</div>
						</label>
						<b>¥{accountAsset.game}</b>
					</div>
				) : (
					<div>
						<label>账户余额</label>
						<b>¥{accountAsset.money}</b>
					</div>
				)}
				<div>
					<label>礼金</label>
					<b>{accountAsset.prize}</b>
				</div>
				<div>
					<label>积分</label>
					<b>{accountAsset.eagle}</b>
				</div>
				<div className={styles.switchBtn}>
					<div className={!isSimulate ? styles.active : ""} onClick={() => changeSimulate(false)}>
						实盘
					</div>
					<div className={isSimulate ? styles.active : ""} onClick={() => changeSimulate(true)}>
						模拟
					</div>
				</div>
			</div>
		</section>
	);
};

export default observer(TradeHeader);
