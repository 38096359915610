import { Navigate, useLocation } from "react-router-dom";

export const RouterGuard = (route) => {
	const location = useLocation();
	if (!!route.auth && route.auth) {
		const isLogin = window.localStorage.getItem("isLogin");
		if (isLogin) {
			return route.element;
		} else {
			return <Navigate to={"/common/login"} replace state={{ from: location }} />;
		}
	} else {
		return route.element;
	}
};
