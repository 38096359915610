/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
// import { Popover } from "antd";
import Store from "@/store";
import styles from "../home.module.scss";
// utils
import { isEmpty } from "@/utils";
import { exInfo } from "@/utils/common";
// components
import Popover from "@/view/components/Popover";
import { HomeIcon } from "@/view/components/Svg";
// assets
import { ReactComponent as Triangle } from "@assets/common/triangle.svg";
import { ReactComponent as Shuzihuobi } from "@assets/home/shuzihuobi.svg";
import { ReactComponent as Ganggugupiao } from "@assets/home/ganggugupiao.svg";
import { ReactComponent as Shangpinqihuo } from "@assets/home/shangpinqihuo.svg";
import { ReactComponent as Waihuizaiquan } from "@assets/home/waihuizaiquan.svg";
import banner1 from "@assets/home/banner/banner01.png";

const Nav = () => (
  <div className={styles.tradeNav}>
    <Link to={"/cooperation/invite"}>邀请好友</Link>
    <Link to={"/cooperation/proxy"}>申请代理</Link>
  </div>
);

const TradeNav = () => (
  <div className={styles.tradeNav}>
    <Link
      to="/trade/crypto/BTCUSDT"
      onClick={() => Store.Quote.switchSimulate(false)}
    >
      <Triangle />
      <Shuzihuobi className={styles.textIcon} />
    </Link>
    <Link
      to="/trade/stock/9988"
      onClick={() => Store.Quote.switchSimulate(false)}
    >
      <Triangle />
      <Ganggugupiao className={styles.textIcon} />
    </Link>
    <Link
      to="/trade/futures/CL"
      onClick={() => Store.Quote.switchSimulate(false)}
    >
      <Triangle />
      <Shangpinqihuo className={styles.textIcon} />
    </Link>
    <Link
      to="/trade/foreignE/EURUSD"
      onClick={() => Store.Quote.switchSimulate(false)}
    >
      <Triangle />
      <Waihuizaiquan className={styles.textIcon} />
    </Link>
  </div>
);

const Header = () => {
  const { total } = Store.Brief;
  const [exList, setExList] = useState([]);
  useEffect(() => {
    if (!isEmpty(total)) {
      const exCodeList = ["CL", "GC", "NQ", "DAX", "NK", "HSI", "IF"];
      setExList(exCodeList.map((key) => total[key]));
      console.log(exCodeList.map((key) => total[key]));
    }
  }, [total]);

  return (
    <section className={styles.headerWrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.main}>
          <div className={styles.ex}>
            {!isEmpty(exList) &&
              exList.map((item, index) => {
                if (item == undefined) return;
                return (
                  <div key={index}>
                    <HomeIcon img={exInfo[index].img} />
                    <span>
                      <b>{exInfo[index].ex}:</b>
                      <i className={item.isOpen ? styles.open : ""}>
                        {item.isOpen ? "开盘中" : "休市"}
                      </i>
                    </span>
                  </div>
                );
              })}
          </div>
          <Link
            to={Store.User.isLogin ? "/account/userCenter" : "/common/login"}
            className={styles.userCenter}
          >
            <HomeIcon img="userCenter" />
            <span>个人中心</span>
          </Link>
        </div>
      </div>
      <div className={styles.header}>
        <div className={styles.main}>
          <HomeIcon img="logo" />
          <div className={styles.navBox}>
            <div className={styles.nav}>
              <Popover
                content={<TradeNav />}
                placement="bottom"
                className={styles.tradeNavModal}
              >
                <a>品类交易</a>
              </Popover>
            </div>
            <div className={styles.nav}>
              <Link to={"/activity"}>活动专区</Link>
            </div>
            <div className={styles.nav}>
              <Popover
                content={<Nav />}
                placement={"bottom"}
                className={styles.tradeNavModal}
              >
                <Link to={"/cooperation/invite"}>商务合作</Link>
              </Popover>
            </div>
            <div className={styles.nav}>
              <Link to={"/download"}>移动端下载</Link>
            </div>
            {!Store.User.isLogin && (
              <Link to={"/common/register"} className={styles.register}>
                免费开户
              </Link>
            )}
          </div>
        </div>
      </div>
      <a className={styles.homeBanner}>
        <img src={banner1} alt="" />
      </a>
    </section>
  );
};

export default observer(Header);
