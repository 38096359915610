import React, { useState } from "react";
import { notification } from "antd";
// utils
import { nameMask } from "@/utils";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import FormModal from "@/view/components/Modal/form";
import SimpleInput from "@/view/components/SimpleInput";
// style
import styles from "./index.module.scss";

export default function AddBankcard({ accountInfo, close }) {
  const [cardNumber, setCardNumber] = useState("");
  const { name } = accountInfo;

  const submit = () => {
    EVENT.Account.addAlipay(cardNumber)
      .then(() => {
        notification.success({
          message: "支付宝绑定成功",
          onClose: () => Store.User.updateDetail(),
        });
        close();
      })
      .catch(({ message }) => notification.error({ message }));
  };
  return (
    <FormModal title="添加支付宝" close={close}>
      <SimpleInput label={"开户姓名"} value={nameMask(name)} disable={true} />

      <SimpleInput
        label={"支付宝"}
        placeholder={"请输入支付宝账户"}
        type={"card"}
        maxlength={"30"}
        value={cardNumber}
        onChange={(val) => {
          setCardNumber(val);
        }}
      />

      <div
        className={`confirm_btn ${cardNumber.length < 9 ? "error" : ""} ${
          styles.confirmBtn
        }`}
        onClick={submit}
      >
        添加
      </div>
    </FormModal>
  );
}
