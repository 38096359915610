import service from "../network/axios";

//todo 获取竞猜排行榜
const getGameRaking = () => {
  const o = {
    type: 2,
  };
  return service.get("/api/quiz/ranking.htm", { params: o });
};

//todo 获取竞猜商品信息
const getCommodity = () => {
  return service.get("/api/quiz/commodity.htm");
};

//todo ?
const getIssue = (code, time) => {
  const o = {
    commodityCode: code,
    interval: time,
  };
  return service.get("/api/quiz/issue.htm", { params: o });
};

//todo 获取今日趋势
const getTodayTrend = (o) => {
  return service.get("/api/quiz/issues_of_day.htm", { params: o });
};

//todo 获取历史记录
const getGameHistory = () => {
  const o = {
    type: 2,
  };
  return service.get("/api/quiz/history.htm", { params: o });
};

const order = (o) => {
  return service.post("/api/quiz/open.htm", o);
};

//todo 获取活动内容;
const getActivity = async () => {
  const result = await service.get("api/lottery/blind");
  return result;
};

//todo 获取活动礼金
const getPrizes = async (lotteryId) => {
  const o = { lotteryId };
  const result = await service.get("api/lottery/prizes", { params: o });
  return result;
};

//todo 开启活动
const openActivity = async (id) => {
  const o = {
    lotteryId: id,
    blind: true,
  };
  const result = await service.post("api/lottery/open", o);
  return result;
};

export default {
  getGameRaking,
  getCommodity,
  getIssue,
  getTodayTrend,
  getGameHistory,
  order,
  getActivity,
  getPrizes,
  openActivity,
};
