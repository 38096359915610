import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

import styles from "./index.module.scss";

const MODAL_TYPE = {
	LOADING: "LOADING",
	FAIL: "FAIL",
	INFO: "INFO",
	DIALOG: "DIALOG",
	PANEL: "PANEL",
	CUSTOM: "CUSTOM",
	IMGVALID: "IMGVALID",
};

const DEFAULT_DURATION = 3;
const ONE_SECOND = 1000;

const Modal = (props) => {
	const [isMask] = useState(props.mask || [MODAL_TYPE.DIALOG, MODAL_TYPE.PANEL].includes(props.type));

	useEffect(() => {
		if (![MODAL_TYPE.DIALOG, MODAL_TYPE.PANEL, MODAL_TYPE.CUSTOM, MODAL_TYPE.IMGVALID].includes(props.type)) {
			setTimeout(() => {
				props.close();
			}, props.duraction * ONE_SECOND);
		}
	}, [props]);

	const typeClass = (type) => {
		switch (type) {
			case MODAL_TYPE.LOADING:
				return styles.loading;
			case MODAL_TYPE.FAIL:
				return styles.fail;
			case MODAL_TYPE.INFO:
				return `${styles.info} ${props.isSuccess || props.isHint ? styles.withIcon : ""}`;
			case MODAL_TYPE.DIALOG:
				return styles.dialog;
			case MODAL_TYPE.PANEL:
				return styles.panel;
			default:
				return "";
		}
	};

	// const renderContent = (type) => {
	// 	switch (type) {
	// 		case MODAL_TYPE.LOADING:
	// 			return <Loading />;
	// 		case MODAL_TYPE.FAIL:
	// 			return <Fail content={props.content} />;
	// 		case MODAL_TYPE.INFO:
	// 			return <Info {...props} />;
	// 		case MODAL_TYPE.DIALOG:
	// 			return <Dialog {...props} />;
	// 		case MODAL_TYPE.PANEL:
	// 			return <Panel {...props} />;
	// 		default:
	// 			return "";
	// 	}
	// };
	// 自定義
	if (props.type === MODAL_TYPE.CUSTOM) {
		return (
			<div className={styles.modalWrapper}>
				<div className={styles.mask} onClick={() => (props.isMaskClose ? props.close() : null)} />
				{React.cloneElement(props.content, { close: props.close })}
			</div>
		);
	}
	if (props.type === MODAL_TYPE.IMGVALID) {
		return (
			<div className={`${styles.modalWrapper} ${styles.imgValid}`}>
				<div className={styles.mask} />
				{React.cloneElement(props.content, { close: props.close })}
			</div>
		);
	}

	return (
		<div className={styles.modalWrapper}>
			{isMask && <div className={styles.mask} />}
			<div className={`${styles.common} ${typeClass(props.type)} ${props.customClass ? props.customClass : ""}`}>
				{/* {renderContent(props.type)} */}
			</div>
		</div>
	);
};
const show = (props) => {
	const divEl = document.createElement("div");
	document.body.appendChild(divEl);
	const close = () => {
		if (props?.callback) {
			props.callback();
		}
		document.body.removeChild(divEl);
	};
	const root = ReactDOM.createRoot(divEl);
	root.render(<Modal {...props} close={close} />);
};

export const customModal = (data, isMaskClose = true, callback) =>
	show({ type: MODAL_TYPE.CUSTOM, content: data, isMaskClose, callback });
export const imgValidModal = (data, callback) => show({ type: MODAL_TYPE.IMGVALID, content: data, callback });
// export default {
// 	loading: (callback) => show({ type: MODAL_TYPE.LOADING, duraction: DEFAULT_DURATION, callback }),
// 	error: (msg, duraction = 0.5, callback) => show({ type: MODAL_TYPE.FAIL, content: msg, duraction, callback }),
// 	info: (data, duraction = 0.5, callback) =>
// 		typeof data === "object"
// 			? show({ type: MODAL_TYPE.INFO, ...data, duraction, callback })
// 			: show({
// 					type: MODAL_TYPE.INFO,
// 					content: data,
// 					duraction,
// 					callback,
// 			  }),
// 	panel: (data) => show({ type: MODAL_TYPE.PANEL, ...data }),
// 	dialog: (data) => show({ type: MODAL_TYPE.DIALOG, ...data }),
// 	custom: (data, callback) => show({ type: MODAL_TYPE.CUSTOM, content: data, callback }),
// };
