import React, { useState, useMemo } from "react";
import { notification } from "antd";
// utils
import { nameMask } from "@/utils";
import { bank } from "@/utils/common";
// lib
import EVENT from "@/library/api";
// store
import Store from "@/store";
// components
import FormModal from "@/view/components/Modal/form";
import SimpleInput from "@/view/components/SimpleInput";
import SimpleSelect from "@/view/components/SimpleSelect";
import Linkage from "./linkage";
// style
import styles from "./index.module.scss";

export default function AddBankcard({ accountInfo, close }) {
	const [bankType, setBankType] = useState("");
	const [province, setProvince] = useState("");
	const [city, setCity] = useState("");
	const [subbranch, setSubBranch] = useState("");
	const [cardNumber, setCardNumber] = useState("");
	const [cfmCardNumber, setCfmCardNumber] = useState("");
	const { name } = accountInfo;
	const isVerify = useMemo(
		() => bankType && province && city && subbranch && cardNumber.length > 15 && cfmCardNumber.length > 15,
		[bankType, province, city, subbranch, cardNumber, cfmCardNumber]
	);
	const submit = () => {
		if (!isVerify) {
			return;
		}
		if (cardNumber !== cfmCardNumber) {
			return notification.error({ message: "卡号输入不一致，请确认后重新输入" });
		}
		EVENT.Account.addBankCard({
			bankType,
			province,
			city,
			cardNumber,
			cfmCardNumber,
			subbranch,
		})
			.then(() => {
				notification.success({ message: "银行卡绑定成功", onClose: () => Store.User.updateDetail() });
				close();
			})
			.catch(({ message }) => notification.error({ message }));
	};
	return (
		<FormModal title="添加银行卡" close={close}>
			<SimpleInput label={"开户姓名"} value={nameMask(name)} disable={true} />
			<SimpleSelect
				label={"选择银行"}
				placeholder={"选择银行"}
				options={bank[0]}
				value={bankType}
				onChange={(val) => setBankType(val)}
			/>
			<Linkage
				province={province}
				city={city}
				getProvince={(val) => setProvince(val)}
				getCity={(val) => setCity(val)}
			/>
			<SimpleInput
				label={"开户支行"}
				placeholder={"请输入银行卡开户支行"}
				type={"text"}
				maxlength={30}
				value={subbranch}
				onChange={(val) => {
					setSubBranch(val);
				}}
			/>
			<SimpleInput
				label={"银行卡号"}
				placeholder={"请输入银行卡号"}
				type={"card"}
				value={cardNumber}
				onChange={(val) => setCardNumber(val)}
			/>
			<SimpleInput
				label={"确认卡号"}
				placeholder={"请输入确认银行卡号"}
				type={"card"}
				value={cfmCardNumber}
				onChange={(val) => setCfmCardNumber(val)}
			/>
			<div className={`confirm_btn ${!isVerify ? "error" : ""} ${styles.confirmBtn}`} onClick={submit}>
				添加
			</div>
		</FormModal>
	);
}
