import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

// store
import Store from "@/store";
// components
import { ReactComponent as OpenEyes } from "@assets/userCenter/openEyes.svg";
import { ReactComponent as CloseEyes } from "@assets/userCenter/closeEyes.svg";

// style
import styles from "./index.module.scss";

const MyAssets = () => {
	const { money, prize, eagle } = Store.User.assets;
	const [show, setShow] = useState(true);
	useEffect(() => {
		Store.User.updateAssets();
		if (!!localStorage.getItem("eye")) {
			setShow(JSON.parse(localStorage.getItem("eye")));
		}
	}, []);
	return (
		<div className={styles.myAssets}>
			<div className={styles.assetTitle}>
				<h5>我的资产</h5>
			</div>
			<div className={styles.assetBox}>
				<div className={styles.assetInfo}>
					<div className={styles.left}>
						<p>
							<label>账户余额</label>
							<b
								onClick={() => {
									localStorage.setItem("eye", !show);
									setShow((state) => !state);
								}}
							>
								{show ? <OpenEyes /> : <CloseEyes />}
								<span>{show ? "隐藏" : "显示"}余额</span>
							</b>
						</p>
						<b>
							{show ? money : "*****"} <span>CNY</span>
						</b>
					</div>
					<div className={styles.right}>
						<Link to={"/account/recharge"}>充值</Link>
						<Link to={"/account/withdraw"}>提现</Link>
					</div>
				</div>
				<div className={styles.assetInfo}>
					<div className={styles.left}>
						<p>
							<label>礼金余额</label>
						</p>
						<b>{show ? prize : "*****"}</b>
					</div>
					<div className={styles.right}>
						<Link to="/account/fundsDetail" state={5}>
							查看明细
						</Link>
					</div>
				</div>
				<div className={styles.assetInfo}>
					<div className={styles.left}>
						<p>
							<label>积分余额</label>
						</p>
						<b>{show ? eagle : "*****"}</b>
					</div>
					<div className={styles.right}>
						<Link to="/account/fundsDetail" state={6}>
							查看明细
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default observer(MyAssets);
