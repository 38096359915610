import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { notification, Tabs } from "antd";
import { useLocation } from "react-router-dom";

// store
import Store from "@/store";
// lib
import EVENT from "@/library/api";
import { RESTRICT } from "@/library/standard";
// components
import MyUser from "./myUser";
import UserTrade from "./userTrade";

// style
import styles from "./index.module.scss";

const InviteFriends = () => {
	const location = useLocation();
	const [activeIndex] = useState(location.state || 1);
	const [inviteInfo, setInviteInfo] = useState({
		subCount: 0,
		subTrade: 0,
		subCountNew: 0,
		tradeAmount: 0,
		salaryDay: 0,
		salaryAll: 0,
		commission: 0,
		fee: 0,
	});
	const [tabList] = useState([
		{
			label: "被邀请人",
			key: "1",
			children: <MyUser />,
		},
		{
			label: "佣金记录",
			key: "2",
			children: <UserTrade />,
		},
	]);
	useEffect(() => {
		Promise.all([updateCommissionTotal(), updateAgentStat()]).then(([commissionTotal, agentStat]) => {
			setInviteInfo({ ...commissionTotal, ...agentStat });
		});
	}, []);
	const updateCommissionTotal = async () => {
		return EVENT.Account.updateCommissionTotal()
			.then(({ data }) => {
				let o = 0,
					c = 0;
				for (let { currency, money, charge } of data) {
					if (money === 0) continue;
					if (currency === "CNY") {
						o += money;
						c += charge;
						continue;
					}
					const { rate } = Store.Wallet.getCurrency(currency);
					if (money !== money.mul(rate)) {
						o += money.mul(rate);
					}
				}
				return { commission: o, fee: c };
			})
			.catch(({ message }) => {
				notification.error({ message });
				return { commission: 0, fee: 0 };
			});
	};
	const updateAgentStat = async () => {
		return EVENT.Account.updateAgentStat("CNY")
			.then(({ data }) => {
				const { subCount, subTrade, subCountNew, tradeAmount, salaryDay, salaryAll } = data;
				return { subCount, subTrade, subCountNew, tradeAmount, salaryDay, salaryAll };
			})
			.catch(({ message }) => {
				notification.error({ message });
				return { subCount: 0, subTrade: 0, subCountNew: 0, tradeAmount: 0, salaryDay: 0, salaryAll: 0 };
			});
	};
	return (
		<div className={styles.inviteFriends}>
			<div className={styles.inviteTitle}>
				<h5>邀请记录</h5>
			</div>
			<div className={styles.inviteInfo}>
				<div>
					<label>邀请人数</label>
					<b>{inviteInfo.subCount}</b>
				</div>
				<div>
					<label>交易人数</label>
					<b>{inviteInfo.subTrade}</b>
				</div>
				<div>
					<label>累计手续费</label>
					<b>{RESTRICT.float(inviteInfo.fee, 2).toFixed(2)}</b>
				</div>
				<div>
					<label>总佣金收入</label>
					<b>{RESTRICT.float(inviteInfo.commission, 2).toFixed(2)}</b>
				</div>
			</div>
			<div className={styles.myUser}>
				<Tabs defaultActiveKey={`${activeIndex}`} items={tabList}></Tabs>
			</div>
		</div>
	);
};

export default observer(InviteFriends);
